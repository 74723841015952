import { useTranslation } from "react-i18next";
import { ITable } from "./table/table"
import { useStore } from "../store";
import { IColumnProps } from "./table";
import { SalesGetListResModel } from "../services/sales/sales";
import { useState } from "react";
import { Card, Col, Image, Row, Tag } from "antd";
import { ClockCircleOutlined, EditOutlined, FlagOutlined, SoundOutlined, UserOutlined } from "@ant-design/icons";
import { DurationToTime } from "../helpers/utils";
import Meta from "antd/es/card/Meta";

export const TransactionTable: React.FC<{ data: any[] }> = props => {
    const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([])
    const { t } = useTranslation();
    const store = useStore();
    const columns: IColumnProps<SalesGetListResModel>[] = [
        {
            title: 'Book',
            dataIndex: 'book',
            render: (v, r) => r.book.book_name
        },

        {
            dataIndex: 'currency',
            title: 'Currency'
        },
        {
            dataIndex: 'localized_price',
            title: 'Price'
        },

        {
            dataIndex: 'transaction_date',
            title: 'Date',
            isDate: true
        }
    ]
    return (
        <ITable
            rowKey={"ut_id"}
            dataSource={props.data}
            columns={columns}

            expandable={{
                rowExpandable(record:SalesGetListResModel) {
                    return Boolean(record.book && record.user) && record.transaction_type === 'BOOK'
                },
                expandedRowKeys: expandedRowKeys,
                onExpand(expanded, record) {
                    setExpandedRowKeys(p => {
                        if (expanded) {
                            p.push(record.ut_id)
                            return p;
                        }

                        return p.filter(key => key !== record.ut_id)
                    })
                },
                expandedRowRender: (r: SalesGetListResModel, i, indent, expanded) => {

                    if (!expanded) return null
                    const { book, user, user_book } = r;
                    return (
                        <div
                            key={indent}
                        >
                            <Row gutter={16}>
                                <Col span={6}>
                                    <Card
                                        title={t('Book')}
                                        cover={
                                            <span
                                                style={{
                                                    width: '100%',
                                                    height: 200,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    background: '#1a1a1a',
                                                }}
                                            >
                                                <Image
                                                    alt={r.book.book_name}
                                                    src={r.book.image}
                                                    style={{
                                                        width: '90%',
                                                        textAlign: 'center'
                                                    }}

                                                />
                                            </span>
                                        }
                                        actions={[
                                            <SoundOutlined onClick={() => store.setSound(user_book.audio_url)} key="play" />,
                                        ]}
                                    >
                                        <Meta
                                            title={book.book_name}
                                            description={
                                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                                                    <Tag color="orange" icon={<EditOutlined />} children={<>{book.author}</>} />
                                                    <Tag color="blue" icon={<FlagOutlined />} children={<>{book.language.toUpperCase()}</>} />
                                                    <Tag color="gray" icon={<ClockCircleOutlined />} children={<>{DurationToTime(user_book.duration)}</>} />
                                                </div>
                                            }
                                        />
                                    </Card>
                                </Col>
                                <Col span={6}>
                                    <Card
                                        title={t('Bookfluencer')}
                                    >
                                        <Meta
                                            title={<>{user_book.user.name} {user_book.user.surname}</>}
                                            description={
                                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                                                    <Tag color="orange" icon={<UserOutlined />} children={<>{user_book.user.username}</>} />
                                                </div>
                                            }
                                        />
                                    </Card>
                                </Col>
                                <Col span={6}>
                                    <Card
                                        title={t('Buyyer')}
                                    >
                                        <Meta
                                            title={<>{user.name} {user.surname}</>}
                                            description={
                                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                                                    <Tag color="orange" icon={<UserOutlined />} children={<>{user.username}</>} />
                                                </div>
                                            }
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    )
                }
            }}
        />
    )
}