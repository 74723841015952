export interface TranslationUpdateReqModel {
    translation_id: number;
    value: string;
}

export enum TranslationEnums {
    interest = 'interest'
}

export interface TranslationAddReqModel {
    key: string;
    value: string;
    language:string;
    type: TranslationEnums
    
}