import React, { useCallback, useEffect, useState } from 'react';
import { PageCard } from '../../components/pageCard';
import { useUserBookService } from '../../services/userBook/userBook.service';
import { UserBookWaitingResModel } from '../../services/userBook/userBook';
import { IColumnProps } from '../../components/table';
import { ITable } from '../../components/table/table';
import { Button, Image, Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { useStore } from '../../store';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../hooks/modal';
import { ModalData, ModalDataModel } from '../../models/system';
import { SummaryDeclineModal } from './summaryDeclineModal';
import dayjs from 'dayjs';

export const SummaryWaiting: React.FC = props => {
    const service = useUserBookService();

    const [state, setState] = useState<UserBookWaitingResModel[]>([]);
    const store = useStore();
    const modal = useModal();
    const { t } = useTranslation();
    const [declineModal, setDeclineModal] = useState<ModalData<number>>(ModalDataModel());

    const getList = useCallback(async () => {
        const res = await service.approveWaitingBooks();
        if (!res.result) return;
        setState(res.data);
    }, [service, setState])

    useEffect(() => {
        getList();
        store.getAllInterest();
        store.getLanguages();
        // eslint-disable-next-line
    }, [])

    const cols: IColumnProps<UserBookWaitingResModel>[] = [
        {
            dataIndex: 'ub_id',
            title: 'SummaryID',
            textFilter:true
        },
        {
            dataIndex: 'image',
            title: 'Image',
            align: 'center',
            render: (v) => <Image width={40} height={60} src={v} />
        },
        {
            dataIndex: ['book_name'],
            title: 'Book'
        },
        {
            dataIndex: 'author',
            title: 'Author',
            textFilter: true
        },
        {
            dataIndex: 'interests',
            title: 'Categories',
            filters: store.data.categories.map(e => ({ text: e.interest_key, value: e.interest_id })),
            filterSearch: true,
            filterMode: 'menu',
            render: (v, r) => r.interests.map(e => <Tag children={e.value} />),
            onFilter(value, record) {
                const exist = record.interests.find(e => e.interest_id === Number(value))
                return exist ? true : false;
            },
        },
        {
            dataIndex: 'username',
            title: 'Bookfluencer',
            align: 'center',
            textFilter: true
        },

        {
            dataIndex: 'audio_url',
            title: 'Audio',
            render: (v) => <Button onClick={() => store.setSound(v)}><PlayCircleOutlined /></Button>
        },
        // {
        //     title: 'Description',
        //     dataIndex: 'description',
        //     render: (v) => {
        //         const desc = String(v);
        //         if (desc.length < 50) return desc;
        //         return <Tooltip placement="left" title={v}>{String(v).substring(0, 50) + "..."}</Tooltip>
        //     }
        // },
        {
            dataIndex: 'free_listening_time',
            title: 'FreeListeningTime',
            sortNumber: true
        },
        {
            dataIndex: 'price',
            title: 'Credit',
            isTier: true,
            sortNumber: true,
            sortNumberFormater: (v) => parseFloat(String(v).replace(/\$/g, ''))
        },
        {
            dataIndex: 'language',
            title: 'Language',
            filters: store.data.languages.map(e => ({ text:`${e.language.toUpperCase()} - ${e.code.toUpperCase()}`, value: e.code })),
            filterSearch: true,
            filterMode: 'menu',
            onFilter(value, record) {
                return String(record.language.toLowerCase()).indexOf(String(value)) === 0
            },
        },
        {
            dataIndex: 'created_at',
            title: 'CreatedDate',
            isDate: true,
            sorter: (a, b) => dayjs(a.created_at, 'YYYY-MM-DD').diff(dayjs(b.created_at, 'YYYY-MM-DD')),
        },
        {
            dataIndex: 'updated_at',
            title: 'UpdatedDate',
            isDate: true,
            sorter: (a, b) => dayjs(a.created_at, 'YYYY-MM-DD').diff(dayjs(b.created_at, 'YYYY-MM-DD')),

        },
        {
            dataIndex: 'ub_id',
            actionColumn: {
                items: (row) => [
                    {
                        icon: <CheckCircleOutlined />,
                        key: 'approve',
                        label: t('Approve'),
                        onClick: async () => {
                            modal.confirm({
                                content: t('SummaryApprove', { user: row.username, book: row.book_name }),
                                onOk: async () => {
                                    const res = await service.approve(row.ub_id);
                                    if (!res.result) return;
                                    getList()
                                }
                            })
                        }
                    },
                    {
                        icon: <CloseCircleOutlined />,
                        key: 'decline',
                        label: t('Decline'),
                        onClick: async () => {
                            setDeclineModal({ data: row.ub_id, open: true })
                        }
                    },
                ]
            }
        }
    ];

    return (
        <>
            <PageCard>
                <ITable
                    columns={cols}
                    dataSource={state.reverse()}
                />
            </PageCard>
            <SummaryDeclineModal
                data={declineModal}
                onCancel={() => setDeclineModal(ModalDataModel())}
                onDone={() => {
                    setDeclineModal(ModalDataModel())
                    getList()
                }}
            />
        </>
    )
}