import { InboxOutlined } from "@ant-design/icons";
import { UploadChangeParam, UploadFile } from "antd/es/upload";
import Dragger from "antd/es/upload/Dragger"
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "../store";

export const SoundUpload: React.FC<{
    value?: any;
    onChange?(value: any): void;
}> = props => {
    const { t } = useTranslation();
    const store = useStore();
    const getBase64 = useCallback((img: any, callback: (base64: any) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }, [])
    const uploadContent = useCallback(() => {
        if (!props.value) return (
            <>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">{t('DragDropUploadTitle')}</p>
                <p className="ant-upload-hint">
                    {t('DragDropUploadMessage')}
                </p>
            </>
        );



        return (<>{props.value.name}</>)
    }, [props, t])

    const handleChange = useCallback((info: UploadChangeParam<UploadFile<any>>) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, base64 => {
                store.setSound(base64)
            });
            props.onChange && props.onChange(info.file.originFileObj as any);
        }
    }, [props, getBase64, store])

    const customAction = useCallback(async ({ onSuccess }: any): Promise<any> => {
        setTimeout(() => {
            onSuccess("ok");
        }, 500);
    }, []);

    return (
        <Dragger
            multiple={false}
            accept="audio/mp3"
            customRequest={customAction.bind(this)}
            onChange={handleChange.bind(this)}
            showUploadList={false}
        >
            {uploadContent()}
        </Dragger>
    )
}