import { useCallback, useEffect, useState } from "react";
import { PageCard } from "../../components/pageCard"
import { useBookReviewService } from "../../services/bookReview/bookReview.service"
import { BookReviewWaitingListResModel } from "../../services/bookReview/bookReview";
import { IColumnProps } from "../../components/table";
import { ITable } from "../../components/table/table";
import { useModal } from "../../hooks/modal";
import { useTranslation } from "react-i18next";

export const Comments: React.FC = props => {
    const service = useBookReviewService();

    const [state, setState] = useState<BookReviewWaitingListResModel[]>([]);
    const modal = useModal();
    const { t } = useTranslation();
    const getList = useCallback(async () => {
        const res = await service.getWaitingList();
        if (!res.result) return;
        setState(res.data)
    }, [service, setState])

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])

    const columns: IColumnProps<BookReviewWaitingListResModel>[] = [
        {
            dataIndex: 'book_name',
            title: 'Book',
        },
        {
            dataIndex: 'name',
            title: 'Name'
        },
        {
            dataIndex: 'surname',
            title: 'Surname'
        },
        {
            dataIndex: 'review',
            title: 'Comment'
        },
        {
            dataIndex: 'star_rate',
            title: 'StarRate',
            isRate: true,
        },
        {
            dataIndex: 'br_id',
            actionColumn: {
                items: (row) => [
                    {
                        label: 'Approve',
                        key: 'aprove',
                        onClick: async () => {
                            modal.confirm({
                                content: t('CommentApproveConfirm', { value: row.review }),
                                onOk: async () => {
                                    const res = await service.approve({ br_id: row.br_id });
                                    if (!res.result) return;
                                    getList()
                                }
                            });
                        }
                    },
                    {
                        label: 'Decline',
                        key: 'decline',
                        onClick: async () => {
                            modal.confirm({
                                content: t('CommentDeclineConfirm', { value: row.review }),
                                onOk: async () => {
                                    const res = await service.decline({ br_id: row.br_id });
                                    if (!res.result) return;
                                    getList()
                                }
                            });

                        }
                    },
                    {
                        label: 'Remove',
                        key: 'remove',
                        danger: true,
                        onClick: async () => {
                            modal.confirm({
                                content: t('ConfirmDelete', { val: '' }),
                                onOk:async()=>{
                                    const res = await service.removeComment(row.br_id);
                                    if(!res.result) return;
                                    getList()
                                }
                            })
                        }
                    }
                ]
            }
        }
    ]

    return (
        <PageCard>
            <ITable
                columns={columns}
                dataSource={state}
            />
        </PageCard>
    )
}