import { useCallback, useEffect, useState } from "react";
import dayjs from 'dayjs';
import { PageCard } from "../../components/pageCard"
import { useSalesService } from "../../services/sales/sales.service"
import { ITable } from "../../components/table/table";
import { IColumnProps } from "../../components/table";
import { Image } from "antd";
import { useTranslation } from "react-i18next";
import { SoundOutlined } from "@ant-design/icons";
import { useStore } from "../../store";

import { ExcelExport } from "../../components/excellExport";

interface SummaryProps {
}

export const SummarySales: React.FC<SummaryProps> = props => {
    const service = useSalesService();
    const [state, setState] = useState<any[]>([])
    const { t } = useTranslation();
    const store = useStore();
    const [excelData, setExcelData] = useState<any[]>([]);

    const getList = useCallback(async () => {
        const res = await service.creditSales();
        if (!res.result) return;
        setState(res.data);
        setExcelData(res.data)
    }, [service, setState, setExcelData])

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])

    const columns: IColumnProps<any>[] = [
        {
            dataIndex:'ubs_id',
            title:'Id'
        },
        {
            dataIndex: ['user_book', 'ub_id'],
            title: 'SummaryID',
            align: 'center'
        },
        {
            dataIndex: 'book',
            title: 'BookImage',
            align: 'center',
            render: (v, r) => <Image width={100} height={120} src={r.book.image} />
        },
        {
            title: 'Book',
            dataIndex: 'book',
            render: (v, r) => r.book.book_name,
            textFilter: true,
            textFilterData: (r) => r.book.book_name
        },
        {
            dataIndex: 'credit',
            title: 'Credit',
            sortNumber: true,
            sortNumberFormater: (v) => parseFloat(String(v).replace(/\$/g, ''))
        },
        {
            dataIndex: 'book',
            title: 'Language',
            render: (v, r) => r.book.language,
            filters: store.data.languages.map(e => ({ text:`${e.language.toUpperCase()} - ${e.code.toUpperCase()}`, value: e.code })),
            onFilter: (v, r) => r.book.language === v
        },
        {
            dataIndex: ['book', 'author'],
            title: 'Author',
            render: (v, r) => r.book.author,
            textFilter: true,
            textFilterData: (r) => r.book.author
        },

        {
            dataIndex: 'user_book',
            title: 'Seller',
            render: (v, r) => `${r.user_book.user.name} ${r.user_book.user.surname}  / ${r.user_book.user.username}`,
            textFilter: true,
            textFilterData: (r) => `${r.user_book.user.name} ${r.user_book.user.surname}`
        },
        {
            dataIndex: 'user',
            title: 'Buyyer',
            render: (v, r) => `${r.user.name}  ${r.user.surname} / ${r.user.username}`,
            textFilter: true,
            textFilterData: (r) => `${r.user.name} ${r.user.surname}`
        },
        {
            dataIndex: 'user_book',
            title: 'Summary',
            render: (v, r) => r.user_book.audio_url !== null ? <SoundOutlined onClick={() => store.setSound(r.user_book.audio_url)} key="play" /> : "",
        },
        {
            dataIndex: ['user_book', 'duration'],
            title: 'Duration',
            isDuration: true,
        },
        {
            dataIndex: 'transaction_date',
            title: 'Date',
            isDate: true,
            dateFormat:'DD/MM/YYYY - hh:mm:ss',
            sortDate: true
        }
    ]

    return (
        <PageCard
            extra={<ExcelExport
                data={excelData}
                fileName={`Summaries-${new Date().toUTCString()}`}
                rowConverter={(r: any) => ({
                    'Transaction': r.ubs_id,
                    [t('Book')]: r.book.book_name,
                    [t('Price')]: r.credit,
                    [t('Language')]: r.book.language,
                    [t('Author')]: r.book.author,
                    [t('Seller')]: `${r.user_book.user.name} ${r.user_book.user.surname}  / ${r.user_book.user.username}`,
                    [t('Buyyer')]: `${r.user.name}  ${r.user.surname} / ${r.user.username}`,
                    [t('Duration')]: r.user_book.duration,
                    [t('Date')]: dayjs(r.transaction_date).format('DD/MM/YYYY').toString()
                })}
            />}
        >
            <ITable
                rowKey={"ut_id"}
                dataSource={state}
                columns={columns}
                onChange={(pg, f, s, e) => {
                    setExcelData(e.currentDataSource)
                }}
            />
        </PageCard >
    )
}