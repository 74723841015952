import { CloudUploadOutlined } from '@ant-design/icons';
import { Upload, UploadProps } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import React, { useCallback, useState } from 'react';

interface ImageUploadProps extends UploadProps {
    value?: string;
    showStockSelect?: boolean;
    onlyPng?: boolean;
}


export const ImageUpload: React.FC<ImageUploadProps> = props => {
    const [loadedImage, setLoadedImage] = useState<string | null>(props.value ? String(props.value) : null);

    const getBase64 = useCallback((img: any, callback: (base64: any) => void) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            if (e.target) {
                callback(String(e.target.result))
            }
        };
        reader.readAsDataURL(img);
    }, [])

    const beforeUpload = useCallback((file: any) => {
        return true;
    }, [])

    const handleChange = useCallback((info: UploadChangeParam<UploadFile<any>>) => {
        const file = info.fileList[info.fileList.length - 1];

        if (file.status === 'done') {
            if (info.fileList[info.fileList.length - 1].originFileObj) {
                getBase64(info.fileList[info.fileList.length - 1].originFileObj, (base64) => {
                    setLoadedImage(base64);
                    props.onChange && props.onChange(String(base64).replace(/^data:image\/[a-z]+;base64,/, "") as any)
                })
            }
        }


    }, [getBase64, props])

    const customAction = useCallback(async ({ onSuccess }: any): Promise<any> => {
        setTimeout(() => {
            onSuccess("ok");
        }, 500);
    }, []);

    return (
        <>
            <Upload
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={e => customAction(e)}
                beforeUpload={beforeUpload.bind(this)}
                onChange={(v) => handleChange(v)}
                multiple={false}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <CloudUploadOutlined style={{ fontSize: '22px', zIndex: 3, padding: '10px', color: 'white', background: 'rgba(0,0,0,0.5)', position: 'absolute' }} />
                {(props.value || loadedImage) && <img width={"80%"} src={loadedImage ? loadedImage : String(props.value)} alt="" />}
            </Upload >
        </>
    )
}