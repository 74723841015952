import { Button } from 'antd';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import XLSX from 'sheetjs-style';
import dayjs from 'dayjs';
import { FileExcelOutlined } from '@ant-design/icons';

interface ExcelExportProps {
    data: any[];
    fileName: string;
    rowConverter?(row: any): any;
}

export const ExcelExport: React.FC<ExcelExportProps> = props => {
    const { t } = useTranslation();
    const onExport = useCallback(() => {
        const data = props.data.map(row=> props.rowConverter ? props.rowConverter(row) : row);
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet);
        const date = dayjs().format('DD-MM-YYYY-HH.MM');
        
        XLSX.writeFile(workbook, `${props.fileName}-${date}.xlsx`, { compression: true });
        // eslint-disable-next-line
    }, [props])
    return (
        <>
            <Button type='dashed' style={{ backgroundColor: 'green', color: 'white', margin: '0px 10px' }} icon={<FileExcelOutlined />} onClick={() => onExport()}>
                {t('ExportExcel')}
            </Button>
        </>
    )
}