import { useCallback, useEffect, useState } from "react";
import dayjs from 'dayjs';
import { PageCard } from "../../components/pageCard"
import { CreditSales } from "../../services/sales/sales";
import { useSalesService } from "../../services/sales/sales.service"
import { ITable } from "../../components/table/table";
import { IColumnProps } from "../../components/table";
import { useTranslation } from "react-i18next";
import { ExcelExport } from "../../components/excellExport";

export const Credits: React.FC = props => {
    const service = useSalesService();
    const [state, setState] = useState<CreditSales[]>([])
    const { t } = useTranslation();
    const [excelData, setExcelData] = useState<any[]>([]);

    const getList = useCallback(async () => {
        const res = await service.transactions();
        if (!res.result) return;
        setState(res.data);
        setExcelData(res.data)
    }, [service, setState, setExcelData])

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])

    const columns: IColumnProps<CreditSales>[] = [
        {
            dataIndex: 'ut_id',
            title: 'Id',
            align: 'center'
        },
        {
            dataIndex: 'name',
            title: 'Name',
            textFilter:true
        },
        {
            dataIndex: 'surname',
            title: 'Surname',
            textFilter:true
        },
        {
            dataIndex: 'username',
            title: 'Username',
            textFilter:true
        },
        {
            dataIndex: 'price',
            title: 'Price',
            sortNumber: true,
            sortNumberFormater: (v) => parseFloat(String(v).replace(/\$/g, ''))
        },
        {
            dataIndex: 'currency',
            title: 'CustomerMoneyCurrency'
        },
        {
            dataIndex: 'credit',
            title: 'Credit',
            sortNumber:true
        },
        {
            dataIndex: 'product_id',
            title: 'ProductId'
        },
        {
            dataIndex: 'created_at',
            title: 'Date',
            isDate: true,
            dateFormat: 'DD/MM/YYYY - hh:mm:ss',
            sortDate: true
        }
    ]

    return (
        <PageCard
            extra={<ExcelExport
                data={excelData}
                fileName={`Credit-${new Date().toUTCString()}`}
                rowConverter={(r: CreditSales) => ({
                    'Transaction': r.ut_id,
                    [t('Name')]: r.name,
                    [t('Surname')]: r.surname,
                    [t('Username')]: r.username,
                    [t('Price')]: r.localized_price,
                    [t('CustomerMoneyCurrency')]: r.localized_price,
                    [t('Credit')]: r.credit,
                    ProductId: r.product_id,
                    [t('Date')]: dayjs(r.created_at).format('DD/MM/YYYY').toString()
                })}
            />}
        >
            <ITable
                rowKey={"ut_id"}
                dataSource={state}
                columns={columns}
                onChange={(pg, f, s, e) => {
                    setExcelData(e.currentDataSource)
                }}
            />
        </PageCard >
    )
}