import { useCallback, useEffect, useState } from "react";
import { PageCard } from "../../components/pageCard"
import { useBookReviewService } from "../../services/bookReview/bookReview.service"
import { BookReviewWaitingListResModel } from "../../services/bookReview/bookReview";
import { IColumnProps } from "../../components/table";
import { ITable } from "../../components/table/table";
import { useTranslation } from "react-i18next";
import { useModal } from "../../hooks/modal";


export const CommentsAll: React.FC = props => {
    const service = useBookReviewService();

    const [state, setState] = useState<BookReviewWaitingListResModel[]>([]);
    const modal = useModal();
    const { t } = useTranslation();

    const getList = useCallback(async () => {
        const res = await service.getAllList();
        if (!res.result) return;
        setState(res.data)
    }, [service, setState])

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])

    const columns: IColumnProps<BookReviewWaitingListResModel>[] = [
        {
            dataIndex: 'book_name',
            title: 'Book',
        },
        {
            dataIndex: 'name',
            title: 'Name'
        },
        {
            dataIndex: 'surname',
            title: 'Surname'
        },
        {
            dataIndex: 'review',
            title: 'Comment'
        },
        {
            dataIndex: 'star_rate',
            title: 'StarRate',
            isRate: true
        },
        {
            dataIndex: 'br_id',
            actionColumn: {
                items: (row) => [
                    {
                        label: 'Remove',
                        key: 'remove',
                        danger: true,
                        onClick: async () => {
                            modal.confirm({
                                content: t('ConfirmDelete', { val: '' }),
                                onOk: async () => {
                                    const res = await service.removeComment(row.br_id);
                                    if (!res.result) return;
                                    getList()
                                }
                            })
                        }
                    }
                ]
            }
        }
    ]

    return (
        <PageCard>
            <ITable
                columns={columns}
                dataSource={state}
            />
        </PageCard>
    )
}