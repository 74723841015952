import { Form, Modal } from "antd";
import { ComponentTypes, ModalData } from "../../models/system";
import { useForm } from "antd/lib/form/Form";
import { useCreateFormItems } from "../../helpers/form";
import { StyledInput } from "../../components/form";
import TextArea from "antd/es/input/TextArea";
import { useCallback, useEffect } from "react";
import { useHttp } from "../../hooks/http";
import { useBookService } from "../../services/book/book.service";

interface BookDetailEditModalProps {
    data: ModalData<number>;
    onDone(): void;
    onCancel(): void;
}

export const BookDetailEditModal: React.FC<BookDetailEditModalProps> = props => {

    const [form] = useForm();
    const http = useHttp();
    const service = useBookService();

    const getData = useCallback(async (id: number) => {
        const res = await service.getById(id);
        if (!res.result) return;
        form.setFieldsValue(res.data);
    }, [form, service])

    useEffect(() => {
        if (!props.data.open) return;
        if (!props.data.data) return;
        getData(props.data.data);
        // eslint-disable-next-line
    }, [props.data])

    const items = useCreateFormItems<{
        book_name: string;
        author: string;
        description: string;
    }>([
        {
            colProps: {
                span: 24
            },
            element: <StyledInput />,
            elementType: ComponentTypes.Input,
            formItem: {
                name: 'book_name',
                label: 'Name',
                required: true
            }
        },
        {
            colProps: {
                span: 24
            },
            element: <StyledInput />,
            elementType: ComponentTypes.Input,
            formItem: {
                name: 'author',
                label: 'Author',
                required: true
            }
        },
        {
            colProps: {
                span: 24
            },
            element: <TextArea style={{height:130}} maxLength={255} />,
            elementType: ComponentTypes.Input,
            formItem: {
                name: 'description',
                label: 'Description',
                required: true
            }
        }
    ]);

    const reset = useCallback(() => {
        form.resetFields()
    }, [form])

    const cancel = useCallback(() => {
        reset()
        props.onCancel()
    }, [reset, props])

    const done = useCallback(() => {
        reset()
        props.onDone()
    }, [reset, props])

    return (
        <Modal
            open={props.data.open}
            okButtonProps={{
                htmlType: 'submit',
                form: 'bookeditform'
            }}
            onCancel={() => cancel()}
        >
            <Form id="bookeditform" form={form} onFinish={async (v) => {
                if (!props.data.data) return;
                const res = await http.post(`/book/update/${props.data.data}`, v);
                if (!res.result) return;
                done()
            }}>
                {items}
            </Form>
        </Modal>
    )
}