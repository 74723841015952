import { Params } from "react-router-dom";
import { IRouteHandleProps } from "../../routes/type";
export interface LoginDto {
    username: string;
    password: string;
}

export interface ConfigDto {
    name: string;
    title: string;
    apiBaseUrl: string;
    authPagePath: string;
    httpTimeout: number;
    authLocalstorageKey: string;
    languages: string[];
}

export interface LocalStorageDto {
    key: string;
    value: string | null;
}

export interface UseMatchesDto {
    id: string;
    pathname: string;
    params: Params<string>;
    data: unknown;
    handle: IRouteHandleProps;
}

export enum ComponentTypes {
    Switch,
    Input,
    InputEmail,
    InputPassword,
    Checkbox,
    Textarea,
    ImageUpload
}

export interface ModalData<T> {
    data: T | null;
    open: boolean;
}

export const ModalDataModel = (): ModalData<any> => {
    return {
        data: null,
        open: false
    }
}

export enum YesOrNoEnum {
    Yes = 'Y',
    No = 'N'
}

export enum GenderEnum {
    male = 1,
    female = 2,
    other = 3,
    prefernot = 4,
}

export interface PaginationReqModel{
    page:number;
    limit:number;
}