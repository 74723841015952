import { useCallback, useEffect, useState } from "react";
import { PageCard } from "../../components/pageCard"
import { useAdminService } from "../../services/admin/admin.service"
import { AdminGetAllResModel } from "../../services/admin/admin";
import { IColumnProps } from "../../components/table";
import { ITable } from "../../components/table/table";
import { ComponentTypes, ModalData, ModalDataModel, YesOrNoEnum } from "../../models/system";
import { NewAdminModal } from "./newAdminModal";
import { useHttp } from "../../hooks/http";
import { useForm } from "antd/lib/form/Form";
import { useCreateFormItems } from "../../helpers/form";
import { StyledInput } from "../../components/form";
import { Form, Modal, Row } from "antd";
import { useTranslation } from "react-i18next";

export const AdminUsers: React.FC = props => {

    const service = useAdminService();
    const [state, setState] = useState<AdminGetAllResModel[]>([]);
    const [modal, setModal] = useState<ModalData<number>>(ModalDataModel())
    const http = useHttp();

    const [pwdModal, setPwdModal] = useState<ModalData<string>>(ModalDataModel());
    const [pwdForm] = useForm();

    const { t } = useTranslation();

    const getList = useCallback(async () => {
        const res = await service.getAllAdminUsers();
        if (!res.result) return;
        setState(res.data);
    }, [service, setState])

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])

    const columns: IColumnProps<AdminGetAllResModel>[] = [
        {
            dataIndex: 'username',
            title: 'Username',
            textFilter: true
        },
        {
            dataIndex: 'created_at',
            title: 'CreatedDate',
            isDate: true,
            sortDate: true
        },
        {
            dataIndex: 'updated_at',
            title: 'UpdatedDate',
            isDate: true,
            sortDate: true
        },
        {
            dataIndex: 'isActive',
            title: 'Status',
            isYesOrNo: true,
            sorter: (a, b, sortOrder) => {
                const valueA = a.isActive === 'Y' ? 1 : 0;
                const valueB = b.isActive === 'Y' ? 1 : 0;
                return sortOrder === 'descend' ? valueB - valueA : valueA - valueB;
            }
        },
        {
            dataIndex: 'admin_id',
            actionColumn: {
                items: (row) => [
                    {
                        label: 'Active',
                        key: 'active',
                        onClick: async () => {
                            await service.activate(row.username);
                            getList()
                        },
                        disabled: row.isActive === YesOrNoEnum.Yes
                    },
                    {
                        label: 'DeActive',
                        key: 'deactive',
                        onClick: async () => {
                            await service.deactivate(row.username);
                            getList()
                        },
                        disabled: row.isActive === YesOrNoEnum.No
                    },
                    {
                        label: 'UpdatePassword',
                        key: 'updatePassword',
                        onClick: () => setPwdModal({ data: row.username, open: true })
                    }
                ],
            }
        }
    ];

    /**
     * admin/admin-password-change
-username
-password
     */



    const updateAdminUserPassword = useCallback(async (val: any) => {
        if (!pwdModal.data) return;
        const res = await http.post('/admin/admin-password-change', {
            ...val,
            username: pwdModal.data,
        });
        if (!res.result) return;
        pwdForm.resetFields();
        setPwdModal(ModalDataModel())
    }, [http, setPwdModal, pwdForm,pwdModal])

    const pwdForms = useCreateFormItems([
        {
            formItem: {
                label: 'Password',
                name: 'password',
                required:true
            },
            colProps: {
                span: 24
            },
            element: <StyledInput.Password />,
            elementType: ComponentTypes.InputPassword
        }
    ])

    return (
        <>
            <PageCard
                onClickNew={() => setModal({ data: null, open: true })}
            >
                <ITable
                    dataSource={state}
                    columns={columns}
                />
                <NewAdminModal
                    data={modal}
                    onCancel={() => setModal(ModalDataModel())}
                    onDone={() => {
                        setModal(ModalDataModel())
                        getList()
                    }}
                />
                <Modal
                    open={pwdModal.open}
                    title={t('UpdatePassword')}
                    okButtonProps={{
                        form: 'pwdForm',
                        htmlType: 'submit'
                    }}
                    onCancel={() => {
                        setPwdModal(ModalDataModel())
                        pwdForm.resetFields();
                    }}
                >
                    <Form form={pwdForm} id="pwdForm" onFinish={(v) => updateAdminUserPassword(v)}>
                        <Row gutter={24}>
                            {pwdForms}
                        </Row>
                    </Form>
                </Modal>
            </PageCard>
        </>
    )
}