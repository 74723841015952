import React, { useCallback, useEffect, useState } from 'react';
import { PageCard } from '../../components/pageCard';
import { useUserService } from '../../services/user/user.service';
import { UserGetListResModel } from '../../services/user/user';
import { ITable } from '../../components/table/table';
import { IColumnProps } from '../../components/table';
import { Avatar } from 'antd';
import { ModalData, ModalDataModel, YesOrNoEnum } from '../../models/system';
import { UserModal } from './userModal';
import { NewUserModal } from './newUserModal';
import { UserTransactionsModal } from './userTransactionsModal';
import { EyeFilled } from '@ant-design/icons';

export const Users: React.FC = props => {
    const service = useUserService();
    const [state, setState] = useState<UserGetListResModel[]>([]);
    const [modalData, setModalData] = useState<ModalData<number>>(ModalDataModel());
    const [transactionModal, setTransactionModal] = useState<ModalData<number>>(ModalDataModel());
    const [newModal, setNewModal] = useState(false);

    const getList = useCallback(async () => {
        const res = await service.getList();
        if (!res.result) return;
        setState(res.data);
    }, [service, setState])

    useEffect(() => {
        getList();
        // eslint-disable-next-line
    }, [])

    const columns: IColumnProps<UserGetListResModel>[] = [
        {
            dataIndex: 'profile_image',
            title: 'ProfileImage',
            align: 'center',
            render: (v) => <Avatar src={v} />
        },
        {
            dataIndex: 'name',
            title: 'Name',
            width: '20%',
            textFilter: true
        },
        {
            dataIndex: 'surname',
            title: 'Surname',
            width: '20%',
            textFilter: true
        },
        {
            dataIndex: 'username',
            title: 'Username',
            textFilter: true
        },
        {
            dataIndex: 'birthday',
            title: 'Birthday',
            dateFormat: 'DD/MM/YYYY',
            isDate: true
        },
        {
            dataIndex: 'email',
            title: 'Email',
            width: '20%',
            textFilter: true

        },
        {
            dataIndex: 'phone',
            title: 'PhoneNumber',
            textFilter: true
        },
        {
            dataIndex: 'created_at',
            title: 'UserRegisterDate',
            isDate:true,
            sortDate:true
        },
        {
            dataIndex: 'earned_wallet',
            title: 'EarnedWallet',
            // onFilter: (value: string, record) => record.name.indexOf(value) === 0,
            sorter: (a, b) => a.earned_wallet - b.earned_wallet,
        },
        {
            dataIndex: 'isBanned',
            title: 'BanStatus',
            isYesOrNo: true,
            sorter: (a, b) => a.isBanned === b.isBanned ? 1 : 0,

        },
        {
            dataIndex: 'isPremium',
            title: 'Premium',
            isYesOrNo: true,
            sorter: (a, b) => (a.isPremium === YesOrNoEnum.Yes ? 1 : 0) - (b.isPremium === YesOrNoEnum.Yes ? 1 : 0),
        },
        {
            dataIndex: 'id',
            actionColumn: {
                hasUpdate: {
                    callback: (data) => setModalData({ data, open: true }),
                    propName: 'id'
                },
                items: (row) => [
                    {
                        key: 'ban',
                        label: 'Ban',
                        disabled: row.isBanned === YesOrNoEnum.Yes,
                        onClick: async () => {
                            const res = await service.ban(row.id);
                            if (!res.result) return;
                            getList()
                        }
                    },
                    {
                        key: 'unban',
                        label: 'UnBan',
                        disabled: row.isBanned === YesOrNoEnum.No,
                        onClick: async () => {
                            const res = await service.unban(row.id);
                            if (!res.result) return;
                            getList()
                        }
                    },
                    {
                        key: 'premium',
                        label: 'SetPremium',
                        disabled: row.isPremium === YesOrNoEnum.Yes,
                        onClick: async () => {
                            const res = await service.setPremium(row.id);
                            if (!res.result) return;
                            getList()
                        }
                    },
                    {
                        key: 'unPremium',
                        label: 'UnPremium',
                        disabled: row.isPremium === YesOrNoEnum.No,
                        onClick: async () => {
                            const res = await service.unPremium(row.id);
                            if (!res.result) return;
                            getList()
                        }
                    },
                    {
                        key: 'transactions',
                        label: 'Detail',
                        onClick: () => setTransactionModal({ data: row.id, open: true }),
                        icon: <EyeFilled />
                    }
                ]
            }
        }
    ]



    return (
        <>
            <PageCard
                onClickNew={() => setNewModal(true)}
            >
                <ITable
                    dataSource={state}
                    rowKey={"id"}
                    columns={columns}
                />
            </PageCard>


            <UserModal
                data={modalData}
                onCancel={() => setModalData(ModalDataModel())}
                onDone={() => {
                    setModalData(ModalDataModel())
                    getList()
                }}
            />
            <NewUserModal
                onCancel={() => setNewModal(false)}
                onDone={() => {
                    setNewModal(false);
                    getList()
                }}
                open={newModal}
            />

            <UserTransactionsModal
                modalData={transactionModal}
                onDone={() => setTransactionModal(ModalDataModel())}
            />
        </>
    )
}