import { createContext, useCallback, useContext, useState } from "react";
import { LanguageModel } from "./services/language/language";
import { useLanguageService } from "./services/language/language.service";
import { InterestsGetAllResModel } from "./services/interest/interest";
import { useInterestService } from "./services/interest/interest.service";
import { PricesGetListResModel } from "./services/prices/prices";
import { usePriceService } from "./services/prices/prices.service";
import { UserReportDto } from "./pages/reporteds/userReport";
import { useHttp } from "./hooks/http";
import { BookReportedType } from "./pages/reporteds/bookReportType";
import { YesOrNoEnum } from "./models/system";

interface StoreDataType {
    languages: LanguageModel[];
    categories: InterestsGetAllResModel[];
    tierList: PricesGetListResModel[];
    sound?: string;
    reportedUserList: UserReportDto[];
    reportedBookList: BookReportedType[];
}

interface StoreType {
    data: StoreDataType,
    getLanguages(force?:boolean): Promise<void>;
    getAllInterest(): Promise<void>
    getAllTiers(): Promise<void>
    setSound(url?: string): void;
    getReportedUsers(): Promise<void>
    getReportedBooks(): Promise<void>
}

const StoreDataInit: StoreDataType = {
    languages: [],
    categories: [],
    tierList: [],
    reportedUserList: [],
    reportedBookList: []
}

const StoreContext = createContext<StoreType>({
    data: StoreDataInit,
    getLanguages: async () => { },
    getAllInterest: async () => { },
    getAllTiers: async () => { },
    setSound: (url?: string) => { },
    getReportedUsers: async () => { },
    getReportedBooks: async () => { },
})

export const StoreProvider: React.FC = props => {
    const [state, setState] = useState<StoreDataType>(StoreDataInit)
    const languageService = useLanguageService();
    const interestService = useInterestService();
    const priceService = usePriceService();
    const http = useHttp();

    const getLanguages = useCallback(async (force?:boolean) => {
        if (state.languages.length && !force) return;
        const res = await languageService.getAll();
        if (!res.result) return;
        setState(p => ({ ...p, languages: res.data }))
    }, [languageService, state])

    const getAllInterest = useCallback(async () => {
        if (state.categories.length) return;
        const res = await interestService.getAll();
        if (!res.result) return;
        setState(p => ({ ...p, categories: res.data }));
    }, [interestService, setState, state])

    const setSound = useCallback((url?: string) => {
        setState(p => ({ ...p, sound: url }))
    }, [setState])

    const getAllTiers = useCallback(async () => {
        if (state.tierList.length) return;
        const res = await priceService.getAllPrices();
        if (!res.result) return;
        setState(p => ({ ...p, tierList: res.data }))
    }, [priceService, state])

    const getReportedUsers = useCallback(async () => {
        const res = await http.get<{ data: UserReportDto[] }>('/chat-reports');
        if (!res.result) return;
        setState(p => ({ ...p, reportedUserList: res.data.reverse().filter(e => e.isRead !== YesOrNoEnum.Yes) }))
    }, [http, setState])


    const getReportedBooks = useCallback(async () => {
        const res = await http.get<{ data: BookReportedType[] }>('/book-reports');
        if (!res.result) return;

        setState(p => ({ ...p, reportedBookList: res.data.reverse().filter(e => e.isRead !== YesOrNoEnum.Yes) }))
    }, [http, setState])

    return (
        <StoreContext.Provider
            value={{
                data: state,
                setSound,
                getLanguages,
                getAllInterest,
                getAllTiers,
                getReportedBooks,
                getReportedUsers
            }}
        >
            {props.children}
        </StoreContext.Provider>
    )
}

export const useStore = () => {
    return useContext(StoreContext)
}