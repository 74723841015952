import { Card, CardProps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMatches } from 'react-router-dom';
import { UseMatchesDto } from '../models/system';
import { NewButton } from './buttons';

interface PageCardProps extends CardProps {
    onClickNew?(): void;
}

export const PageCard: React.FC<PageCardProps> = props => {
    const activeRoutes = useMatches().filter(m => m.id !== 'layout') as UseMatchesDto[];
    const { t } = useTranslation();
    return (
        <Card
            {...props}
            style={{
                ...props.style,
                width: '100%',
                marginBottom: 30
            }}
            extra={<div style={{ display: 'flex', gap: 10 }}>
                {props.extra}
                {props.onClickNew ? <NewButton onClick={props.onClickNew} /> : null}
            </div>}
            title={!props.title ? t(`Navigation.${activeRoutes[activeRoutes.length - 1].handle.title || 'NoKey'}`) : props.title}
        >
            {props.children}
        </Card>
    )
}