import { Button } from 'antd';
import { createElement } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { useStore } from '../store';
import { CloseOutlined } from '@ant-design/icons';
interface PlayerProps {

}
export const Player: React.FC<PlayerProps> = props => {

    const store = useStore();
    return (
        <>
            {
                <div style={{
                    position: 'absolute',
                    bottom: store.data.sound ? 0 : -90,
                    left: 0,
                    width: '100%',
                    zIndex: 9999999,
                    height: store.data.sound ? 90 : 0,
                    overflow: store.data.sound ? 'visible' : 'hidden',
                    transition: 'all ease-in-out .3s',
                }}>

                    <div
                        style={{ position: 'fixed', bottom:0, width:'100%' }}
                    >
                        <Button
                            style={{
                                background: 'white',
                                position: 'absolute',
                                top: store.data.sound ? -30 : 0,
                                right: 20,
                                width: 32,
                                height: 32,
                                borderRadius: '100%',
                                boxShadow: '0px 0px 13px 3px #333',
                                padding: 0,
                                color: 'black',
                                transition: 'all ease-in-out .3s',
                            }}
                            onClick={() => store.setSound()}><CloseOutlined /></Button>
                        {store.data.sound !== undefined && <>
                            {store.data.sound.includes('base64') && <span style={{
                                position: 'absolute',
                                left: 50,
                                bottom:10,
                                background: 'red',
                                color: 'white',
                                padding: 10,
                                borderRadius: 8,
                                textAlign:'center'
                            }}>Önizleme</span>}
                            {
                                createElement(AudioPlayer, {
                                    src: store.data.sound,
                                    autoPlay: true,
                                    style: { margin: 0, height: 90 }
                                })
                            }
                        </>}
                    </div>
                </div>
            }
        </>
    )
}