import { Form } from "antd";
import { ITableEditableOptions } from ".";
import { CSSProperties, useMemo } from "react";

interface TableCellProp<T> {
    children: React.ReactNode;
    editing: boolean;
    dataIndex: string;
    title: string;
    editable?: ITableEditableOptions<T>;
    row: any;
    style: CSSProperties
}
export const TableCell: React.FC<TableCellProp<any>> = props => {
    const canEdit = useMemo(() => {
        if (!props.editable) return false;
        const canEdit = props.editable.canEdit ? props.editable.canEdit(props.row) : true
        return props.editing && canEdit
    }, [props.editable, props.editing, props.row])

    return (
        <td style={props.style}>
            {canEdit && props.editable ?
                <Form.Item
                    name={props.dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            validator(rule, value, callback) {
                                callback(typeof value !== 'undefined' ? undefined: "Required")
                            },
                        }
                    ]}
                >
                    {props.editable.element}
                </Form.Item>
                : props.children}
        </td >
    )
}

