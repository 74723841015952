import { useCallback, useEffect, useState } from "react";
import { usePriceService } from "../../services/prices/prices.service"
import { CreditSettingsResModel, TierListResModel } from "../../services/prices/prices";
import { IColumnProps } from "../../components/table";
import { PageCard } from "../../components/pageCard";
import { ITable } from "../../components/table/table";
import { ModalData, ModalDataModel } from "../../models/system";
import { Card } from "antd";
import { useTranslation } from "react-i18next";
import { TierModal } from "./tierModal";
import { StyledNumberInput } from "../../components/form";

export const TierList = () => {
    const service = usePriceService();
    const [state, setState] = useState<TierListResModel[]>([]);
    const [modalData,setModalData]=useState<ModalData<TierListResModel>>(ModalDataModel());
    const[settings,setSettings] =  useState<CreditSettingsResModel>({
        created_at:'',
        cs_id:0,
        price:0,
        updated_at:''
    });
    const {t} = useTranslation();

    const getList = useCallback(async()=>{
        service.getTierList().then(e => {
            if (!e.result) return;
            setState(e.data);
        });
    },[service,setState])

    const getSettings = useCallback(async()=>{
        service.creditSettings().then(e=>{
            if(!e.result) return;
            setSettings(e.data);
        });
    },[service,setSettings])

    useEffect(() => {
        getList();
        getSettings();
        // eslint-disable-next-line
    }, [])

    const columns: IColumnProps<TierListResModel>[] = [
        {
            dataIndex: 'tier_number',
            title: 'TierNumber',
            isTag: true
        },
        {
            dataIndex: 'price',
            title: 'Price',
            isTag: true
        },
        {
            dataIndex: 'credit',
            title: 'Credit',
            isTag: true
        },
        {
            dataIndex:'created_at',
            title:'CreatedDate',
            isDate:true
        },
        {
            dataIndex:'updated_at',
            title:'UpdatedDate',
            isDate:true
        },
        {
            dataIndex:'tier_id',
            actionColumn:{
                hasUpdate:{
                    propName:'tier_id',
                    callback:(data,r)=> setModalData({data:r, open:true})
                }
            }
        }
    ];

    const creditColumns:IColumnProps<CreditSettingsResModel>[]=[
        {
            dataIndex:'price',
            title:'Price',
            editable:{
                element:<StyledNumberInput   />
            }
        },
        {
            dataIndex:'created_at',
            title:'CreateDate',
            isDate:true
        },
        {
            dataIndex:'updated_at',
            title:'UpdatedDate',
            isDate:true
        }
    ];

    return (
        <PageCard>
            <Card style={{ marginBottom: 20 }} title={t('TierCreditRate')}>
                <ITable
                    columns={creditColumns}
                    dataSource={[
                        settings
                    ]}
                    onSaveRow={async (d) => {
                        const res = await service.updateCreditSettings({
                            price:d.price
                        })
                        if (!res.result) return;
                        getSettings()
                    }}
                />
            </Card>
            <Card
                title={t('TierToCoin')}
            >
            <ITable
                columns={columns}
                dataSource={state}
                rowKey={'tier'}
            />
            </Card>
            <TierModal 
                data={modalData}
                onCancel={()=> setModalData(ModalDataModel())}
                onDone={()=>{
                    setModalData(ModalDataModel())
                    getList()
                }}
            />
        </PageCard>
    )
}