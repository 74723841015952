import React, { useCallback, useEffect, useState } from 'react';
import { useHttp } from '../../hooks/http';
import { YesOrNoEnum } from '../../models/system';
import { IColumnProps } from '../../components/table';
import { ITable } from '../../components/table/table';
import { PageCard } from '../../components/pageCard';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store';

interface LanguagesProps {
}

interface LanguageModel {
    code: string;
    created_at: string;
    isActive: YesOrNoEnum
    isAppLanguage: YesOrNoEnum
    language: string;
    language_id: number;
    updated_at: string | null
}

export const Languages: React.FC<LanguagesProps> = props => {
    const http = useHttp();
    const [state, setState] = useState<LanguageModel[]>();
    const { t } = useTranslation();
    const store = useStore();

    const getList = useCallback(async () => {
        const res = await http.get<{ data: LanguageModel[] }>('/languages/all');
        if (!res.result) return;
        setState(res.data);
    }, [http, setState])

    const columns: IColumnProps<LanguageModel>[] = [
        {
            dataIndex: 'code',
            title: 'LanguageCode',
        },
        {
            dataIndex: 'language',
            title: 'LanguageName',
            textFilter: true
        },
        {
            dataIndex: 'isActive',
            title: 'Status',
            isYesOrNo: true
        },
        {
            dataIndex: 'updated_at',
            isDate: true,
            title: 'UpdatedDate'
        },
        {
            dataIndex: 'language_id',
            actionColumn: {
                items: (row) => [
                    {
                        key: 'active',
                        label: t('Active'),
                        disabled: row.isActive === YesOrNoEnum.Yes,
                        onClick: async () => {
                            const res = await http.post('languages/activate', { language_id: row.language_id });
                            if (!res.result) return;
                            store.getLanguages(true)
                            getList()
                        }
                    },
                    {
                        key: 'DeActive',
                        label: t('DeActive'),
                        disabled: row.isActive === YesOrNoEnum.No || row.isAppLanguage === YesOrNoEnum.Yes,
                        onClick: async () => {
                            const res = await http.post('languages/deactivate', { language_id: row.language_id });
                            if (!res.result) return;
                            store.getLanguages(true)
                            getList()
                        }
                    }
                ]
            }
        }
    ]

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <PageCard>
                <ITable
                    dataSource={state}
                    columns={columns}
                />
            </PageCard>
        </>
    )
}