import { useCallback } from "react";
import { useHttp } from "../../hooks/http"
import { BookReviewApproveReqModel, BookReviewDeclineReqModel, BookReviewWaitingListResModel } from "./bookReview";

export const useBookReviewService = () => {

    const http = useHttp();


    const getWaitingList = useCallback(async () => {
        return await http.get<{ data: BookReviewWaitingListResModel[] }>('/book-reviews/waiting');
    }, [http])

    const getAllList = useCallback(async () => {
        return await http.get<{ data: BookReviewWaitingListResModel[] }>('/book-reviews/all');
    }, [http])

    const approve = useCallback(async (req: BookReviewApproveReqModel) => {
        return await http.post('/book-reviews/approve', req)
    }, [http])

    const decline = useCallback(async (req: BookReviewDeclineReqModel) => {
        return await http.post('/book-reviews/decline', req)
    }, [http])

    const remove = useCallback(async (id: number) => {
        return await http.delete(`/book-reviews/${id}`)
    }, [http])

    const removeComment = useCallback(async (br_id: number) => {
        return await http.delete(`/book-review/${br_id}`);
    }, [http])

    return {
        getWaitingList,
        removeComment,
        getAllList,
        approve,
        decline,
        remove
    }

}