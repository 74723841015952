export const GoogleTextToSpeechConfigParamsResources = {
    languageSettings: [
        {
            "country": "Afrikaans (South Africa)",
            "name": [
                "af-ZA-Standard-A"
            ],
            "langCode": "af-ZA",
            "ssmlGender": [
                "FEMALE"
            ]
        },
        {
            "country": "Amharic (Ethiopia)",
            "name": [
                "am-ET-Standard-A",
                "am-ET-Standard-B",
                "am-ET-Wavenet-A",
                "am-ET-Wavenet-B"
            ],
            "langCode": "am-ET",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        },
        {
            "country": "Bengali (India)",
            "name": [
                "bn-IN-Standard-A",
                "bn-IN-Standard-B",
                "bn-IN-Standard-C",
                "bn-IN-Standard-D",
                "bn-IN-Wavenet-A",
                "bn-IN-Wavenet-B",
                "bn-IN-Wavenet-C",
                "bn-IN-Wavenet-D"
            ],
            "langCode": "bn-IN",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        },
        {
            "country": "Catalan (Spain)",
            "name": [
                "ca-ES-Standard-A"
            ],
            "langCode": "ca-ES",
            "ssmlGender": [
                "FEMALE"
            ]
        },
        {
            "country": "Czech (Czech Republic)",
            "name": [
                "cs-CZ-Standard-A",
                "cs-CZ-Wavenet-A"
            ],
            "langCode": "cs-CZ",
            "ssmlGender": [
                "FEMALE"
            ]
        },
        {
            "country": "Danish (Denmark)",
            "name": [
                "da-DK-Neural2-D",
                "da-DK-Standard-A",
                "da-DK-Standard-C",
                "da-DK-Standard-D",
                "da-DK-Standard-E",
                "da-DK-Wavenet-A",
                "da-DK-Wavenet-C",
                "da-DK-Wavenet-D",
                "da-DK-Wavenet-E"
            ],
            "langCode": "da-DK",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        },
        {
            "country": "German (Germany)",
            "name": [
                "de-DE-Neural2-A",
                "de-DE-Neural2-B",
                "de-DE-Neural2-C",
                "de-DE-Neural2-D",
                "de-DE-Neural2-F",
                "de-DE-Polyglot-1",
                "de-DE-Standard-A",
                "de-DE-Standard-B",
                "de-DE-Standard-C",
                "de-DE-Standard-D",
                "de-DE-Standard-E",
                "de-DE-Standard-F",
                "de-DE-Studio-B",
                "de-DE-Studio-C",
                "de-DE-Wavenet-A",
                "de-DE-Wavenet-B",
                "de-DE-Wavenet-C",
                "de-DE-Wavenet-D",
                "de-DE-Wavenet-E",
                "de-DE-Wavenet-F"
            ],
            "langCode": "de-DE",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        },
        {
            "country": "English (Australia)",
            "name": [
                "en-AU-Neural2-A",
                "en-AU-Neural2-B",
                "en-AU-Neural2-C",
                "en-AU-Neural2-D",
                "en-AU-News-E",
                "en-AU-News-F",
                "en-AU-News-G",
                "en-AU-Polyglot-1",
                "en-AU-Standard-A",
                "en-AU-Standard-B",
                "en-AU-Standard-C",
                "en-AU-Standard-D",
                "en-AU-Wavenet-A",
                "en-AU-Wavenet-B",
                "en-AU-Wavenet-C",
                "en-AU-Wavenet-D"
            ],
            "langCode": "en-AU",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        },
        {
            "country": "English (United Kingdom)",
            "name": [
                "en-GB-Neural2-A",
                "en-GB-Neural2-B",
                "en-GB-Neural2-C",
                "en-GB-Neural2-D",
                "en-GB-Neural2-F",
                "en-GB-News-G",
                "en-GB-News-H",
                "en-GB-News-I",
                "en-GB-News-J",
                "en-GB-News-K",
                "en-GB-News-L",
                "en-GB-News-M",
                "en-GB-Standard-A",
                "en-GB-Standard-B",
                "en-GB-Standard-C",
                "en-GB-Standard-D",
                "en-GB-Standard-F",
                "en-GB-Studio-B",
                "en-GB-Studio-C",
                "en-GB-Wavenet-A",
                "en-GB-Wavenet-B",
                "en-GB-Wavenet-C",
                "en-GB-Wavenet-D",
                "en-GB-Wavenet-F"
            ],
            "langCode": "en-GB",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        },
        {
            "country": "English (India)",
            "name": [
                "en-IN-Neural2-A",
                "en-IN-Neural2-B",
                "en-IN-Neural2-C",
                "en-IN-Neural2-D",
                "en-IN-Standard-A",
                "en-IN-Standard-B",
                "en-IN-Standard-C",
                "en-IN-Standard-D",
                "en-IN-Wavenet-A",
                "en-IN-Wavenet-B",
                "en-IN-Wavenet-C",
                "en-IN-Wavenet-D"
            ],
            "langCode": "en-IN",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        },
        {
            "country": "English (United States)",
            "name": [
                "en-US-Casual-K",
                "en-US-Journey-D",
                "en-US-Journey-F",
                "en-US-Neural2-A",
                "en-US-Neural2-C",
                "en-US-Neural2-D",
                "en-US-Neural2-E",
                "en-US-Neural2-F",
                "en-US-Neural2-G",
                "en-US-Neural2-H",
                "en-US-Neural2-I",
                "en-US-Neural2-J",
                "en-US-News-K",
                "en-US-News-L",
                "en-US-News-N",
                "en-US-Polyglot-1",
                "en-US-Standard-A",
                "en-US-Standard-B",
                "en-US-Standard-C",
                "en-US-Standard-D",
                "en-US-Standard-E",
                "en-US-Standard-F",
                "en-US-Standard-G",
                "en-US-Standard-H",
                "en-US-Standard-I",
                "en-US-Standard-J",
                "en-US-Studio-O",
                "en-US-Studio-Q",
                "en-US-Wavenet-A",
                "en-US-Wavenet-B",
                "en-US-Wavenet-C",
                "en-US-Wavenet-D",
                "en-US-Wavenet-E",
                "en-US-Wavenet-F",
                "en-US-Wavenet-G",
                "en-US-Wavenet-H",
                "en-US-Wavenet-I",
                "en-US-Wavenet-J"
            ],
            "langCode": "en-US",
            "ssmlGender": [
                "MALE",
                "FEMALE"
            ]
        },
        {
            "country": "Spanish (Spain)",
            "name": [
                "es-ES-Neural2-A",
                "es-ES-Neural2-B",
                "es-ES-Neural2-C",
                "es-ES-Neural2-D",
                "es-ES-Neural2-E",
                "es-ES-Neural2-F",
                "es-ES-Polyglot-1",
                "es-ES-Standard-A",
                "es-ES-Standard-B",
                "es-ES-Standard-C",
                "es-ES-Standard-D",
                "es-ES-Wavenet-B",
                "es-ES-Wavenet-C",
                "es-ES-Wavenet-D"
            ],
            "langCode": "es-ES",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        },
        {
            "country": "Spanish (United States)",
            "name": [
                "es-US-Neural2-A",
                "es-US-Neural2-B",
                "es-US-Neural2-C",
                "es-US-News-D",
                "es-US-News-E",
                "es-US-News-F",
                "es-US-News-G",
                "es-US-Polyglot-1",
                "es-US-Standard-A",
                "es-US-Standard-B",
                "es-US-Standard-C",
                "es-US-Studio-B",
                "es-US-Wavenet-A",
                "es-US-Wavenet-B",
                "es-US-Wavenet-C"
            ],
            "langCode": "es-US",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        },
        {
            "country": "Basque (Spain)",
            "name": [
                "eu-ES-Standard-A"
            ],
            "langCode": "eu-ES",
            "ssmlGender": [
                "FEMALE"
            ]
        },
        {
            "country": "Filipino (Philippines)",
            "name": [
                "fil-PH-Standard-A",
                "fil-PH-Standard-B",
                "fil-PH-Standard-C",
                "fil-PH-Standard-D",
                "fil-PH-Wavenet-A",
                "fil-PH-Wavenet-B",
                "fil-PH-Wavenet-C",
                "fil-PH-Wavenet-D",
                "fil-ph-Neural2-A",
                "fil-ph-Neural2-D"
            ],
            "langCode": "fil-PH",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        },
        {
            "country": "French (Canada)",
            "name": [
                "fr-CA-Neural2-A",
                "fr-CA-Neural2-B",
                "fr-CA-Neural2-C",
                "fr-CA-Neural2-D",
                "fr-CA-Standard-A",
                "fr-CA-Standard-B",
                "fr-CA-Standard-C",
                "fr-CA-Standard-D",
                "fr-CA-Wavenet-A",
                "fr-CA-Wavenet-B",
                "fr-CA-Wavenet-C",
                "fr-CA-Wavenet-D"
            ],
            "langCode": "fr-CA",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        },
        {
            "country": "French (France)",
            "name": [
                "fr-FR-Neural2-A",
                "fr-FR-Neural2-B",
                "fr-FR-Neural2-C",
                "fr-FR-Neural2-D",
                "fr-FR-Neural2-E",
                "fr-FR-Polyglot-1",
                "fr-FR-Standard-A",
                "fr-FR-Standard-B",
                "fr-FR-Standard-C",
                "fr-FR-Standard-D",
                "fr-FR-Standard-E",
                "fr-FR-Studio-A",
                "fr-FR-Studio-D",
                "fr-FR-Wavenet-A",
                "fr-FR-Wavenet-B",
                "fr-FR-Wavenet-C",
                "fr-FR-Wavenet-D",
                "fr-FR-Wavenet-E"
            ],
            "langCode": "fr-FR",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        },
        {
            "country": "Galician (Spain)",
            "name": [
                "gl-ES-Standard-A"
            ],
            "langCode": "gl-ES",
            "ssmlGender": [
                "FEMALE"
            ]
        },
        {
            "country": "Gujarati (India)",
            "name": [
                "gu-IN-Standard-A",
                "gu-IN-Standard-B",
                "gu-IN-Wavenet-A",
                "gu-IN-Wavenet-B"
            ],
            "langCode": "gu-IN",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        },
        {
            "country": "Indonesian (Indonesia)",
            "name": [
                "id-ID-Standard-A",
                "id-ID-Standard-B",
                "id-ID-Standard-C",
                "id-ID-Standard-D",
                "id-ID-Wavenet-A",
                "id-ID-Wavenet-B",
                "id-ID-Wavenet-C",
                "id-ID-Wavenet-D"
            ],
            "langCode": "id-ID",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        },
        {
            "country": "Icelandic (Iceland)",
            "name": [
                "is-IS-Standard-A"
            ],
            "langCode": "is-IS",
            "ssmlGender": [
                "FEMALE"
            ]
        },
        {
            "country": "Italian (Italy)",
            "name": [
                "it-IT-Neural2-A",
                "it-IT-Neural2-C",
                "it-IT-Standard-A",
                "it-IT-Standard-B",
                "it-IT-Standard-C",
                "it-IT-Standard-D",
                "it-IT-Wavenet-A",
                "it-IT-Wavenet-B",
                "it-IT-Wavenet-C",
                "it-IT-Wavenet-D"
            ],
            "langCode": "it-IT",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        },
        {
            "country": "Kannada (India)",
            "name": [
                "kn-IN-Standard-A",
                "kn-IN-Standard-B",
                "kn-IN-Standard-C",
                "kn-IN-Standard-D",
                "kn-IN-Wavenet-A",
                "kn-IN-Wavenet-B",
                "kn-IN-Wavenet-C",
                "kn-IN-Wavenet-D"
            ],
            "langCode": "kn-IN",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        },
        {
            "country": "Lithuanian (Lithuania)",
            "name": [
                "lt-LT-Standard-A"
            ],
            "langCode": "lt-LT",
            "ssmlGender": [
                "MALE"
            ]
        },
        {
            "country": "Latvian (Latvia)",
            "name": [
                "lv-LV-Standard-A"
            ],
            "langCode": "lv-LV",
            "ssmlGender": [
                "MALE"
            ]
        },
        {
            "country": "Malayalam (India)",
            "name": [
                "ml-IN-Standard-A",
                "ml-IN-Standard-B",
                "ml-IN-Standard-C",
                "ml-IN-Standard-D",
                "ml-IN-Wavenet-A",
                "ml-IN-Wavenet-B",
                "ml-IN-Wavenet-C",
                "ml-IN-Wavenet-D"
            ],
            "langCode": "ml-IN",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        },
        {
            "country": "Marathi (India)",
            "name": [
                "mr-IN-Standard-A",
                "mr-IN-Standard-B",
                "mr-IN-Standard-C",
                "mr-IN-Wavenet-A",
                "mr-IN-Wavenet-B",
                "mr-IN-Wavenet-C"
            ],
            "langCode": "mr-IN",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        },
        {
            "country": "Malay (Malaysia)",
            "name": [
                "ms-MY-Standard-A",
                "ms-MY-Standard-B",
                "ms-MY-Standard-C",
                "ms-MY-Standard-D",
                "ms-MY-Wavenet-A",
                "ms-MY-Wavenet-B",
                "ms-MY-Wavenet-C",
                "ms-MY-Wavenet-D"
            ],
            "langCode": "ms-MY",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        },
        {
            "country": "Polish (Poland)",
            "name": [
                "pl-PL-Standard-A",
                "pl-PL-Standard-B",
                "pl-PL-Standard-C",
                "pl-PL-Standard-D",
                "pl-PL-Standard-E",
                "pl-PL-Wavenet-A",
                "pl-PL-Wavenet-B",
                "pl-PL-Wavenet-C",
                "pl-PL-Wavenet-D",
                "pl-PL-Wavenet-E"
            ],
            "langCode": "pl-PL",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        },
        {
            "country": "Portuguese (Brazil)",
            "name": [
                "pt-BR-Neural2-A",
                "pt-BR-Neural2-B",
                "pt-BR-Neural2-C",
                "pt-BR-Standard-A",
                "pt-BR-Standard-B",
                "pt-BR-Standard-C",
                "pt-BR-Wavenet-A",
                "pt-BR-Wavenet-B",
                "pt-BR-Wavenet-C"
            ],
            "langCode": "pt-BR",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        },
        {
            "country": "Portuguese (Portugal)",
            "name": [
                "pt-PT-Standard-A",
                "pt-PT-Standard-B",
                "pt-PT-Standard-C",
                "pt-PT-Standard-D",
                "pt-PT-Wavenet-A",
                "pt-PT-Wavenet-B",
                "pt-PT-Wavenet-C",
                "pt-PT-Wavenet-D"
            ],
            "langCode": "pt-PT",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        },
        {
            "country": "Romanian (Romania)",
            "name": [
                "ro-RO-Standard-A",
                "ro-RO-Wavenet-A"
            ],
            "langCode": "ro-RO",
            "ssmlGender": [
                "FEMALE"
            ]
        },
        {
            "country": "Russian (Russia)",
            "name": [
                "ru-RU-Standard-A",
                "ru-RU-Standard-B",
                "ru-RU-Standard-C",
                "ru-RU-Standard-D",
                "ru-RU-Standard-E",
                "ru-RU-Wavenet-A",
                "ru-RU-Wavenet-B",
                "ru-RU-Wavenet-C",
                "ru-RU-Wavenet-D",
                "ru-RU-Wavenet-E"
            ],
            "langCode": "ru-RU",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        },
        {
            "country": "Slovak (Slovakia)",
            "name": [
                "sk-SK-Standard-A",
                "sk-SK-Wavenet-A"
            ],
            "langCode": "sk-SK",
            "ssmlGender": [
                "FEMALE"
            ]
        },
        {
            "country": "Serbian (Serbia)",
            "name": [
                "sr-RS-Standard-A"
            ],
            "langCode": "sr-RS",
            "ssmlGender": [
                "FEMALE"
            ]
        },
        {
            "country": "Tamil (India)",
            "name": [
                "ta-IN-Standard-A",
                "ta-IN-Standard-B",
                "ta-IN-Standard-C",
                "ta-IN-Standard-D",
                "ta-IN-Wavenet-A",
                "ta-IN-Wavenet-B",
                "ta-IN-Wavenet-C",
                "ta-IN-Wavenet-D"
            ],
            "langCode": "ta-IN",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        },
        {
            "country": "Telugu (India)",
            "name": [
                "te-IN-Standard-A",
                "te-IN-Standard-B"
            ],
            "langCode": "te-IN",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        },
        {
            "country": "Thai (Thailand)",
            "name": [
                "th-TH-Neural2-C",
                "th-TH-Standard-A"
            ],
            "langCode": "th-TH",
            "ssmlGender": [
                "FEMALE"
            ]
        },
        {
            "country": "Turkish (Turkey)",
            "name": [
                "tr-TR-Standard-A",
                "tr-TR-Standard-B",
                "tr-TR-Standard-C",
                "tr-TR-Standard-D",
                "tr-TR-Standard-E",
                "tr-TR-Wavenet-A",
                "tr-TR-Wavenet-B",
                "tr-TR-Wavenet-C",
                "tr-TR-Wavenet-D",
                "tr-TR-Wavenet-E"
            ],
            "langCode": "tr-TR",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        },
        {
            "country": "Ukrainian (Ukraine)",
            "name": [
                "uk-UA-Standard-A",
                "uk-UA-Wavenet-A"
            ],
            "langCode": "uk-UA",
            "ssmlGender": [
                "FEMALE"
            ]
        },
        {
            "country": "Vietnamese (Vietnam)",
            "name": [
                "vi-VN-Neural2-A",
                "vi-VN-Neural2-D",
                "vi-VN-Standard-A",
                "vi-VN-Standard-B",
                "vi-VN-Standard-C",
                "vi-VN-Standard-D",
                "vi-VN-Wavenet-A",
                "vi-VN-Wavenet-B",
                "vi-VN-Wavenet-C",
                "vi-VN-Wavenet-D"
            ],
            "langCode": "vi-VN",
            "ssmlGender": [
                "FEMALE",
                "MALE"
            ]
        }
    ],
    effectsProfileIds: [
        "wearable-class-device",
        "handset-class-device",
        "headphone-class-device",
        "small-bluetooth-speaker-class-device",
        "medium-bluetooth-speaker-class-device",
        "large-home-entertainment-class-device",
        "large-automotive-class-device",
        "telephony-class-application"
    ]
}
