import { useEffect, useState } from "react";
import { PageCard } from "../../components/pageCard"
import { useHttp } from "../../hooks/http"
import { BookReportedType } from "./bookReportType";
import { IColumnProps } from "../../components/table";
import { ITable } from "../../components/table/table";
import { Button, Card, Col, Image, Row, Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useStore } from "../../store";
import { FlagOutlined, PlayCircleOutlined, SoundOutlined, UserOutlined } from "@ant-design/icons";
import Meta from "antd/es/card/Meta";
import { ModalData, ModalDataModel } from "../../models/system";
import { SummaryDeclineModal } from "../summary/summaryDeclineModal";

export const ReportBooks: React.FC<{
    data?: any[]
}> = (props) => {

    const store = useStore()
    const [declineModal, setDeclineModal] = useState<ModalData<number>>(ModalDataModel());
    const [activeRow, setActiveRow] = useState<number>(0);
    const http = useHttp();

    useEffect(() => {
        if (!props.data) {
            store.getReportedBooks()
        }
        // eslint-disable-next-line
    }, [])

    const columns: IColumnProps<BookReportedType>[] = [
        {
            dataIndex: 'flagged_user_book',
            render: (v, r) => r.flagged_user_book.book.book_name,
            title: 'Book',
            textFilter: true,
            textFilterData: (row) => row.flagged_user_book.book.book_name,
        },
        {
            dataIndex: 'user',
            render: (v, r) => `${r.flagged_user_book.user.name} ${r.flagged_user_book.user.surname} / ${r.flagged_user_book.user.username}`,
            title: 'flagged_user',
            textFilter: true,
            textFilterData: (row) => row.flagged_user_book.user.name + " " + row.flagged_user_book.user.surname,

        },

        {
            dataIndex: 'user',
            render: (v, r) => `${r.user.name} ${r.user.surname} / ${r.user.username}`,
            title: 'reporter_user',
            textFilterData: (row) => row.user.name + " " + row.user.surname,
            textFilter: true
        },
        {
            dataIndex: ['flagged_user_book', 'audio_url'],
            title: 'Audio',
            render: (v) => <Button onClick={() => store.setSound(v)}><PlayCircleOutlined /></Button>
        },
        {
            dataIndex: 'reason',
            title: 'Reason',
            textFilterData: (row) => row.reason,
            textFilter: true
        },
        {
            dataIndex: 'created_at',
            title: 'Date',
            sortDate: true,
            isDate: true
        },
        {
            dataIndex: 'br_id',
            actionColumn: {
                items(row) {
                    return [
                        {
                            label: t('UnPublish'),
                            onClick: () => {
                                setActiveRow(row.br_id);
                                setDeclineModal({ data: row.flagged_user_book.ub_id, open: true })
                            },
                            key: 'unpublish'
                        },
                        {
                            label: t('SetRead'),
                            onClick: async () => {
                                const res = await http.post('/book-report/read', { br_id: row.br_id })
                                if (!res.result) return;
                                store.getReportedBooks()
                            },
                            key:'setRead'
                        }
                    ]
                },
            }
        }

    ]

    const { t } = useTranslation()
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])
    return (
        <PageCard title={props.data ? ' ' : undefined}>
            <ITable
                rowKey={"index"}
                columns={columns}
                dataSource={props.data || store.data.reportedBookList}
                expandable={{
                    rowExpandable(record: BookReportedType) {
                        return Boolean(record.index)
                    },
                    expandedRowKeys: expandedRowKeys,
                    onExpand(expanded, record: BookReportedType) {
                        setExpandedRowKeys(p => {
                            if (expanded) {
                                p.push(record.index)
                                return p;
                            }

                            return p.filter(key => key !== record.index)
                        })
                    },
                    expandedRowRender: (r: BookReportedType, i, indent, expanded) => {

                        if (!expanded) return null
                        const { user } = r;
                        return (
                            <div
                                key={indent}
                            >
                                <Row gutter={16}>
                                    <Col span={6}>
                                        <Card
                                            title={t('Book')}
                                            cover={
                                                <span
                                                    style={{
                                                        width: '100%',
                                                        height: 200,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        background: '#1a1a1a',
                                                    }}
                                                >
                                                    <Image
                                                        alt={r.flagged_user_book.book.book_name}
                                                        src={r.flagged_user_book.book.image}
                                                        style={{
                                                            width: '90%',
                                                            textAlign: 'center'
                                                        }}

                                                    />
                                                </span>
                                            }
                                            actions={[
                                                <SoundOutlined onClick={() => store.setSound(r.flagged_user_book.audio_url)} key="play" />,
                                            ]}
                                        >
                                            <Meta
                                                title={r.flagged_user_book.book.book_name}
                                                description={
                                                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                                                        <Tag color="blue" icon={<FlagOutlined />} children={<>{r.flagged_user_book.book.language.toUpperCase()}</>} />
                                                    </div>
                                                }
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={18}>
                                        <Row gutter={12}>
                                            <Col span={12}>
                                                <Card
                                                    title={t('Bookfluencer')}
                                                >
                                                    <Meta
                                                        title={<>{r.flagged_user_book.user.name} {r.flagged_user_book.user.surname}</>}
                                                        description={
                                                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                                                                <Tag color="orange" icon={<UserOutlined />} children={<>{r.flagged_user_book.user.username}</>} />
                                                            </div>
                                                        }
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={12}>
                                                <Card
                                                    title={t('Reporter')}
                                                >
                                                    <Meta
                                                        title={<>{user.name} {user.surname}</>}
                                                        description={
                                                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                                                                <Tag color="orange" icon={<UserOutlined />} children={<>{user.username}</>} />
                                                            </div>
                                                        }
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={24}>
                                                <Card
                                                    title={t('Reason')}
                                                >
                                                    <Typography.Text>{r.reason}</Typography.Text>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                }}
            />
            <SummaryDeclineModal
                data={declineModal}
                onCancel={() => setDeclineModal(ModalDataModel())}
                onDone={async () => {
                    setDeclineModal(ModalDataModel())
                    const res = await http.post('/book-report/read', { br_id: activeRow })
                    if (!res.result) return;
                    store.getReportedBooks()
                }}
            />
        </PageCard>
    )
}