import { YesOrNoEnum } from "../../models/system";

export interface BannerGetAllReModel {

}

export enum BannerType {
    TopBanner = 0,
    BottomBanner = 1,

}

export interface BannerCreateReqModel {
    banner_type: BannerType;
    book_id?: number;
    image: string;
    language: string;
    priority: number;
    start_date: string;
    end_date: string;
}

export interface BannerUpdateReqModel {
    banner_type: BannerType;
    book_id?: number;
    language: string;
    priority: number;
    start_date: string;
    end_date: string;
}

export interface BannerGetByLangResModel {
    banner_type: BannerType;
    book_id: number;
    image_url: string;
    language: string;
    priority: number;
    banner_id: number;
    start_date: string;
    end_date: string;
    isActive: YesOrNoEnum;
}
