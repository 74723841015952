import { YesOrNoEnum } from "../../models/system";

export interface AdminGetAllResModel {
    admin_id: number;
    username: string;
    admin_type: string;
    isActive: YesOrNoEnum;
    updated_at: string;
    created_at: string;
}

export interface AdminUpdatePasswordReqModel {
    // username: string;
    old_password: string;
    new_password: string;
}

export enum AdminType {
    super = 'super',
    marketing = 'marketing',
    finance = 'finance',
    // support = 'support',
}

export interface AdminCreateReqModel {
    username: string;
    password: string;
    admin_type: AdminType;
}

