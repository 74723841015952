import React, { useCallback, useMemo } from 'react';
import { Col, ColProps, FormItemProps, InputProps, SelectProps } from "antd";
import { Rule } from 'antd/es/form';
import { StyledFormItem } from '../components/form';
import { useTranslation } from 'react-i18next';
import { NamePath } from 'antd/es/form/interface';
import { ComponentTypes } from '../models/system';


interface FormItemType<T = any> extends FormItemProps<any> {
    label: string;
    name: NamePath<T>;
}

export interface FormItemGeneratorData<T = any> {
    formItem: FormItemType<T>;
    active?: boolean;
    colProps: ColProps;
    element: React.ReactNode;
    elementType: ComponentTypes;
    elementProps?: InputProps | SelectProps;
}

export const useCreateFormItems = function <T = any>(data: FormItemGeneratorData<T>[]): React.ReactNode[] {

    const { t } = useTranslation();

    const valuePropName = useCallback((item: FormItemGeneratorData<any>) => {
        switch (item.elementType) {
            case ComponentTypes.Switch:
                return 'checked';
            default:
                return item.formItem.valuePropName
        }
    }, [])


    const items = useMemo(() => {
        return data.map(item => {
            if (item.active !== undefined && item.active === false) return null
            const rules: Rule[] = item.formItem.rules || [];

            if (item.elementType === ComponentTypes.InputEmail) {
                rules.push({
                    type: 'email'
                })
            }

            const col: ColProps = {
                ...item.colProps,
                xl: item.colProps.span,
                span: 24
            }

            return (
                <Col key={String(item.formItem.name)} {...col}>
                    <StyledFormItem
                        {...item.formItem}
                        label={t(item.formItem.label)}
                        rules={[
                            ...rules
                        ]}
                        labelCol={{
                            span: 24
                        }}
                        valuePropName={valuePropName(item)}
                    >
                        {item.element}
                    </StyledFormItem>
                </Col>
            )
        })
    }, [valuePropName, data, t])


    return items;

}