import { BankOutlined, BookOutlined, CommentOutlined, DashboardOutlined, FieldTimeOutlined, FileOutlined, FileSearchOutlined, FlagOutlined, FolderOpenOutlined, PieChartOutlined, SettingOutlined, ShoppingOutlined, SlidersOutlined, StarOutlined, UserOutlined, WarningOutlined } from "@ant-design/icons";
import { Main } from "../components/system/main";
import { Error404 } from "../pages/404";
import { Login } from "../pages/login/login";
import { IRouteProp } from "./type";
import { Books } from "../pages/books/books";
import { Interest } from "../pages/interest/interest";
import { Dashboard } from "../pages/dashboard/dashboard";
import { Users } from "../pages/users/users";
import { Summary } from "../pages/summary/summary";
import { Comments } from "../pages/comments/comments";
import { Banner } from "../pages/banner/banner";
import { WithdrawManager } from "../pages/withdrawManager/withdrawManager";
import { WithdrawAllList } from "../pages/withdrawManager/withdrawAllList";
import { CommentsAll } from "../pages/comments/commentsAll";
import { SummaryWaiting } from "../pages/summary/summaryWaiting";
import { AdminUsers } from "../pages/settings/adminUsers";
import { Agreements } from "../pages/settings/agreements/agreements";
import { NewBook } from "../pages/books/newBook";
import { SummaryDeclinedList } from "../pages/summary/summaryDeclinedList";
import { Commissions } from "../pages/commissions/commissions";
import { PopularInterests } from "../pages/interest/popularInterests";
import { TierList } from "../pages/settings/tierList";
import { ReportBooks } from "../pages/reporteds/bookReport";
import { ReportedUsers } from "../pages/reporteds/userReported";
import { Languages } from "../pages/settings/languages";
import { AdminType } from "../services/admin/admin";
import { Donations } from "../pages/sales/donations";
import { Credits } from "../pages/sales/credit";
import { SummarySales } from "../pages/sales/summary";

export const mainRouteList: IRouteProp[] = [
    {
        path: '/login',
        element: <Login />,
        id: 'login'
    },
    {
        element: <Main />,
        isDashboard: true,
        id: 'layout',
        children: [
            {
                path: '/',
                element: <Dashboard />,
                id: 'dashboard',
                handle: {
                    title: 'Dashboard',
                    icon: DashboardOutlined
                }

            },
            {
                path: '/interest',
                element: <Interest />,
                id: 'interest',
                adminTypes:[
                    AdminType.marketing,
                ],
                handle: {
                    title: 'Interest',
                    icon: FolderOpenOutlined
                }
            },
            {
                path: '/interest-popular',
                element: <PopularInterests />,
                id: 'interest-pop',
                adminTypes:[
                    AdminType.marketing,
                ],
                handle: {
                    title: 'PopularInterests',
                    icon: FolderOpenOutlined
                }
            },
            {
                path: '/books/new',
                id: "newbook",
                element: <NewBook />,
                handle: {
                    noMenu: true,
                    icon: BookOutlined,
                    title: "NewBook"
                },
                adminTypes:[
                    AdminType.super,
                ],
            },
            {
                path: '/summary',
                id: 'summary-manager',
                handle: {
                    title: 'SummaryManager',
                    icon: FileSearchOutlined
                },
                adminTypes:[
                    AdminType.marketing,
                    AdminType.super
                ],
                children: [
                    {
                        path: '/summary/list',
                        id: 'sumarylist',
                        element: <Summary />,
                        handle: {
                            title: 'SummaryList',
                            icon: FileSearchOutlined
                        },
                        adminTypes:[
                            AdminType.marketing,
                        ],
                    },
                    {
                        path: '/summary/waiting',
                        id: 'sumarylistwaiting',
                        element: <SummaryWaiting />,
                        handle: {
                            title: 'SummaryManagerWaiting',
                            icon: FileSearchOutlined
                        },
                        adminTypes:[
                            AdminType.super,
                        ],
                    },
                    {
                        path: '/summary/declined-list',
                        id: 'summaryDeclinedList',
                        element: <SummaryDeclinedList />,
                        adminTypes:[
                            AdminType.super,
                        ],
                        handle: {
                            title: 'SummaryDeclinedList',
                            icon: FileSearchOutlined
                        }
                    }
                ]
            },
            {
                path: '/books',
                id: 'books',
                element: <Books />,
                adminTypes:[
                    AdminType.marketing,
                    AdminType.super
                ],
                handle: {
                    title: 'Books',
                    icon: BookOutlined
                },
                // children: [
                //     {
                //         path: '/books',

                //         id: 'bookslist',
                //         handle: {
                //             title: 'Books',
                //             icon: BookOutlined
                //         },
                //     },
                //     {
                //         path: '/books/books-waiting-approve',
                //         element: <ApproveWaitingBooks />,
                //         id: 'books-waiting',
                //         handle: {
                //             title: 'BooksWaiting',
                //             icon: BookOutlined
                //         },

                //     }
                // ]
            },
            {
                path: '/users',
                element: <Users />,
                id: 'users',
                handle: {
                    title: 'Users',
                    icon: UserOutlined
                },
                adminTypes:[
                    AdminType.super
                ],
            },
            {
                path: '/sales',
                id: 'sales',
                // element: <AllSales />,
                handle: {
                    title: 'AllSales',
                    icon: ShoppingOutlined
                },
                adminTypes:[
                    AdminType.finance,
                    AdminType.super
                ],
               
                children:[
                    {
                        path:'/sales/donations',
                        id:'donations',
                        element: <Donations />,
                        handle:{
                            title:'Donations',
                            icon: ShoppingOutlined
                        }
                    },
                    {
                        path:'/sales/credit',
                        id:'credit',
                        element: <Credits />,
                        handle:{
                            title:'Credits',
                            icon: ShoppingOutlined
                        }
                    },
                    {
                        path:'/sales/summary',
                        id:'summary',
                        element: <SummarySales />,
                        handle:{
                            title:'Summary',
                            icon: ShoppingOutlined
                        }
                    },
                ]
            },

            {
                path: '/comments',
                id: 'comments',
                handle: {
                    title: 'CommentsManager',
                    icon: CommentOutlined
                },
                adminTypes:[
                    AdminType.marketing,
                    AdminType.super
                ],
                children: [
                    {
                        path: '/comments/waiting',
                        id: 'comments-waiting',
                        element: <Comments />,
                        handle: {
                            title: 'CommentsWaiting',
                            icon: CommentOutlined
                        },
                        adminTypes:[
                            AdminType.marketing,
                            AdminType.super
                        ],
                    },
                    {
                        path: '/comments/all',
                        id: 'comments-all',
                        element: <CommentsAll />,
                        handle: {
                            title: 'CommentsManager',
                            icon: CommentOutlined
                        },
                    },
                ]
            },
            {
                path: '/reports-warning',
                id: 'reportslist',
                handle: {
                    title: 'Reported',
                    icon: WarningOutlined
                },
                adminTypes:[
                    AdminType.super
                ],
                children: [
                    {
                        path: '/reports-warning/books',
                        id: 'report-book',
                        element: <ReportBooks />,
                        handle: {
                            title: 'ReportedBooks',
                            icon: BookOutlined
                        }
                    },
                    {
                        path: '/reports-warning/user',
                        id: 'report-user',
                        element: <ReportedUsers />,
                        handle: {
                            title: 'ReportedUsers',
                            icon: UserOutlined
                        }
                    }
                ]
            },
            // {
            //     path: '/reports',
            //     id: 'reports',
            //     element: <Reports />,
            //     handle: {
            //         title: 'Reports',
            //         icon: FundOutlined
            //     }
            // },
            {
                path: '/banner-manager',
                id: 'banner-manager',
                element: <Banner />,
                handle: {
                    title: 'BannerManager',
                    icon: StarOutlined
                },
                adminTypes:[
                    AdminType.marketing,
                    AdminType.super,
                ]
            },
            {
                path: '/withdraws',
                id: 'withdraws',
                handle: {
                    title: 'WithDraw',
                    icon: BankOutlined
                },
                adminTypes:[
                    AdminType.finance,
                    AdminType.super,
                ],
                children: [
                    {
                        path: '/withdraws/waiting',
                        element: <WithdrawManager />,
                        id: 'withdraw-waiting',
                        handle: {
                            title: 'WithdrawWaiting',
                            icon: FieldTimeOutlined
                        }
                    },
                    {
                        path: '/withdraws/all',
                        id: 'withdraw-all-list',
                        element: <WithdrawAllList />,
                        handle: {
                            title: 'WithdrawAllList',
                            icon: BankOutlined
                        }
                    }
                ]
            },
            {
                path: '/settings',
                id: 'settings',
                handle: {
                    title: 'Settings',
                    icon: SettingOutlined
                },
                adminTypes:[
                    AdminType.super,
                ],
                children: [
                    {
                        id: 'settings-language',
                        path: '/settings/languages',
                        element: <Languages />,
                        handle: {
                            title: 'Languages',
                            icon: FlagOutlined
                        }
                    },
                    {
                        path: '/settings/commissions',
                        id: 'commissions',
                        element: <Commissions />,
                        handle: {
                            title: 'Commissions',
                            icon: PieChartOutlined
                        }
                    },
                    {
                        path: '/settings/tiers',
                        id: 'settings-tiers',
                        element: <TierList />,
                        handle: {
                            title: 'TierList',
                            icon: SlidersOutlined
                        }
                    },
                    {
                        path: '/settings/admins',
                        id: 'admins',
                        element: <AdminUsers />,
                        handle: {
                            title: 'AdminUser',
                            icon: UserOutlined
                        }
                    },
                    {
                        path: '/settings/agreements',
                        id: 'agreements',
                        element: <Agreements />,
                        handle: {
                            title: 'Agreements',
                            icon: FileOutlined
                        }
                    }
                ]
            }
        ]
    },
    {
        id: 'error-page',
        element: <Error404 />,
        path: '*'
    }
];

export const dashboardRoutes = (): IRouteProp[] => {
    const dashboardRoute = mainRouteList.find(route => route.isDashboard);
    if (!dashboardRoute) return [];
    return dashboardRoute.children || []
}