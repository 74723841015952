import { useCallback } from "react";
import { useHttp } from "../../hooks/http"
import { TranslationAddReqModel, TranslationUpdateReqModel } from "./translation";

export const useTranslationService = () => {
    const http = useHttp();

    const add = useCallback(async (req: TranslationAddReqModel) => {
        return await http.post('/translation/add', req);
    }, [http])

    const update = useCallback(async (req: TranslationUpdateReqModel) => {
        return await http.put('/translation/update', req);
    }, [http])

    const remove = useCallback(async (translation_id: number) => {
        return await http.post('/translation/delete', { translation_id })
    }, [http])

    return {
        update,
        add,
        remove
    }
}