import { ThemeConfig } from "antd"


export const StyledComponentsTheme = {
    colors: {
        primary: '#08203E'
    }
}

export const AntDesignTheme: ThemeConfig = {
    token:{
        
    }
}