import { useCallback } from "react";
import { useHttp } from "../../hooks/http"
import { BookCoverUpdateReqModel, BookCreateReqModel, BookGetAllResModel, BookInterestsList, BookLangResModel, BookListReqModel, BookModel, BookSearchGoogleReqModel, BookSearchGoogleResModel, BookSearchReqModel, BookUpdateReqModel } from "./book";
import { PaginationResModel } from "../../models/system/http";

export const useBookService = () => {
    const http = useHttp();

    const getAll = useCallback(async () => {
        return await http.get<BookGetAllResModel[]>('/books');
    }, [http]);

    const create = useCallback(async (req: BookCreateReqModel) => {
        return await http.post<BookCreateReqModel>('/books', req);
    }, [http]);

    const getById = useCallback(async (id: number) => {
        return await http.get<{ data: BookModel }>(`/books/${id}`);
    }, [http]);

    const update = useCallback(async (req: BookUpdateReqModel) => {
        return await http.put<BookUpdateReqModel>('/books', req);
    }, [http]);

    const remove = useCallback(async (id: number) => {
        return await http.delete(`/book/${id}`);
    }, [http]);

    const search = useCallback(async (req: BookSearchReqModel) => {
        return await http.post<BookSearchReqModel, PaginationResModel<BookModel>>('books/search', req)
    }, [http]);

    const getByLanguage = useCallback(async (lang: string, page: number) => {
        return await http.get<PaginationResModel<BookLangResModel>>(`/books/list/${lang}/${page}`).then(e => e[0]);
    }, [http])

    const getList = useCallback(async (req: BookListReqModel, lang: string) => {
        return await http.post<BookListReqModel, { data: PaginationResModel<BookLangResModel> }>(`/books/list/${lang}`, req)
    }, [http])

    const getWaitingList = useCallback(async (req: BookListReqModel, lang: string) => {
        return await http.post(`/books/list/${lang}/waiting`, req)
    }, [http])

    const searcFromGoogle = useCallback(async (req: BookSearchGoogleReqModel, lang: string) => {
        return http.post<BookSearchGoogleReqModel, PaginationResModel<BookSearchGoogleResModel>>(`/books/search/${lang}`, req);
    }, [http])

    const add = useCallback(async (req: any) => {
        return await http.post('/books', req);
    }, [http])

    const addInterest = useCallback(async (req: { book_id: number, interest_id: number }) => {
        return await http.post('/book/add-interests', req);
    }, [http])

    const removeInterest = useCallback(async (req: { book_id: number, interest_id: number }) => {
        return await http.delete('/book/remove-interests', req);
    }, [http])

    const getInterests = useCallback(async (id: number) => {
        return await http.get<{ data: BookInterestsList[] }>(`/books/categories/${id}`);
    }, [http])

    const updateCover = useCallback(async (req: BookCoverUpdateReqModel) => {
        return await http.post<BookCoverUpdateReqModel, { data: any }>('/book/image-update', req)
    }, [http])

    return {
        getAll,
        updateCover,
        getInterests,
        add,
        searcFromGoogle,
        getWaitingList,
        getList,
        getByLanguage,
        create,
        getById,
        update,
        remove,
        search,
        addInterest,
        removeInterest
    }
}