import { ConfigProvider, MenuTheme, theme } from "antd";
import { ThemeConfig } from "antd/es/config-provider/context";
import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useBrowserStorage } from "../hooks/browserStorage";
import localeTr from 'antd/locale/tr_TR'
import * as StyledComponents from 'styled-components'
import { AntDesignTheme, StyledComponentsTheme } from "../theme.config";

export enum ThemeModeEnum {
    Dark = 'Dark',
    Light = 'Light',
    Dynamic = 'Dynamic'
}
interface ThemeContextType {
    mode: ThemeModeEnum;
    themeMode: MenuTheme;
    theme: ThemeConfig;
    toggleThemeMode(mode: ThemeModeEnum): void;
}

export const ThemeContext = createContext<ThemeContextType>({
    mode: ThemeModeEnum.Light,
    themeMode: 'light',
    theme: {},
    toggleThemeMode: (mode: ThemeModeEnum) => { },
});

export const ThemeProvider: React.FC = props => {

    const browserStorage = useBrowserStorage();
    const [mode, setMode] = useState<ThemeModeEnum>(ThemeModeEnum.Light);
    const [themeMode, setThemeMode] = useState<MenuTheme>("light");

    const isDay = useMemo(() => {
        const hours = new Date().getHours();
        return (hours >= 6 && hours < 18);
    }, [])

    const toggleThemeMode = useCallback((mode: ThemeModeEnum) => {
        setMode(p => mode);
    }, [setMode])

    useEffect(() => {
        const savedMode = browserStorage.get<ThemeModeEnum>('theme-mode');

        if (savedMode && (
            savedMode === ThemeModeEnum.Dark
            || savedMode === ThemeModeEnum.Light
            || savedMode === ThemeModeEnum.Dynamic
        )) {
            setMode(savedMode);
        } else {
            const isSystemDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
            if (isSystemDark) {
                setMode(ThemeModeEnum.Dark);
            } else {
                setMode(ThemeModeEnum.Light);
            }
        }

    }, [setMode, browserStorage])

    useEffect(() => {
        browserStorage.set('theme-mode', mode);
        switch (mode) {
            case ThemeModeEnum.Dark:
                setThemeMode("dark");
                break;
            case ThemeModeEnum.Light:
                setThemeMode("light");
                break;
            case ThemeModeEnum.Dynamic:
                setThemeMode(isDay ? 'light' : 'dark');
                break;
            default:
                setThemeMode("light");
                break;
        }

    }, [mode, browserStorage, setThemeMode, isDay])


    const themeData: ThemeConfig = useMemo(() => {
        return {
            ...AntDesignTheme,
            algorithm: themeMode === 'dark' ? theme.darkAlgorithm : theme.defaultAlgorithm,
            token: {
                colorPrimary: themeMode === 'dark' ? '#08203e' : '#4A90E2',
            },
            components:{
                Tabs:{
                },
            }
        }
    }, [themeMode]);

    return <ThemeContext.Provider
        value={{
            mode,
            themeMode,
            theme: themeData,
            toggleThemeMode,
        }}
    >
        <ConfigProvider
            theme={themeData}
            locale={localeTr}
            componentSize="middle"
            csp={{
                nonce: 'default-src'
            }}
        >
            <StyledComponents.ThemeProvider theme={{
                mode: mode,
                ...StyledComponentsTheme
            }}>
                <>
                    {props.children}
                </>
            </StyledComponents.ThemeProvider>
        </ConfigProvider>
    </ThemeContext.Provider>
}