import { useCallback, useEffect, useState } from "react";
import { PageCard } from "../../components/pageCard"
import { useUserBookService } from "../../services/userBook/userBook.service"
import { UserBookDeclinedListResModel } from "../../services/userBook/userBook";
import { IColumnProps } from "../../components/table";
import { Button, Image, Tooltip } from "antd";
import { CheckCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { useStore } from "../../store";
import { ITable } from "../../components/table/table";
import { useModal } from "../../hooks/modal";
import { useTranslation } from "react-i18next";

export const SummaryDeclinedList = () => {
    const service = useUserBookService();
    const [state, setState] = useState<UserBookDeclinedListResModel[]>([]);
    const store = useStore();
    const modal = useModal();
    const { t } = useTranslation();
    const getList = useCallback(() => {
        service.declinedList().then(e => {
            if (!e.result) return;
            setState(e.data);
        })
    }, [service, setState])
    useEffect(() => {
        getList();
        store.getAllInterest();
        store.getLanguages();
        // eslint-disable-next-line
    }, [])

    const columns: IColumnProps<UserBookDeclinedListResModel>[] = [
        {
            dataIndex:'ub_id',
            title:'SummaryID',
            textFilter:true
        },
        {
            dataIndex: 'image',
            title: 'Image',
            align: 'center',
            width: 200,
            render: (v) => <Image width={40} height={60} src={v} />
        },
        {
            dataIndex: 'username',
            title: 'Bookfluencer',
            textFilter: true,
            align: 'center'
        },
        {
            dataIndex: 'book_name',
            title: 'BookName',
            textFilter: true,
            render: (v) => <Tooltip title={v}>{String(v).substring(0, 30) + "..."}</Tooltip>
        },
        {
            dataIndex: 'author',
            title: 'Author',
            textFilter: true,
        },
        {
            dataIndex: 'audio_url',
            title: 'Audio',
            render: (v) => <Button onClick={() => store.setSound(v)}><PlayCircleOutlined /></Button>
        },
        {
            dataIndex: 'free_listening_time',
            title: 'FreeListeningTime',
            width: 80,
            sortNumber: true
        },
        {
            dataIndex: 'price',
            title: 'Credit',
            sortNumber: true,
            sortNumberFormater: (v) => parseFloat(String(v).replace(/\$/g, ''))
        },
        {
            dataIndex: 'language',
            title: 'Language',
            filters: store.data.languages.map(e => ({ text:`${e.language.toUpperCase()} - ${e.code.toUpperCase()}`, value: e.code })),
            filterSearch: true,
            filterMode: 'menu',
            onFilter(value, record) {
                return String(record.language.toLowerCase()).indexOf(String(value)) === 0
            }
        },
        {
            dataIndex: 'rejected_description',
            title: 'Reject',
            textFilter: true
        },
        {
            dataIndex: 'created_at',
            title: 'CreatedDate',
            isDate: true,
            sortDate: true
        },
        {
            dataIndex: 'updated_at',
            title: 'UpdatedDate',
            isDate: true,
            sortDate: true
        },
        {
            dataIndex: 'ub_id',
            actionColumn: {
                items: (row) => [
                    {
                        icon: <CheckCircleOutlined />,
                        key: 'approve',
                        label: t('Approve'),
                        onClick: async () => {
                            modal.confirm({
                                content: t('SummaryApprove', { user: row.username, book: row.book_name }),
                                onOk: async () => {
                                    const res = await service.approve(row.ub_id);
                                    if (!res.result) return;
                                    getList()
                                }
                            })
                        }
                    },
                ]
            }
        }
    ]

    return (
        <PageCard>
            <ITable
                columns={columns}
                dataSource={state.reverse()}
            />
        </PageCard>
    )
}