import { useCallback } from "react";
import { useHttp } from "../../hooks/http"
import { CreditSettingsResModel, CreditSettingsUpdateReqModel, PricesGetListResModel, TierListResModel, TierUpdateReqModel } from "./prices";

export const usePriceService = () => {

    const http = useHttp();

    const getAllPrices = useCallback(async () => {
        return await http.get<{ data: PricesGetListResModel[] }>('/prices');
    }, [http])

    const getTierList = useCallback(async()=>{
        return await http.get<{data:TierListResModel[]}>('/tier-lists')
    },[http])

    const updateTier = useCallback(async(req:TierUpdateReqModel)=>{
        return await http.post<TierUpdateReqModel>('/tier-lists/update',req)
    },[http])

    const creditSettings = useCallback(async()=>{
        return await http.get<{data:CreditSettingsResModel}>('/credit-settings')
    },[http])

    const updateCreditSettings = useCallback(async(req:CreditSettingsUpdateReqModel)=>{
        return await http.post<CreditSettingsUpdateReqModel>('/credit-settings/update',req);
    },[http])

    return {
        getAllPrices,
        getTierList,
        updateTier,
        creditSettings,
        updateCreditSettings
    }
}