import { Button, Modal } from "antd";
import { ModalData } from "../../models/system";
import { useUserBookService } from "../../services/userBook/userBook.service";
import { useCallback, useEffect, useState } from "react";
import { Userbookdetail } from "../../services/userBook/userBook";
import { IColumnProps } from "../../components/table";
import { ITable } from "../../components/table/table";
import { useTranslation } from "react-i18next";
import { useStore } from "../../store";
import { PlayCircleOutlined } from "@ant-design/icons";

interface SummaryStatsModalProps {
    data: ModalData<number>;
    onDone(): void;
    onCancel(): void;
}

export const SummaryStatsModal: React.FC<SummaryStatsModalProps> = props => {

    const service = useUserBookService();
    const [state, setState] = useState<Userbookdetail[]>([]);
    const store = useStore()
    const { t } = useTranslation();
    const reset = useCallback(() => {
        setState([])
    }, [setState])

    const cancel = useCallback(() => {
        reset();
        props.onCancel()
    }, [props, reset])

    const done = useCallback(() => {
        reset();
        props.onDone()
    }, [props, reset])

    const get = useCallback(async (id: number) => {
        const res = await service.getDetail(id);
        if (!res.result) return;
        setState(res.data)
    }, [setState, service])

    useEffect(() => {
        if (!props.data.data || !props.data.open) return;
        get(props.data.data);
        // eslint-disable-next-line
    }, [props.data])

    const columns: IColumnProps<Userbookdetail>[] = [
        {
            dataIndex: 'user_book',
            title: 'Bookfluencer',
            render: (v, r) => `${r.user_book.user.name} ${r.user_book.user.surname}`
        },
        {
            dataIndex: 'user_book',
            title: 'Audio',
            render: (v,r) => <Button onClick={() => store.setSound(r.user_book.audio_url)}><PlayCircleOutlined /></Button>

        },
        {
            dataIndex:['user_book','duration'],
            title:'Duration',
            isDuration:true
        },
        {
            dataIndex: 'user_book',

            title: 'totalEarning',
            render: (v, r) => r.user_book.statistics.total_earned
        },
        {

            dataIndex: 'user_book',
            render: (v, r) => r.user_book.statistics.total_listenings,
            title: 'totalListenings'
        },
        {
            dataIndex: 'user_book',
            title: 'totalSales',
            render: (v, r) => r.user_book.statistics.total_sales
        },
    ]



    return (
        <Modal
            open={props.data.open}
            onCancel={() => cancel()}
            onOk={() => done()}
            width={"80vw"}
            title={t('SummaryDetail')}
            cancelButtonProps={{ hidden: true }}
        >
            <ITable
                columns={columns}
                dataSource={state}
            />
        </Modal>
    )
}