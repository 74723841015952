import { Form, Modal, Row } from "antd";
import { ComponentTypes, ModalData } from "../../models/system";
import { useForm } from "antd/lib/form/Form";
import { useCallback, useEffect } from "react";
import { useCreateFormItems } from "../../helpers/form";
import { UserBookGetAllResModel, UserBookUpdateModel } from "../../services/userBook/userBook";
import { StyledNumberInput } from "../../components/form";
import { useUserBookService } from "../../services/userBook/userBook.service";
import { useTranslation } from "react-i18next";
import { useStore } from "../../store";

interface SummaryEditModalProps {
    data: ModalData<UserBookGetAllResModel>;
    onDone(): void;
    onCancel(): void;
}

export const SummaryEditModal: React.FC<SummaryEditModalProps> = props => {

    const [form] = useForm();
    const service = useUserBookService();
    const { t } = useTranslation();
    const store = useStore();


    const onReset = useCallback(() => {
        form.resetFields();
    }, [form])

    const onCancel = useCallback(() => {
        onReset();
        props.onCancel()
    }, [onReset, props])

    const onDone = useCallback(() => {
        onReset();
        props.onDone()
    }, [onReset, props])

    useEffect(() => {
        if (!props.data.data) return;
        form.setFieldsValue(props.data.data)
        store.getAllTiers();
        // eslint-disable-next-line
    }, [props.data])

    const formItems = useCreateFormItems<UserBookUpdateModel>([
        {
            formItem: {
              label: "Price",
              name: "price",
              required: true,
              extra:t('PriceNotification')
            },
            colProps: {
              span: 24,
            },
            element:<StyledNumberInput />,
            elementType: ComponentTypes.Input,
          },
        {
            colProps: {
                span: 24
            },
            formItem: {
                name: 'free_listening_time',
                label: 'FreeListeningTime'
            },
            element: <StyledNumberInput />,
            elementType: ComponentTypes.Input
        },
    ])

    return (
        <Modal
            title={t('UpdateSummary')}
            open={props.data.open}
            onCancel={() => onCancel()}
            okButtonProps={{
                form: 'summaryUpdateForm',
                htmlType: 'submit'
            }}
        >
            <Form id="summaryUpdateForm" form={form} onFinish={async (v) => {
                if (!props.data.data) return;
                const res = await service.updateUserBook({ ...v, ub_id: props.data.data.ub_id })
                if (!res.result) return;
                onDone();
            }}>
                <Row gutter={24}>
                    {formItems}
                </Row>
            </Form>
        </Modal>
    )
}