import React, { useCallback, useState } from 'react';
import { ModalData } from '../../models/system';
import { useBookService } from '../../services/book/book.service';
import { Alert, Form, GetProp, Modal, UploadProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadFile } from 'antd/lib';
import { InboxOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';
import { useForm } from 'antd/lib/form/Form';

interface BookImageUpdateModalProps {
    data: ModalData<number>;
    onDone(): void;
    onCancel(): void;
}

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

export const BookImageUpdateModal: React.FC<BookImageUpdateModalProps> = props => {
    const service = useBookService();
    const { t } = useTranslation();
    const [form] = useForm();
    const [fileList, setFileList] = useState<UploadFile | null>(null);
    const [preview, setPreview] = useState<any>(null);

    const onPreview = useCallback(async (file: UploadFile) => {
        setFileList(file);
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj as FileType);
        reader.onload = () => setPreview(reader.result);
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as FileType);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image(240, 370);
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    }, [setFileList, setPreview]);

    const uploadContent = useCallback(() => {

        if (preview) {
            return <img alt="" style={{ width: '100%' }} src={String(preview)} />;
        }
        return (
            <>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">{t('DragDropUploadTitle')}</p>
            </>
        );



        // return (<>{props.value.name}</>)
    }, [t, preview])

    const onReset = useCallback(() => {
        form.resetFields();
        setFileList(null);
        setPreview(null);
    }, [form, setFileList, setPreview])

    const onCancel = useCallback(() => {
        onReset();
        props.onCancel()
    }, [onReset, props])

    const onSend = useCallback(async () => {
        if (!props.data.data) return;
        if (!fileList) return;
        const formData = new FormData();

        formData.append('book_id', props.data.data.toString())
        formData.append('image_file', fileList.originFileObj as any);

        const res = await service.updateCover(formData as any);
        if (!res.result) return;
        onReset();
        props.onDone()
    }, [service, props, fileList, onReset])

    return (
        <>
            <Modal
                open={props.data.open}
                title={t('BookUpdateCover')}
                onOk={() => onSend()}
                onCancel={() => onCancel()}
            >
                <Alert type='info' message={t('BookUpdateCoverMessage')} />
                <Form id="bookCoverForm" form={form}>
                    <Form.Item wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} label={t('BookPicture')}>
                        <Dragger
                            multiple={false}
                            accept=".jpg"
                            style={{ width: '100%' }}
                            onChange={(v) => onPreview(v.file)}
                            showUploadList={false}
                        >
                            {uploadContent()}
                        </Dragger>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}