import React, { useCallback, useEffect } from 'react';
import { ComponentTypes, ModalData } from '../../models/system';
import { useForm } from 'antd/lib/form/Form';
import { usePriceService } from '../../services/prices/prices.service';
import { Form, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCreateFormItems } from '../../helpers/form';
import { TierListResModel, TierUpdateReqModel } from '../../services/prices/prices';
import { StyledNumberInput } from '../../components/form';

interface TierModalProps {
    data: ModalData<TierListResModel>;
    onDone(): void
    onCancel(): void;
}

export const TierModal: React.FC<TierModalProps> = props => {
    const [form] = useForm();
    const service = usePriceService();
    const { t } = useTranslation()

    useEffect(() => {
        if (props.data.data) {
            form.setFieldsValue({
                price: props.data.data.price,
                credit: props.data.data.credit,
            })
        }
        // eslint-disable-next-line 
    }, [props.data.data])

    const onReset = useCallback(async () => {
        form.resetFields();
    }, [form])

    const onDone = useCallback(async () => {
        onReset()
        props.onDone();
    }, [onReset, props])

    const onCancel = useCallback(async () => {
        onReset()
        props.onCancel()
    }, [onReset, props])


    const onSubmit = useCallback(async (val: TierUpdateReqModel) => {
        if (!props.data.data) return;

        const res = await service.updateTier({
            ...val,
            tier_id: props.data.data.tier_id
        });

        if (!res.result) return;
        onDone()
    }, [service, onDone, props])

    const formItems = useCreateFormItems<TierUpdateReqModel>([
        {
            formItem: {
                label: 'Price',
                name: 'price',
                required: true
            },
            colProps: {
                span: 24
            },
            element: <StyledNumberInput />,
            elementType: ComponentTypes.Input
        },
        {
            formItem: {
                label: 'Credit',
                name: 'credit'
            },
            colProps: {
                span: 24
            },
            element: <StyledNumberInput />,
            elementType: ComponentTypes.Input
        },
    ])

    return (
        <>
            <Modal
                open={props.data.open}
                title={t('Update')}
                okButtonProps={{
                    htmlType: 'submit',
                    form: 'updateTier'
                }}
                onCancel={() => onCancel()}
            >
                <Form id="updateTier" onFinish={(v) => onSubmit(v)} form={form}>
                    <Row gutter={24}>
                        {formItems}
                    </Row>
                </Form>
            </Modal>
        </>
    )
}