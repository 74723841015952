import { useCallback } from "react";
import { useHttp } from "../../hooks/http"
import { AdminCreateReqModel, AdminGetAllResModel, AdminUpdatePasswordReqModel } from "./admin";

export const useAdminService = () => {
    const http = useHttp();

    const getAllAdminUsers = useCallback(async () => {
        return await http.get<{ data: AdminGetAllResModel[] }>('/admins/all')
    }, [http])

    const updatePassword = useCallback(async (req: AdminUpdatePasswordReqModel) => {
        return await http.post('/admin/change-password', req);
    }, [http])

    const create = useCallback(async (req: AdminCreateReqModel) => {
        return await http.post('/admin/create', req);
    }, [http])

    const activate = useCallback(async (username: string) => {
        return await http.post('/admin/activate', { username })
    }, [http])

    const deactivate = useCallback(async (username: string) => {
        return await http.post('/admin/deactivate', { username })
    }, [http])

    return {
        getAllAdminUsers,
        updatePassword,
        create,
        deactivate,
        activate
    }
}