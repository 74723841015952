import { useCallback, useEffect, useState } from "react";
import { PageCard } from "../../components/pageCard"
import { useWithdrawalService } from "../../services/withdrawal/withdrawal.service"
import { WithdrawalWaitingListResModel } from "../../services/withdrawal/withdrawal";
import { IColumnProps } from "../../components/table";
import { Button, Dropdown, Space } from "antd";
import { useTranslation } from "react-i18next";
import { DownOutlined } from "@ant-design/icons";
import { useModal } from "../../hooks/modal";
import { WithdrawDeclineModal } from "./withdrawDeclineModal";
import { ModalData, ModalDataModel } from "../../models/system";
import { ITable } from "../../components/table/table";

export const WithdrawManager: React.FC = props => {
    const service = useWithdrawalService();

    const [state, setState] = useState<WithdrawalWaitingListResModel[]>([]);
    const { t } = useTranslation();
    const modal = useModal();
    const [modalData, setModalData] = useState<ModalData<number>>(ModalDataModel());

    const getList = useCallback(async () => {
        const res = await service.getWaitingList();
        if (!res.result) return;
        setState(res.data);
        console.log(res.data.length)
    }, [service, setState])

    useEffect(() => {
        getList();
        // eslint-disable-next-line
    }, [])

    const columns: IColumnProps<WithdrawalWaitingListResModel>[] = [
        {
            dataIndex: 'name',
            title: 'Name'
        },
        {
            dataIndex: 'surname',
            title: 'Surname'
        },
        {
            dataIndex: 'amount',
            title: 'Amount'
        },
        {
            dataIndex: 'earned_wallet',
            title: 'EarnedWallet',
            render:(v)=> Number(v).toFixed(2)
        },
        {
            dataIndex: 'created_at',
            title: 'CreatedDate',
            isDate: true
        },
        {
            title: 'Action',
            dataIndex: 'id',
            render: (v, r) => <Dropdown menu={{
                items: [
                    {
                        label: t('Approve'),
                        key: 'approve',
                        onClick: () => modal.confirm({
                            content: t('WithdrawApproveConfirm', { value: r.amount }),
                            onOk: async () => {
                                const res = await service.approve({ request_id: r.request_id })
                                if (!res.result) return;
                                getList();
                            }
                        })
                    },
                    {
                        label: t('Decline'),
                        key: 'decline',
                        onClick: () => modal.confirm({
                            content: t('WithdrawDeclineConfirm', { value: r.amount }),
                            onOk: async () => {
                                setModalData({ data: r.request_id, open: true })
                            }
                        })
                    }
                ]
            }}>
                <Button>
                    <Space>
                        {t('Action')}
                        <DownOutlined />
                    </Space>
                </Button>
            </Dropdown>
        }
    ]


    return (
        <>
            <PageCard>
                <ITable
                    rowKey={"request_id"}
                    dataSource={state}
                    columns={columns}
                />
            </PageCard>
            <WithdrawDeclineModal
                data={modalData}
                onCancel={() => setModalData(ModalDataModel())}
                onDone={() => {
                    setModalData(ModalDataModel())
                    getList()
                }}
            />
        </>
    )
}