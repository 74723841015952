import { useCallback, useEffect, useState } from "react";
import { PageCard } from "../../components/pageCard"
import { useCommissionService } from "../../services/commission/commission.service"
import { CommissionGetListModel } from "../../services/commission/commission";
import { IColumnProps } from "../../components/table";
import { ITable } from "../../components/table/table";
import { ModalData, ModalDataModel } from "../../models/system";
import { CommissionsModal } from "./commissionsModal";
import { Card } from "antd";
import { useTranslation } from "react-i18next";
import { useHttp } from "../../hooks/http";
import { StyledNumberInput } from "../../components/form";
import { NewButton } from "../../components/buttons";

interface BC {
    "bc_id": number,
    "rate": number,
    "updated_at": string;
    "created_at": string;
}

export const Commissions = () => {
    const service = useCommissionService();
    const [state, setState] = useState<CommissionGetListModel[]>([])
    const [modalData, setModalData] = useState<ModalData<CommissionGetListModel>>(ModalDataModel());
    const { t } = useTranslation();
    const [c, setC] = useState<BC>({
        bc_id: 0,
        created_at: '',
        rate: 0,
        updated_at: ''
    })

    const http = useHttp();

    const getList = useCallback(async () => {
        const res = await service.getList();
        if (res.result) {
            setState(res.data);
        }

        const bc = await http.get<{
            data: BC
        }>('book-comission/get')

        if (bc.result) {
            setC(bc.data);
        }

    }, [setState, service, http])


    useEffect(() => {
        getList();
        // eslint-disable-next-line
    }, [])

    const columns: IColumnProps<CommissionGetListModel>[] = [
        {
            dataIndex: 'amount',
            title: 'Amount'
        },
        {
            dataIndex: 'rate',
            title: 'Rate'
        },
        {
            dataIndex: 'created_at',
            title: 'CreatedDate',
            isDate: true
        },
        {
            dataIndex: 'updated_at',
            title: 'UpdatedDate',
            isDate: true
        },
        {
            dataIndex: 'comission_id',
            actionColumn: {
                hasUpdate: {
                    callback: (data, r) => setModalData({ data: r, open: true }),
                    propName: 'comission_id'
                },
                hasDelete: {
                    callback: async (data) => {
                        const res = await service.remove(data);
                        if (!res.result) return;
                        getList()
                    },
                    propName: 'comission_id'
                },

            }
        }
    ];


    const bccol: IColumnProps<BC>[] = [
        {
            dataIndex: 'rate',
            title: 'Rate',
            editable: {
                element: <StyledNumberInput />,
            },
        },
        {
            dataIndex: 'created_at',
            title: 'CreatedDate',
            isDate: true
        },
        {
            dataIndex: 'updated_at',
            title: 'UpdatedDate',
            isDate: true
        },
    ]



    return (
        <PageCard>
            <Card style={{ marginBottom: 20 }} title={t('SellCommission')}>
                <ITable
                    columns={bccol}
                    dataSource={[
                        c
                    ]}
                    onSaveRow={async (d) => {
                        const res = await http.post('book-comission/update', d);
                        if (!res.result) return;
                        getList()
                    }}
                />
            </Card>
            <Card
                title={t('WithdrawCommission')}
                extra={<NewButton onClick={() => setModalData({ data: null, open: true })} />}
            >
                <ITable
                    columns={columns}
                    dataSource={state}
                />
            </Card>

            <CommissionsModal
                data={modalData}
                onCancel={() => setModalData(ModalDataModel())}
                onDone={() => {
                    setModalData(ModalDataModel())
                    getList()
                }}
            />
        </PageCard>
    )
}