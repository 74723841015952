import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageCard } from '../../components/pageCard';
import { useUserBookService } from '../../services/userBook/userBook.service';
import { UserBookGetAllResModel } from '../../services/userBook/userBook';
import dayjs from 'dayjs';
import { IColumnProps } from '../../components/table';
import { Button, Image, Tag, Tooltip } from 'antd';
import { CheckCircleFilled, CloseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { ITable } from '../../components/table/table';
import { useStore } from '../../store';
import { NewSummaryModal } from './newSummaryModal';
import { ModalData, ModalDataModel } from '../../models/system';
import { SummaryEditModal } from './summaryEditModal';
import { useTranslation } from 'react-i18next';
import { SummaryDeclineModal } from './summaryDeclineModal';

export const Summary: React.FC = props => {
    const service = useUserBookService();
    const [state, setState] = useState<UserBookGetAllResModel[]>([]);
    const store = useStore();
    const [newModal, toggleNewModal] = useState(false);
    const [editModal, setEditModal] = useState<ModalData<UserBookGetAllResModel>>(ModalDataModel());
    const { t } = useTranslation();
    const [declineModal, setDeclineModal] = useState<ModalData<number>>(ModalDataModel());

    const getList = useCallback(async () => {
        const res = await service.getAll();
        if (!res.result) return;
        setState(res.data);
    }, [service, setState])

    useEffect(() => {
        getList();
        store.getAllInterest();
        store.getLanguages();
        // eslint-disable-next-line
    }, [])

    const catList = useMemo(() => {
        const list = [{ text: t('UnCategorized'), value: -1 }] 
        store.data.categories.forEach(e => list.push({ text: e.interest_key, value: e.interest_id }))
        return list;
    }, [store, t])

    const cols: IColumnProps<UserBookGetAllResModel>[] = [
        {
            dataIndex: 'ub_id',
            title: 'SummaryID',
            textFilter:true
        },
        {
            dataIndex: 'image',
            title: 'Image',
            align: 'center',
            width: 200,
            render: (v) => <Image width={40} height={60} src={v} />
        },
        {
            dataIndex: 'username',
            title: 'Bookfluencer',
            align: 'center',
            textFilter: true
        },
        {
            dataIndex: 'book_name',
            title: 'BookName',
            textFilter: true,
            render: (v) => <Tooltip title={v}>{String(v).substring(0, 30) + "..."}</Tooltip>
        },
        {
            dataIndex: 'author',
            title: 'Author',
            textFilter: true,
        },
        {
            dataIndex: 'interests',
            title: 'Categories',
            filters: catList,
            filterSearch: true,
            filterMode: 'menu',
            render: (v, r) => r.interests.map(e => <Tag children={e.value} />),
            onFilter(value, record) {
                const exist = record.interests.find(e => e.interest_id === Number(value))
                return exist ? true : false;
            },
        },
        {
            dataIndex: 'audio_url',
            title: 'Audio',
            render: (v) => <Button onClick={() => store.setSound(v)}><PlayCircleOutlined /></Button>
        },
        // {
        //     title: 'Description',
        //     width: 200,
        //     dataIndex: 'description',
        //     render: (v) => {
        //         const desc = String(v);
        //         if (desc.length < 50) return desc;
        //         return <Tooltip placement="left" title={v}>{String(v).substring(0, 50) + "..."}</Tooltip>
        //     }
        // },
        {
            dataIndex: 'free_listening_time',
            title: 'FreeListeningTime',
            width: 80,
        },
        {
            dataIndex: 'price',
            title: 'Credit',
            isTier: true,
            sortNumber: true,
            sortNumberFormater: (v) => parseFloat(String(v).replace(/\$/g, ''))
        },
        {
            dataIndex: 'language',
            title: 'Language',
            filters: store.data.languages.map(e => ({ text:`${e.language.toUpperCase()} - ${e.code.toUpperCase()}`, value: e.code })),
            filterSearch: true,
            filterMode: 'menu',
            onFilter(value, record) {
                return String(record.language.toLowerCase()).indexOf(String(value)) === 0
            },
        },
        {
            dataIndex: 'created_at',
            title: 'CreatedDate',
            isDate: true,
            sorter: (a, b) => dayjs(a.created_at, 'YYYY-MM-DD').diff(dayjs(b.created_at, 'YYYY-MM-DD')),
        },
        {
            dataIndex: 'updated_at',
            title: 'UpdatedDate',
            isDate: true,
            sortDate: true
        },
        {
            dataIndex: 'status',
            title: 'Status',
            render: (v) => t(v === 1 ? 'Active' : 'DeActive'),
            sortNumber: true
        },
        {
            dataIndex: 'ub_id',
            actionColumn: {
                hasDelete: {
                    callback: async (data) => {
                        const res = await service.removeSummary(data);
                        if (!res.result) return;
                        getList();
                    },
                    propName: 'ub_id'
                },
                hasUpdate: {
                    callback: (data, r) => setEditModal({ data: r, open: true }),
                    propName: 'ub_id'
                },
                items(row) {
                    return [
                        row.status === 1 ? {
                            icon: <CloseCircleOutlined />,
                            key: 'UnPublish',
                            label: t('UnPublish'),
                            onClick: async () => {
                                setDeclineModal({ data: row.ub_id, open: true })
                            }
                        } :
                            {
                                icon: <CheckCircleFilled />,
                                key: 'Publish',
                                label: t('Publish'),
                                onClick: async () => {
                                    await service.approve(row.ub_id);
                                    getList()
                                }
                            },
                        // {
                        //     key: 'unpublish',
                        //     label: t('UnPublish'),
                        //     onClick: () => modal.confirm({
                        //         content: t('ApproveSummaryUnPublish'),
                        //         onOk: async () => {
                        //             const res = await service.unPublish(row.ub_id);
                        //             if (!res.result) return;
                        //             getList();
                        //         }
                        //     })
                        // }
                    ]
                },
            }
        }
    ];

    return (
        <>
            <PageCard
                onClickNew={() => toggleNewModal(true)}
            >
                <ITable
                    dataSource={state.reverse()}
                    columns={cols}
                    rowKey={"ub_id"}
                />
            </PageCard>

            <NewSummaryModal
                onCancel={() => toggleNewModal(false)}
                onDone={() => {
                    toggleNewModal(false);
                    getList()
                }}
                open={newModal}
            />

            <SummaryEditModal
                data={editModal}
                onCancel={() => setEditModal(ModalDataModel())}
                onDone={() => {
                    setEditModal(ModalDataModel())
                    getList()
                }}
            />

            <SummaryDeclineModal
                data={declineModal}
                isAdmin
                onCancel={() => setDeclineModal(ModalDataModel())}
                onDone={() => {
                    setDeclineModal(ModalDataModel())
                    getList()
                }}
            />

        </>
    )
}