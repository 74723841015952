import { Card, Col, Modal, Row, Statistic, Tabs } from "antd";
import { ModalData } from "../../models/system";
import { useTransactionsService } from "../../services/transactions/transactions.service";
import { useCallback, useEffect, useState } from "react";
import { SalesGetListResModel } from "../../services/sales/sales";
import { useTranslation } from "react-i18next";
import { TransactionTable } from "../../components/transactionTable";
import { useHttp } from "../../hooks/http";
import { useUserService } from "../../services/user/user.service";
import { UserEarnModel, UserGetByIdResModel } from "../../services/user/user";
import { ReportBooks } from "../reporteds/bookReport";
import { ReportedUsers } from "../reporteds/userReported";
import { IColumnProps } from "../../components/table";
import { ITable } from "../../components/table/table";


interface UserTransactionsModalProps {
    modalData: ModalData<number>;
    onDone(): void;
}

export interface FollowModel {
    id: number
    name: string
    surname: string
    username: string
    countryCode: any
    profile_image: any
    display_language: string
    wallet: number
    about_me: any
    donate_tier: number
    login_type: number
    isPremium: string
    isBanned: string
    created_at: string
    total_user_books: number
    star_average: number
}


interface DetailModal extends UserGetByIdResModel, UserEarnModel {

}

export const UserTransactionsModal: React.FC<UserTransactionsModalProps> = props => {
    const service = useTransactionsService();
    const [state, setState] = useState<SalesGetListResModel[]>([])
    const [earnstate, setEarnState] = useState<SalesGetListResModel[]>([])
    const http = useHttp();
    const [detail, setDetail] = useState<DetailModal | null>(null);
    const userService = useUserService();
    const [reportedBookList, setreportedBookList] = useState<any[]>([]);
    const [reportedUserList, setreportedUserList] = useState<any[]>([]);
    const [followers, setFollowers] = useState<FollowModel[]>([])
    const [followings, setFollowing] = useState<FollowModel[]>([])
    const { t } = useTranslation();
    const [activeKey,setActiveKey] = useState("buys")

    const reset = useCallback(() => {
        setState([])
        setEarnState([])
        setDetail(null)
        setreportedUserList([])
        setreportedBookList([])
        setFollowers([])
        setFollowing([])
        setActiveKey("buys")
    }, [setState, setEarnState, setreportedUserList, setreportedBookList, setDetail,
        setFollowers,
        setActiveKey,
        setFollowing
    ])

    const followCol: IColumnProps<FollowModel>[] = [
        {
            dataIndex: 'name',
            title: 'Name'
        },
        {
            dataIndex: 'surname',
            title: 'Surname'
        },
        {
            dataIndex: 'username',
            title: 'Username'
        },
        {
            dataIndex: 'countryCode',
            title: 'Country'
        },
        {
            dataIndex: 'wallet',
            title: 'Wallet'
        },
        {
            dataIndex: 'isBanned',
            title: 'isBanned',
            isYesOrNo: true
        },
        {
            dataIndex: 'isPremium',
            title: 'Premium',
            isYesOrNo: true
        },
        {
            dataIndex: 'total_user_books',
            title: 'BookSize'
        },
        {
            dataIndex: 'star_average',
            title: 'StarRate',
            isRate: true
        }
    ]

    const done = useCallback(() => {
        reset();
        props.onDone()
    }, [reset, props])

    const getList = useCallback(async () => {
        if (!props.modalData.data) return;
        const res = await service.getTransactionsByUserId(props.modalData.data);
        if (!res.result) return;
        setState(res.transactions);
    }, [service, setState, props.modalData])


    const getEarningList = useCallback(async () => {
        if (!props.modalData.data) return;
        const res = await service.getEarningsByUserId(props.modalData.data);
        if (!res.result) return;
        setEarnState(res.transactions);
    }, [service, setEarnState, props.modalData])

    const getDetail = useCallback(async (id: number) => {
        const detail = await userService.getById(id);
        const earnings = await userService.earnings(id);

        setDetail({
            ...detail.data,
            ...earnings.data,
        })

    }, [userService])


    useEffect(() => {
        if (!props.modalData.open) return;
        if (!props.modalData.data) return;
        getList();
        getDetail(props.modalData.data)
        // eslint-disable-next-line
    }, [props.modalData])

    return (
        <Modal
            open={props.modalData.open}
            cancelButtonProps={{
                hidden: true
            }}
            onOk={() => done()}
            onCancel={() => {
                reset()
                props.onDone()
            }}
            title={t('UserDetail')}
            width={'80vw'}
        >
            <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
                {
                    detail !== null && Object.keys(detail).filter(e => [
                        'today_earning',
                        'total_earning',
                        'current_earning',
                        'totalBookReport',
                        'week_earning',
                        'month_earning',
                        'follower',
                        'following',
                        // 'earned_wallet',
                        'bookListening',
                        'bookCount',
                        'name',
                        'surname',
                        'username'
                    ].includes(e))
                        .map(key => {
                            return <Col span={6}>
                                <Card>
                                    <Statistic title={t(key)} value={(detail as any)[key]} />
                                </Card>
                            </Col>
                        })
                }
            </Row>
            <Tabs
                defaultActiveKey={activeKey}
                type="card"
                size={"middle"}
                activeKey={activeKey}
                key={activeKey}
                onChange={async (key) => {
                    setActiveKey(key)
                    if (key === 'earn') {
                        getEarningList();
                    }

                    if (key === 'reportedBooks') {
                        const bookReports = await http.get<{ data: any[] }>(`/book-reports/${props.modalData.data}`);

                        if (bookReports.result) {
                            setreportedBookList(bookReports.data);
                        }
                    }

                    if (key === 'reportedChat') {

                        const chatReports = await http.get<{ data: any[] }>(`/chat-reports/${props.modalData.data}`);

                        if (chatReports.result) {
                            setreportedUserList(chatReports.data);
                        }
                    }

                    if (key === 'followers') {
                        const followers = await http.get<{ data: FollowModel[] }>(`/user/${props.modalData.data}/followlist`)
                        if (!followers.result) return;
                        setFollowers(followers.data)
                    }
                    
                    if (key === 'following') {
                        const followinglist = await http.get<{ data: FollowModel[] }>(`/user/${props.modalData.data}/followinglist`)
                        if (!followinglist.result) return;
                        setFollowing(followinglist.data)
                    }

                    
                }}
                items={[
                    {
                        key: 'buys',
                        label: t('Buyings'),
                        children: <TransactionTable data={state} />
                    },
                    {
                        key: 'earn',
                        label: t('Earnings'),
                        children: <TransactionTable data={earnstate} />
                    },
                    {
                        key: 'reportedBooks',
                        label: t('ReportedBooks'),
                        children: <ReportBooks data={reportedBookList} />
                    },
                    {
                        key: 'reportedChat',
                        label: t('ReportedUsers'),
                        children: <ReportedUsers data={reportedUserList} />
                    },
                    {
                        key: 'followers',
                        label: t('Followers'),
                        children: <ITable columns={followCol} dataSource={followers} />
                    },
                    {
                        key: 'following',
                        label: t('Following'),
                        children: <ITable columns={followCol} dataSource={followings} />
                    }
                ]}
            />
        </Modal>
    )
}