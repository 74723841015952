import { useCallback, useEffect, useState } from "react";
import { PageCard } from "../../components/pageCard"
import { useBannerService } from "../../services/banner/banner.service"
import { BannerGetByLangResModel, BannerType } from "../../services/banner/banner";
import { Form, Image } from "antd";
import { useStore } from "../../store";
import { useTranslation } from "react-i18next";
import { StyledSelect } from "../../components/form";
import { ModalData, ModalDataModel, YesOrNoEnum } from "../../models/system";
import { ITable } from "../../components/table/table";
import { IColumnProps } from "../../components/table";
import { BannerModal } from "./bannerModal";

export const Banner: React.FC = props => {
    const service = useBannerService();
    const store = useStore();
    const [state, setState] = useState<BannerGetByLangResModel[]>([]);
    const { t } = useTranslation();
    const [modalData, setModalData] = useState<ModalData<BannerGetByLangResModel>>(ModalDataModel());
    const [lang, setLang] = useState("tr");

    const getList = useCallback(async () => {
        if (!lang.length) return;
        const res = await service.getListByLanguage(lang);
        if (!res.result) return;
        setState(res.data);
    }, [lang, service])

    useEffect(() => {
        store.getLanguages()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [lang])

    const columns: IColumnProps<BannerGetByLangResModel>[] = [
        {
            dataIndex: 'image_url',
            title: 'Image',
            render: (v) => <Image src={v} width={100} height={100} />
        },
        {
            dataIndex: 'banner_type',
            title: 'BannerType',
            render: (v) => {
                switch (v) {
                    case BannerType.BottomBanner:
                        return t('BottomBanner')
                    case BannerType.TopBanner:
                        return t('TopBanner')
                    default:
                        return ""
                }
            }
        },

        {
            dataIndex: 'language',
            title: 'Language',
            isTag: true
        },
        {
            dataIndex: 'priority',
            title: 'Priority'
        },
        {
            dataIndex: 'start_date',
            title: 'StartDate',
            isDate: true
        },
        {
            dataIndex: 'end_date',
            title: 'EndDate',
            isDate: true
        },
        {
            dataIndex: 'isActive',
            title: 'Status',
            isYesOrNo: true
        },
        {
            dataIndex: 'banner_id',
            actionColumn: {
                hasUpdate: {
                    propName: 'banner_id',
                    callback: (data, r) => setModalData({ data: r, open: true })
                },
                hasDelete: {
                    callback: async (data) => {
                        const res = await service.remove(data);
                        if (!res.result) return;
                        getList()
                    },
                    propName: 'banner_id'
                },
                items: (row) => [
                    {
                        key: 'active',
                        label: 'Active',
                        disabled: row.isActive === YesOrNoEnum.Yes,
                        onClick: async () => {
                            const res = await service.active(row.banner_id);
                            if (!res.result) return;
                            getList()
                        }
                    },
                    {
                        key: 'dactive',
                        label: 'DeActive',
                        disabled: row.isActive === YesOrNoEnum.No,
                        onClick: async () => {
                            const res = await service.deActive(row.banner_id);
                            if (!res.result) return;
                            getList()
                        }
                    },
                ]
            }
        }
    ]

    return (
        <>
            <PageCard
                onClickNew={() => setModalData({ data: null, open: true })}
                extra={<Form.Item style={{ margin: 0 }} label={t('SelectLanguage')}>
                    <StyledSelect
                        style={{ width: '200px' }}
                        options={store.data.languages.map(e => ({ label:`${e.language.toUpperCase()} - ${e.code.toUpperCase()}`, value: e.code }))}
                        value={lang}
                        onSelect={(v) => setLang(String(v))}
                    />
                </Form.Item>}
            >
                {/* <Form id="search" onFinish={(v: any) => { setLang(String(v.lang)) }}>
                    <Row gutter={14}>
                        {useCreateFormItems([
                            {
                                colProps: { span: 4 },
                                element: <StyledSelect options={store.data.languages.map(e => ({ label:`${e.language.toUpperCase()} - ${e.code.toUpperCase()}`, value: e.code }))} />,
                                elementType: ComponentTypes.Input,
                                formItem: {
                                    label: 'Language',
                                    name: 'lang',
                                    initialValue: 'tr'
                                },

                            }
                        ])}
                    </Row>
                </Form>
                <Button htmlType="submit" form="search">{t('List')}</Button> */}
                <ITable
                    dataSource={state}
                    columns={columns}
                />
            </PageCard>
            <BannerModal
                modalData={modalData}
                onCancel={() => setModalData(ModalDataModel())}
                onDone={() => {
                    setModalData(ModalDataModel())
                    getList()
                }}
            />
        </>
    )
}