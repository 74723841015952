export type HttpResponseModel<T> = T & {
    result: boolean;
    message?: string;
}

export interface PaginationResModel<T = {}> {
    [key: string]: any;
    search_results: T[];
    hasNextPage: number;
    totalCount: number;
    currentPage: number;
    totalPages: number;
}

export const PaginationResDto = (): PaginationResModel<any> => {
    return {
        currentPage: 1,
        hasNextPage: 1,
        search_results: [],
        totalCount: 1,
        totalPages: 0
    }
}