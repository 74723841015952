import { Button, Card, Col, Form, Modal, Row } from "antd";
import { useStore } from "../../store";
import { useBookService } from "../../services/book/book.service";
import { useUserBookService } from "../../services/userBook/userBook.service";
import { useCallback, useEffect, useState } from "react";
import { useCreateFormItems } from "../../helpers/form";
import { UserBookCreateReqModel } from "../../services/userBook/userBook";
import {
  StyledInput,
  StyledNumberInput,
  StyledSelect,
} from "../../components/form";
import { ComponentTypes } from "../../models/system";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../hooks/notification";
import { useForm } from "antd/es/form/Form";
import { SoundUpload } from "../../components/soundUpload";
import { GoogleTextToSpeechModal } from "../../components/GoogleTextToSpeechModal";
import { CheckCircleOutlined, GoogleCircleFilled } from "@ant-design/icons";
import { useUserService } from "../../services/user/user.service";
import { UserGetListResModel } from "../../services/user/user";
import { useHttp } from "../../hooks/http";
import { ITable } from "../../components/table/table";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase-config";
import { useLoading } from "../../hooks/loading";

export const NewSummaryModal: React.FC<{
  open: boolean;
  onDone(): void;
  onCancel(): void;
}> = (props) => {
  const store = useStore();
  const bookService = useBookService();
  const notification = useNotification();
  const { t } = useTranslation();
  const service = useUserBookService();
  const [bookList, setBookList] = useState<any[]>([]);
  const [form] = useForm();
  const [speechModal, setSpeechModal] = useState(false);
  const userService = useUserService();
  const http = useHttp();
  const [userList, setUserList] = useState<UserGetListResModel[]>([]);
  const [bookId, setBookId] = useState<any>("");
  const [searchDisabled, setSearchDisabled] = useState(true);
  const loading = useLoading();

  const onSearch = useCallback(
    async (val: string) => {
      if (val.length < 3) return;
      await new Promise((r) => {
        setTimeout(() => {
          r(true);
        }, 500);
      });
      const res = await userService.search(val);
      if (!res.result) return;
      setUserList(res.data);
    },
    [setUserList, userService]
  );

  useEffect(() => {
    store.getLanguages();
    store.getAllTiers();
    // eslint-disable-next-line
  }, [props.open]);

  const onSearchBook = useCallback(
    async (val: string) => {
      await new Promise((r) => setTimeout(() => r(true), 700));
      const lang: string = form.getFieldValue("language");
      if (!lang) {
        notification.destroy("searcBook");
        notification.info({
          key: "searchBook",
          message: t("Required", { value: t("Language") }),
        });
      }
      if (!lang || val.length < 3) return;
      const res: any = await bookService.search({
        limit: 50,
        page: 1,
        language: lang,
        query: val,
      });

      const list: any[] = [...res.search_results];

      const processedIsbns = res.search_results.map((item: any) => item.isbn);
      const processedNames = res.search_results.map(
        (item: any) => item.book_name
      );
      const googleData = {
        query: val,
        language: lang,
        limit: 40,
        page: 1,
        processedIsbns: processedIsbns,
        processedNames: processedNames,
      };

      const res2 = await http.post<any, { search_results: any[] }>(
        `/books/search/${lang}`,
        googleData
      );

      if (res2.result) {
        res2.search_results.forEach((e) => list.push(e));
      }

      setBookList(list);
    },
    [form, setBookList, notification, t, bookService, http]
  );

  const onReset = useCallback(() => {
    form.resetFields();
    setUserList([]);
    setBookList([]);
    setBookId(null);
    setSearchDisabled(true);
  }, [form, setUserList, setSearchDisabled, setBookList, setBookId]);

  const onDone = useCallback(() => {
    onReset();
    props.onDone();
  }, [onReset, props]);

  const onCancel = useCallback(() => {
    onReset();
    props.onCancel();
  }, [onReset, props]);

  const generateUniqueFileName = () => {
    const timestamp = new Date().getTime();
    const randomString = Math.random().toString(36).substring(2, 8);
    const uniqueFileName = `audio_${timestamp}_${randomString}`;
    return uniqueFileName;
  };

  const getDuration = async (file: File) => {
    const url = URL.createObjectURL(file);

    return new Promise((resolve) => {
      const audio = document.createElement("audio");
      audio.muted = true;
      const source = document.createElement("source");
      source.src = url; //--> blob URL
      audio.preload = "metadata";
      audio.appendChild(source);
      audio.onloadedmetadata = function () {
        resolve(audio.duration);
      };
    });
  };

  const onSubmit = useCallback(
    async (val: any) => {
      loading.addLoading("newSummaryModalSubmit");
      const file = val.audio_file;
      const duration = await getDuration(file);
      const fileExtension = file.name.split(".").pop();
      const uniqueFileName = generateUniqueFileName() + "." + fileExtension;
      const storageRef = ref(storage, `audios/${uniqueFileName}`);
      uploadBytes(storageRef, file)
        .then(async (snapshot:any) => {
          console.log("Dosya yüklendi:", snapshot);
          const downloadURL = await getDownloadURL(snapshot.ref);
          console.log("File available at", downloadURL);

          let _bookId = bookId;
          const newBook = bookList.find((e) => e?.isbn === _bookId);
          const lang: string = form.getFieldValue("language");
          if (newBook) {
            const service = await http.post<any, any>("books", {
              book_name: newBook.book_name,
              author: newBook.author,
              isbn: newBook.isbn,
              image: newBook.image,
              description: newBook.description,
              language: lang,
              categories: newBook.categories.map((item: any) => item),
            });

            if (service) {
              _bookId = service.book_id;
            }
          }

          const formdata = new FormData();
          Object.keys(val).forEach((e) => {
            formdata.append(e, val[e]);
          });

          formdata.append("book_id", _bookId);
          formdata.delete("audio_file");
          formdata.append("audio_url", downloadURL);
          formdata.append("duration", String(duration));

          const res = await service.create(formdata as any);
          loading.removeLoading("newSummaryModalSubmit");
          if (!res.result) return;
          onDone();
        })
        .catch((error:any) => {
          console.error("Dosya yükleme hatası:", error);
          alert("Dosya yüklenirken bir hata oluştu!");
          loading.removeLoading("newSummaryModalSubmit");
        });
    },
    [service, bookId, onDone, bookList, http, form, loading]
  );

  const formItems = useCreateFormItems<
    UserBookCreateReqModel & { _book_id: number }
  >([
    {
      formItem: {
        label: "Language",
        name: "language",
        required: true,
      },
      colProps: {
        span: 24,
      },
      element: (
        <StyledSelect
          onSelect={(v) => {
            setSearchDisabled(v ? false : true);
          }}
          options={store.data.languages.map((e) => ({
            label: `${e.language.toUpperCase()} - ${e.code.toUpperCase()}`,
            value: e.code,
          }))}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      formItem: {
        label: "Book",
        name: "_book_id",
        required: true,
      },
      colProps: {
        span: 24,
      },

      element: (
        <>
          <Card>
            <StyledInput.Search
              disabled={searchDisabled}
              style={{ marginBottom: 10 }}
              onSearch={onSearchBook}
              size="large"
              placeholder={t("EntryQuery").toString()}
              enterButton
              onPressEnter={e=> e.preventDefault()}
            />
            <ITable
              dataSource={bookList}
              withOutCard
              columns={[
                {
                  dataIndex: "book_id",
                  title: "Select",
                  align: "center",
                  width: 100,
                  render: (v, r) => {
                    const rowBookId = r.book_id || r.isbn;
                    //  bookList.find(e => e.book_id === v || e.isbn === v);
                    const disabled = bookId === rowBookId;

                    return (
                      <Button
                        disabled={disabled}
                        onClick={() => {
                          setBookId(rowBookId);
                        }}
                        type="primary"
                      >
                        <CheckCircleOutlined />
                      </Button>
                    );
                  },
                },
                {
                  dataIndex: "book_name",
                  title: "Book",
                  width: "100%",
                },
              ]}
            />
          </Card>
        </>
      ),
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 24,
      },
      element: (
        <StyledSelect
          onSearch={(v) => onSearch(v)}
          options={userList.map((e) => ({
            label: `${e.name} ${e.surname} / ${e.username}`,
            value: e.id,
          }))}
          filterOption={false}
          showSearch
        />
      ),
      elementType: ComponentTypes.Input,
      formItem: {
        label: "User",
        name: "id",
        required: true,
      },
    },
    {
      formItem: {
        label: "Price",
        name: "price",
        required: true,
        extra:t('PriceNotification')
      },
      colProps: {
        span: 24,
      },
      element:<StyledNumberInput />,
      elementType: ComponentTypes.Input,
    },
    {
      formItem: {
        label: "FreeListeningTime",
        name: "free_listening_time",
        required: true,
        rules: [
          {
            validator(rule, value, callback) {
              callback(
                value > -1 && value < 11
                  ? undefined
                  : t("FreeListenValid").toString()
              );
            },
          },
        ],
      },
      colProps: {
        span: 24,
      },
      element: <StyledNumberInput max={10} min={0} />,
      elementType: ComponentTypes.Input,
    },
    {
      formItem: {
        label: "Audio",
        name: "audio_file",
        required: true,
      },
      colProps: {
        span: 24,
      },
      element: <SoundUpload />,
      elementType: ComponentTypes.Input,
    },
  ]);

  return (
    <Modal
      open={props.open}
      onCancel={() => onCancel()}
      okButtonProps={{
        htmlType: "submit",
        form: "newsummarymodalform",
      }}
      width={"50vw"}
    >
      <Form id="newsummarymodalform" form={form} onFinish={(v) => onSubmit(v)}>
        <Row gutter={24}>
          {formItems}
          <Col span={24} style={{ textAlign: "center" }}>
            <Button
              type="primary"
              onClick={() => setSpeechModal(true)}
              icon={<GoogleCircleFilled />}
            >
              {t("GoogleTextSpeech")}
            </Button>
          </Col>
        </Row>
      </Form>
      <GoogleTextToSpeechModal
        onClose={() => setSpeechModal(false)}
        onSelect={(v) => {
          setSpeechModal(false);
          form.setFieldValue("audio_file", v);
        }}
        open={speechModal}
      />
    </Modal>
  );
};
