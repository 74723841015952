import { useCallback, useEffect, useState } from "react";
import dayjs from 'dayjs';
import { PageCard } from "../../components/pageCard"
import { SalesGetListResModel } from "../../services/sales/sales";
import { useSalesService } from "../../services/sales/sales.service"
import { ITable } from "../../components/table/table";
import { IColumnProps } from "../../components/table";
import { useTranslation } from "react-i18next";

import { ExcelExport } from "../../components/excellExport";

interface DonationsProps {
}

export const Donations: React.FC<DonationsProps> = props => {
    const service = useSalesService();
    const [state, setState] = useState<SalesGetListResModel[]>([])
    const { t } = useTranslation();
    const [excelData, setExcelData] = useState<any[]>([]);

    const getList = useCallback(async () => {
        const res = await service.getAllSales();
        if (!res.result) return;
        setState(res.transactions);
        setExcelData(res.transactions)
    }, [service, setState, setExcelData])

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])

    const columns: IColumnProps<SalesGetListResModel>[] = [
        {
            dataIndex: 'price',
            title: 'Price',
            sortNumber: true,
            sortNumberFormater: (v) => parseFloat(String(v).replace(/\$/g, ''))
        },
        {
            dataIndex: 'currency',
            title: 'CustomerMoneyCurrency'
        },
        {
            dataIndex: 'user_book',
            title: 'Seller',
            render: (v, r) => `${r.user_book.user.name} ${r.user_book.user.surname}  / ${r.user_book.user.username}`,
            textFilter: true,
            textFilterData: (r) => `${r.user_book.user.name} ${r.user_book.user.surname}`
        },
        {
            dataIndex: 'user',
            title: 'Buyyer',
            render: (v, r) => `${r.user.name}  ${r.user.surname} / ${r.user.username}`,
            textFilter: true,
            textFilterData: (r) => `${r.user.name} ${r.user.surname}`
        },
        {
            dataIndex: 'transaction_date',
            title: 'Date',
            isDate: true,
            dateFormat:'DD/MM/YYYY - hh:mm:ss',
            sortDate: true
        }
    ]

    return (
        <PageCard
            extra={<ExcelExport
                data={excelData}
                fileName={`Donations-${new Date().toUTCString()}`}
                rowConverter={(r: SalesGetListResModel) => ({
                    'Transaction': r.ut_id,
                    [t('Price')]: r.localized_price,
                    [t('Currency')]: r.currency,
                    [t('Seller')]: `${r.user_book.user.name} ${r.user_book.user.surname}  / ${r.user_book.user.username}`,
                    [t('Buyyer')]: `${r.user.name}  ${r.user.surname} / ${r.user.username}`,
                    [t('Date')]: dayjs(r.transaction_date).format('DD/MM/YYYY').toString()
                })}
            />}
        >
            <ITable
                rowKey={"ut_id"}
                dataSource={state}
                columns={columns}
                onChange={(pg, f, s, e) => {
                    setExcelData(e.currentDataSource)
                }}
            />
        </PageCard >
    )
}