import { Form, Modal, Row } from "antd";
import { useAdminService } from "../../services/admin/admin.service"
import { ComponentTypes, ModalData } from "../../models/system";
import { useForm } from "antd/lib/form/Form";
import { useCallback } from "react";
import { AdminCreateReqModel, AdminType } from "../../services/admin/admin";
import { useTranslation } from "react-i18next";
import { useCreateFormItems } from "../../helpers/form";
import { StyledInput, StyledSelect } from "../../components/form";
import { EnumToSelectOptions } from "../../helpers/utils";

interface NewAdminModalProps {
    data: ModalData<number>;
    onDone(): void;
    onCancel(): void;
}

export const NewAdminModal: React.FC<NewAdminModalProps> = props => {
    const service = useAdminService();
    const [form] = useForm();
    const { t } = useTranslation();

    const onReset = useCallback(() => {
        form.resetFields();
    }, [form])

    const onDone = useCallback(() => {
        onReset();
        props.onDone();
    }, [onReset, props])

    const onCancel = useCallback(() => {
        onReset();
        props.onCancel();
    }, [onReset, props])

    const onSubmit = useCallback(async (val: AdminCreateReqModel) => {
        const res = await service.create(val);
        if (!res.result) return;
        onDone()
    }, [onDone, service])

    const formItems = useCreateFormItems<AdminCreateReqModel>([
        {
            formItem: {
                label: 'Username',
                name: 'username',
                required: true
            },
            colProps: {
                span: 24
            },
            element: <StyledInput />,
            elementType: ComponentTypes.Input
        },
        {
            formItem: {
                label: 'Password',
                name: 'password',
                required: true
            },
            colProps: {
                span: 24
            },
            element: <StyledInput.Password />,
            elementType: ComponentTypes.Input
        },
        {
            formItem: {
                label: 'Role',
                name: 'admin_type',
                required: true
            },
            colProps: {
                span: 24
            },
            element: <StyledSelect options={EnumToSelectOptions(AdminType, {})} />,
            elementType: ComponentTypes.Input
        }
    ])

    return (
        <Modal
            title={t('NewAdminUser')}
            open={props.data.open}
            onCancel={() => onCancel()}
            okButtonProps={{
                form: 'newAdminModalForm',
                htmlType: 'submit'
            }}
        >
            <Form form={form} id="newAdminModalForm" onFinish={(v) => onSubmit(v)}>
                <Row gutter={16}>
                    {formItems}
                </Row>
            </Form>
        </Modal>
    )
}