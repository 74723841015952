import { BookOutlined, FlagOutlined, StarOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Card, Col, Divider, List, Row, Statistic, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BackofficeStatisticsRes, useStatisticService } from '../../services/statistic/statistic.service';
interface DashboardProps {

}


export const Dashboard: React.FC<DashboardProps> = (props) => {
    const { t } = useTranslation();
    const service = useStatisticService();
    const [state, setState] = useState<BackofficeStatisticsRes>({
        mostFavouritedBooks: [],
        mostFollowedUsers: [],
        totalApprovedVoiceOver: 0,
        totalBookfluencers: 0,
        totalBooks: 0,
        totalUsers: 0,
        freeAddedToLibrary: 0,
        totalSales: 0,
        totalEarning: '',
        todayEarning: '',
        weekEarning: '',
        monthEarning: '',
        totalListenings: 0,
        dailyTotalSales: 0,
        weeklyTotalSales: 0,
        monthlyTotalSales: 0,
        dailyTotalListenings: 0,
        weeklyTotalListenings: 0,
        monthlyTotalListenings: 0,
        totalBookSalesCredit:0,
        dailyTotalBookSalesCredit:0,
        weeklyTotalBookSalesCredit:0,
        monthlyTotalBookSalesCredit:0,
        totalBookSalesCount:0,
        dailyTotalBookSalesCount:0,
        weeklyTotalBookSalesCount:0,
        monthlyTotalBookSalesCount:0,

    });

    useEffect(() => {
        service.getStatistics().then(e => {
            if (!e.result) return;
            setState(e);
        })
        // eslint-disable-next-line
    }, []);

    return (
        <>

            <Row gutter={[16, 16]}>
                <Col span={6}>
                    <Card>
                        <Statistic title={
                            `${t('TotalBooks')}`} value={
                                `${state.totalBooks}`
                            } />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>

                        <Statistic title={
                            `${t('freeAddedToLibrary')}`} value={
                                ` ${state.freeAddedToLibrary}`
                            } />
                    </Card>
                </Col>

                <Col span={6}>
                    <Card>
                        <Statistic title={t('TotalVoicesBooks')} value={state.totalApprovedVoiceOver} />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title={`${t('TotalUsers')} / ${t('TotalBookFluencer')}`} value={`${state.totalUsers} / ${state.totalBookfluencers}`} />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title={t('totalEarning')} value={state.totalEarning} />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title={t('todayEarning')} value={state.todayEarning} />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title={t('weekEarning')} value={state.weekEarning} />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title={t('monthEarning')} value={state.monthEarning} />
                    </Card>
                </Col>


                <Col span={6}>
                    <Card>
                        <Statistic title={t('totalBookSalesCredit')} value={state.totalBookSalesCredit} />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title={t('dailyTotalBookSalesCredit')} value={state.dailyTotalBookSalesCredit} />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title={t('weeklyTotalBookSalesCredit')} value={state.weeklyTotalBookSalesCredit} />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title={t('monthlyTotalBookSalesCredit')} value={state.monthlyTotalBookSalesCredit} />
                    </Card>
                </Col>

                <Col span={6}>
                    <Card>
                        <Statistic title={t('totalBookSalesCount')} value={state.totalBookSalesCount} />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title={t('dailyTotalBookSalesCount')} value={state.dailyTotalBookSalesCount} />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title={t('weeklyTotalBookSalesCount')} value={state.weeklyTotalBookSalesCount} />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title={t('monthlyTotalBookSalesCount')} value={state.monthlyTotalBookSalesCount} />
                    </Card>
                </Col>


                <Col span={6}>
                    <Card>
                        <Statistic title={t('totalSales')} value={state.totalSales} />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title={t('dailyTotalSales')} value={state.dailyTotalSales} />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title={t('weeklyTotalSales')} value={state.weeklyTotalSales} />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title={t('monthlyTotalSales')} value={state.monthlyTotalSales} />
                    </Card>
                </Col>
                {
                    Object.keys(state).filter(e => [
                        "totalListenings",
                        "dailyTotalListenings",
                        "weeklyTotalListenings",
                        "monthlyTotalListenings",

                    ].includes(e)
                    ).map(key => {
                        return <Col span={6}>
                            <Card>
                                <Statistic title={t(key)} value={(state as any)[key]} />
                            </Card>
                        </Col>
                    })
                }

            </Row>
            <Row style={{ marginTop: 16 }} gutter={[16, 16]}>
                <Col span={12}>
                    <Card>
                        <Statistic title={t('BestListeningBooks')} value={state.mostFavouritedBooks.length} prefix={<BookOutlined />} />
                        <Divider />
                        <List
                            itemLayout="horizontal"
                            dataSource={state.mostFavouritedBooks}
                            style={{
                                overflowY: 'scroll'
                            }}
                            renderItem={(item, index) => (
                                <List.Item key={index}>
                                    <List.Item.Meta
                                        avatar={<Avatar src={item.image} />}
                                        title={<div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
                                            {item.book_name}
                                            <div>
                                                <Tag color="green" icon={<UserOutlined />} children={item.author} />
                                                <Tag color='blue' icon={<FlagOutlined />} children={item.language.toUpperCase()} />
                                                <Tag color='yellow' icon={<StarOutlined />} children={item.favorite_count} />
                                            </div>
                                        </div>}
                                        description={item.description.slice(0, 160) + '...'}
                                    />
                                </List.Item>
                            )}
                        />
                    </Card>
                </Col>

                <Col span={12}>
                    <Card>
                        <Statistic title={t('BestFollowingUser')} value={state.mostFollowedUsers.length} prefix={<BookOutlined />} />
                        <Divider />
                        <List
                            itemLayout="horizontal"
                            dataSource={state.mostFollowedUsers}
                            style={{
                                overflowY: 'scroll'
                            }}
                            renderItem={(item, index) => (
                                <List.Item key={index}>
                                    <List.Item.Meta
                                        title={<div style={{ gap: 10, display: 'flex' }}>
                                            <strong>{item.name} {item.surname}</strong>
                                            <Tag color='orange'>{item.username}</Tag>
                                        </div>}
                                    // description=" a design language for background applications, is refined by Ant UED Team"
                                    />
                                </List.Item>
                            )}
                        />
                    </Card>
                </Col>
            </Row>
        </>
    )
}