import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth, signInAnonymously } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC-s4y0LgfeEmCBo5_Qq3jKO9qZqEsUg-0",
  authDomain: "bookfluencer-88252.firebaseapp.com",
  projectId: "bookfluencer-88252",
  storageBucket: "bookfluencer-88252.appspot.com",
  messagingSenderId: "747636708365",
  appId: "1:747636708365:web:1a3f1715d2fb5893e43144",
  measurementId: "G-ZEPJFE8N77",
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

const auth = getAuth();
signInAnonymously(auth)
  .then(() => {
    console.log("Kullanıcı anonim olarak giriş yaptı");
    // Buradan itibaren dosya yükleme işlemini başlatabilirsiniz
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.error("Anonim giriş hatası", errorCode, errorMessage);
  });

export { storage };
