import { useCallback } from "react";
import { useHttp } from "../../hooks/http"
import { LanguageModel } from "./language";

export const useLanguageService = () => {
    const http = useHttp();

    const getAll = useCallback(async () => {
        return await http.get<{ data: LanguageModel[] }>('/languages');
    }, [http])

    return {
        getAll
    }
}