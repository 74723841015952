import { useCallback } from "react";
import { useHttp } from "../../hooks/http"
import { CommissionCreateReqModel, CommissionGetListModel, CommissionUpdateReqModel } from "./commission";

export const useCommissionService = () => {
    const http = useHttp();

    const getList = useCallback(async () => {
        return await http.get<{ data: CommissionGetListModel[] }>('/comissions')
    }, [http])

    const create = useCallback(async (req: CommissionCreateReqModel) => {
        return await http.post('/comission', req);
    }, [http])

    const update = useCallback(async (req: CommissionUpdateReqModel) => {
        return await http.post('/comission/update', req);
    }, [http])

    const remove = useCallback(async (id: number) => {
        return await http.delete(`/comission/delete/${id}`)
    }, [http])

    return {
        getList,
        create,
        update,
        remove
    }

}