import { useCallback, useEffect, useState } from "react";
import { useAgreementService } from "../../../services/agreement/agreement.service"
import { AgreementAddUpdateModel, AgreementGet } from "../../../services/agreement/agreement";
import { PageCard } from "../../../components/pageCard";
import { ITable } from "../../../components/table/table";
import { IColumnProps } from "../../../components/table";
import { ModalData, ModalDataModel } from "../../../models/system";
import { AgreementContentAddModal } from "./agreementContentAddModal";

export const Agreements: React.FC = props => {

    const service = useAgreementService();
    const [state, setState] = useState<AgreementGet[]>([]);
    const [contentModal, setContentModal] = useState<ModalData<AgreementAddUpdateModel>>(ModalDataModel())
    const getAll = useCallback(async () => {
        const res = await service.getAll();
        if (!res.result) return;
        setState(res.data);
    }, [service, setState])

    useEffect(() => {
        getAll()
        // eslint-disable-next-line
    }, [])

    const columns: IColumnProps<AgreementGet>[] = [
        {
            dataIndex: 'lang',
            title: 'Language'
        },
        {
            dataIndex: 'title',
            title: 'Title'
        },

        {
            dataIndex: 'created_at',
            title: 'CreatedDate',
            isDate: true
        },
        {
            dataIndex: 'updated_at',
            title: 'UpdatedDate',
            isDate: true
        },
        {
            dataIndex: 'ua_id',
            actionColumn: {
                items: (row) => [
                    {
                        label: 'Remove',
                        key: 'remove',
                        onClick: async () => {
                            const res = await service.remove(row.ua_id);
                            if (!res.result) return
                            getAll()
                        }
                    }
                ],
                hasUpdate: {
                    callback: (data, row) => {
                        setContentModal({
                            data: row,
                            open: true
                        })
                    },
                    propName: 'ua_id'
                }
            }
        }
    ]


    return (
        <>
            <PageCard
                onClickNew={() => setContentModal({ data: null, open: true })}

            >
                <ITable
                    rowKey={"agreement_id"}
                    columns={columns}
                    dataSource={state}
                />
            </PageCard >

            <AgreementContentAddModal
                data={contentModal}
                onCancel={() => setContentModal(ModalDataModel())}
                onDone={() => {
                    setContentModal(ModalDataModel())
                    getAll()
                }}
            />
        </>
    )
}