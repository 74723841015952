import React, { useCallback } from 'react';
import { ModalData } from '../../models/system';
import { Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useWithdrawalService } from '../../services/withdrawal/withdrawal.service';
import { useForm } from 'antd/lib/form/Form';
import { WithdrawalDeclineReqModel } from '../../services/withdrawal/withdrawal';
import TextArea from 'antd/es/input/TextArea';

interface WithdrawDeclineModalProps {
    data: ModalData<number>;
    onDone(): void;
    onCancel(): void;
}

export const WithdrawDeclineModal: React.FC<WithdrawDeclineModalProps> = props => {
    const { t } = useTranslation();
    const service = useWithdrawalService();
    const [form] = useForm();

    const onReset = useCallback(() => {
        form.resetFields();
    }, [form])

    const onDone = useCallback(() => {
        onReset();
        props.onDone();
    }, [onReset, props])

    const onCancel = useCallback(() => {
        onReset();
        props.onCancel();
    }, [onReset, props])

    const onSubmit = useCallback(async (val: WithdrawalDeclineReqModel) => {
        if (!props.data.data) return;

        const res = await service.decline({
            ...val,
            request_id: props.data.data
        });

        if (!res.result) return;
        onDone();
    }, [onDone, service, props.data])

    return (
        <Modal
            open={props.data.open}
            title={t('Decline')}
            okButtonProps={{
                form: 'withdraw-decline-form',
                htmlType: 'submit',
            }}
            okText={t('Decline')}
            onCancel={() => onCancel()}

        >
            <Form id="withdraw-decline-form" form={form} onFinish={(v) => onSubmit(v)}>
                <Form.Item name={"explanation"} label={t('Description')} required>
                    <TextArea
                        maxLength={254}
                        required
                        minLength={20}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}