import { Button, Form, InputNumber, Modal, Row, Tooltip } from "antd";
import { ComponentTypes, ModalData, YesOrNoEnum } from "../../models/system";
import { useBannerService } from "../../services/banner/banner.service";
import { useForm } from "antd/lib/form/Form";
import { useCallback, useEffect, useState } from "react";
import { useCreateFormItems } from "../../helpers/form";
import { BannerCreateReqModel, BannerGetByLangResModel, BannerType } from "../../services/banner/banner";
import { IDatePicker, StyledSelect } from "../../components/form";
import { useTranslation } from "react-i18next";
import { useBookService } from "../../services/book/book.service";
import { BookLangResModel, BookListReqModel } from "../../services/book/book";
import { useStore } from "../../store";
import { PaginationResDto, PaginationResModel } from "../../models/system/http";
import { ITable } from "../../components/table/table";
import { ImageUpload } from "../../components/imageUpload";

interface BannerModalProps {
    modalData: ModalData<BannerGetByLangResModel>;
    onDone(): void;
    onCancel(): void;
}

export const BannerModal: React.FC<BannerModalProps> = props => {
    const service = useBannerService();
    const [form] = useForm();
    const { t } = useTranslation();
    const store = useStore();
    const [bannerType, setBannerType] = useState<BannerType>(BannerType.TopBanner);
    const bookService = useBookService();
    const [books, setBooks] = useState<PaginationResModel<BookLangResModel>>(PaginationResDto())
    const [bookFilter, setBookFilter] = useState<BookListReqModel>({
        limit: 10,
        page: 1
    });

    const getBooks = useCallback(async () => {
        const res = await bookService.getList(bookFilter, form.getFieldValue('language'));
        if (!res.result) return;
        setBooks(res.data);
    }, [bookService, bookFilter, setBooks, form])

    useEffect(() => {
        getBooks()
        // eslint-disable-next-line
    }, [bookFilter])

    useEffect(() => {
        if (props.modalData.open) {
            store.getLanguages();
        }
        if (props.modalData.data) {
            form.setFieldsValue(props.modalData.data)
            form.setFieldValue('book_id', props.modalData.data.book_id);
            getBooks()
        }
        // eslint-disable-next-line
    }, [props.modalData.open])

    const onReset = useCallback(() => {
        form.resetFields();
    }, [form])

    const onDone = useCallback(() => {
        onReset();
        setBooks(PaginationResDto())
        setBookFilter({ limit: 10, page: 1 })
        props.onDone()
    }, [onReset, props, setBookFilter, setBooks])

    const onCancel = useCallback(() => {
        onReset();
        props.onCancel()
    }, [onReset, props])

    const onSubmit = useCallback(async (val: any) => {
        let res;
        if (props.modalData.data) {
            res = await service.update(val, props.modalData.data.banner_id);
        } else {
            res = await service.create(val);
        }

        if (!res.result) return;
        onDone();
    }, [props.modalData, service, onDone])

    const formItems = useCreateFormItems<BannerCreateReqModel>([
        {
            colProps: {
                span: 12
            },
            element: <StyledSelect options={[
                {
                    label: t('TopBanner'),
                    value: BannerType.TopBanner
                },
                {
                    label: t('BottomBanner'),
                    value: BannerType.BottomBanner
                }
            ]}

                onSelect={(v: any) => setBannerType(v)}
            />,
            elementType: ComponentTypes.Input,
            formItem: {
                name: 'banner_type',
                label: 'BannerType',
                initialValue: bannerType
            }
        },
        {
            colProps: {
                span: 12
            },
            element: <StyledSelect onSelect={() => getBooks()} options={store.data.languages.filter(e => e.isAppLanguage === YesOrNoEnum.Yes).map(e => ({ label: `${e.language.toUpperCase()} - ${e.code.toUpperCase()}`, value: e.code }))} />,
            elementType: ComponentTypes.Input,
            formItem: {
                name: 'language',
                label: 'Language'
            }
        },


        {
            colProps: {
                span: 8
            },
            element: <InputNumber min={0} style={{ width: '100%' }} />,
            elementType: ComponentTypes.Input,
            formItem: {
                name: 'priority',
                label: 'Priority'
            },
        },
        {
            colProps: {
                span: 8
            },
            element: <IDatePicker />,
            elementType: ComponentTypes.Input,
            formItem: {
                name: 'start_date',
                label: 'StartDate',
            }
        },
        {
            colProps: {
                span: 8
            },
            element: <IDatePicker />,
            elementType: ComponentTypes.Input,
            formItem: {
                name: 'end_date',
                label: 'EndDate',
            }
        },
        {
            colProps: {
                span: 24
            },
            element: <ImageUpload />,
            elementType: ComponentTypes.Input,
            formItem: {
                name: 'image',
                label: 'Image'
            },
            active: !props.modalData.data
        },
        {
            colProps: {
                span: 24
            },
            element: <ITable
                onPageChange={(page, s) => setBookFilter(p => ({ ...p, page, limit: s }))}
                columns={[
                    {
                        dataIndex: 'book_id',
                        width: '10%',
                        render: (v) => <Button disabled={form.getFieldValue('book_id') === v} onClick={() => form.setFieldValue('book_id', v)}>{t('Select')}</Button>
                    },
                    {
                        dataIndex: 'book_name',
                        title: 'BookName',
                        textFilter: true
                    },
                    {
                        title: 'Description',
                        dataIndex: 'description',
                        render: (v) => {
                            const desc = String(v);
                            if (desc.length < 50) return desc;
                            return <Tooltip placement="left" title={v}>{String(v).substring(0, 50) + "..."}</Tooltip>
                        }
                    },
                ]} data={books} />,
            elementType: ComponentTypes.Input,
            formItem: {
                name: 'book_id',
                label: 'Book'
            }
        },
    ]);

    return (
        <Modal
            open={props.modalData.open}
            onCancel={() => onCancel()}
            okButtonProps={{
                htmlType: 'submit',
                form: 'bannerModalForm'
            }}
            width={'60vw'}
        >
            <Form form={form} id="bannerModalForm" onFinish={(v) => onSubmit(v)}>
                <Row gutter={24}>
                    {formItems}
                    {
                        // bookTable
                    }
                </Row>
            </Form>
        </Modal>
    )
}