import { useForm } from "antd/lib/form/Form";
import { ModalData } from "../../models/system";
import { useUserBookService } from "../../services/userBook/userBook.service";
import { useCallback } from "react";
import { Form, Modal } from "antd";
import { useTranslation } from "react-i18next";
import TextArea from "antd/es/input/TextArea";

interface SummaryDeclineModalProps {
    data: ModalData<number>;
    onDone(): void;
    onCancel(): void;
    isAdmin?:boolean;
}

export const SummaryDeclineModal: React.FC<SummaryDeclineModalProps> = props => {

    const [form] = useForm();
    const service = useUserBookService();
    const { t } = useTranslation();

    const onReset = useCallback(() => {
        form.resetFields();
    }, [form])

    const onCancel = useCallback(() => {
        onReset();
        props.onCancel()
    }, [onReset, props])

    const onDone = useCallback(() => {
        onReset();
        props.onDone()
    }, [onReset, props])

    const onSubmit = useCallback(async (val: any) => {
        if (!props.data.data) return;
        let res;
        if(props.isAdmin){
            res = await service.declineAdmin(props.data.data, val.description);
        }else{
            res = await service.decline(props.data.data, val.description);
        }
        if (!res.result) return
        onDone();
    }, [service, onDone, props.data, props.isAdmin])


    return (
        <Modal
            open={props.data.open}
            title={t('SummaryDecline')}
            onCancel={() => onCancel()}
            okButtonProps={{
                form: 'summaryDeclineForm',
                htmlType: 'submit'
            }}
        >
            <Form id="summaryDeclineForm" form={form} onFinish={(v) => { onSubmit(v) }}>
                <Form.Item name={"description"} label={t('RejecetedDescription')} labelCol={{ span: 24 }}>
                    <TextArea />
                </Form.Item>
            </Form>
        </Modal>
    )
}