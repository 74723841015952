import React, { useEffect, useState } from 'react';
import RichTextEditor, { EditorValue } from 'react-rte';

interface RichEditorProps {
    value?: string;
    onChange?(value: string): void;
}

export const RichEditor: React.FC<RichEditorProps> = props => {
    const [state, setState] = useState<EditorValue>(RichTextEditor.createValueFromString(props.value || "", "html"));

    useEffect(() => {
        if (props.onChange) {
            props.onChange(state.toString("html"))
        }
        // eslint-disable-next-line
    }, [state])

    useEffect(()=>{
        if(props.value && !state.toString('html').length){
            setState(RichTextEditor.createValueFromString(props.value || "", "html"))
        }
        // eslint-disable-next-line
    },[props.value])

    return (
        <>
            <RichTextEditor
                editorStyle={{
                    height: '300px',
                    color: 'black'
                }}
                onChange={(value) => {
                    setState(value)
                }}
                value={state}
            />
        </>
    )
}