import React, { useEffect, useState } from "react";
import { PageCard } from "../../components/pageCard"
import { useHttp } from "../../hooks/http"
import { IColumnProps } from "../../components/table";
import { ITable } from "../../components/table/table";
import { Card, Col, Row, Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { UserOutlined } from "@ant-design/icons";
import Meta from "antd/es/card/Meta";
import { UserReportDto } from './userReport';
import { useUserService } from "../../services/user/user.service";
import { useStore } from "../../store";
import { useModal } from "../../hooks/modal";

export const ReportedUsers: React.FC<{ data?: any[] }> = (props) => {
    const http = useHttp();
    const store = useStore();
    const userService = useUserService();

    const modal = useModal();

    useEffect(() => {
        if (!props.data) {
            store.getReportedUsers()
        }
        // eslint-disable-next-line
    }, [])

    const columns: IColumnProps<UserReportDto>[] = [
        {
            dataIndex: 'user',
            render: (v, r) => `${r.flagged_user.name} ${r.flagged_user.surname} / ${r.flagged_user.username}`,
            title: 'flagged_user',
            textFilter: true,
            textFilterData:(row) =>row.flagged_user.name + " " + row.flagged_user.surname,
        },

        {
            dataIndex: 'user',
            render: (v, r) => `${r.user.name} ${r.user.surname} / ${r.user.username}`,
            title: 'reporter_user',
            textFilter: true,
            textFilterData:(row) =>row.user.name + " " + row.user.surname,
        },
        {
            dataIndex:'reason',
            title:'Reason',
            textFilterData:(row) =>row.reason,
            textFilter: true
        },
        {
            dataIndex: 'created_at',
            title: 'Date',
            sortDate:true,
            isDate: true
        },
        {
            dataIndex: 'user',
            actionColumn: {
                items(row) {
                    return [
                        {
                            label: t('BanUser'),
                            key: 'banuser',
                            onClick: async () => {
                                modal.confirm({
                                    content: t('ApproveUserBan'),
                                    onOk: async () => {
                                        const res = await userService.ban(row.user.id);
                                        if (!res.result) return;
                                        const readRes = await http.post('/chat-reports/read', { ucr_id: row.ucr_id })
                                        if (!readRes.result) return;
                                        store.getReportedUsers()
                                    }
                                })
                            }
                        },
                        {
                            label: t('SetRead'),
                            key: 'read',
                            onClick: async () => {
                                const readRes = await http.post('/chat-reports/read', { ucr_id: row.ucr_id })
                                if (!readRes.result) return;
                                store.getReportedUsers()
                            }
                        }
                    ]
                },
            }
        }
    ]

    const { t } = useTranslation()
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])

    return (
        <PageCard title={props.data ? ' ' : undefined}>
            <ITable
                rowKey={"index"}
                columns={columns}
                dataSource={props.data || store.data.reportedUserList}
                expandable={{
                    rowExpandable(record: UserReportDto) {
                        return Boolean(record.index)
                    },
                    expandedRowKeys: expandedRowKeys,
                    onExpand(expanded, record: UserReportDto) {
                        setExpandedRowKeys(p => {
                            if (expanded) {
                                p.push(record.index)
                                return p;
                            }

                            return p.filter(key => key !== record.index)
                        })
                    },
                    expandedRowRender: (r: UserReportDto, i, indent, expanded) => {

                        if (!expanded) return null
                        const { user } = r;
                        return (
                            <div
                                key={indent}
                            >
                                <Row gutter={16}>

                                    <Col span={12}>
                                        <Card
                                            title={t('ReportedUser')}
                                        >
                                            <Meta
                                                title={<>{r.flagged_user.name} {r.flagged_user.surname}</>}
                                                description={
                                                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                                                        <Tag color="orange" icon={<UserOutlined />} children={<>{r.flagged_user.username}</>} />
                                                    </div>
                                                }
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={12}>
                                        <Card
                                            title={t('Reporter')}
                                        >
                                            <Meta
                                                title={<>{user.name} {user.surname}</>}
                                                description={
                                                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                                                        <Tag color="orange" icon={<UserOutlined />} children={<>{user.username}</>} />
                                                    </div>
                                                }
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={24}>
                                        <Card
                                            title={t('Reason')}
                                        >
                                            <Typography.Text>{r.reason}</Typography.Text>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                }}
            />
        </PageCard>
    )
}