import { useCallback } from "react";
import { useHttp } from "../../hooks/http"

export interface BackofficeStatisticsRes {
    totalUsers: number;
    totalBookfluencers: number;
    totalBooks: number;
    totalApprovedVoiceOver: number;
    mostFollowedUsers: {
        "id": number,
        "name": string,
        "surname": string,
        "username": string,
        "follow_count": number;
    }[];
    mostFavouritedBooks: {
        "book_id": number,
        book_name: string;
        author: string;
        language: string;
        image: string;
        description: string;
        "favorite_count": number;
    }[];
    freeAddedToLibrary: number;
    totalSales: number;
    totalListenings: number;
    dailyTotalSales: number;
    weeklyTotalSales: number;
    monthlyTotalSales: number;
    dailyTotalListenings: number;
    weeklyTotalListenings: number;
    monthlyTotalListenings: number;
    totalEarning: string;
    todayEarning: string;
    weekEarning: string;
    monthEarning: string;
    totalBookSalesCredit: number;
    dailyTotalBookSalesCredit: number;
    weeklyTotalBookSalesCredit: number;
    monthlyTotalBookSalesCredit: number;
    totalBookSalesCount: number;
    dailyTotalBookSalesCount: number;
    weeklyTotalBookSalesCount: number;
    monthlyTotalBookSalesCount: number;
}

export const useStatisticService = () => {

    const http = useHttp();

    const getStatistics = useCallback(async () => {
        return await http.get<BackofficeStatisticsRes>('/backoffice/statistics')
    }, [http])

    return {
        getStatistics
    }
}