import { Form, Modal, Row } from "antd";
import { useCommissionService } from "../../services/commission/commission.service"
import { ComponentTypes, ModalData } from "../../models/system";
import { useTranslation } from "react-i18next";
import { CommissionCreateReqModel, CommissionGetListModel } from "../../services/commission/commission";
import { useForm } from "antd/es/form/Form";
import { useCreateFormItems } from "../../helpers/form";
import { StyledInput } from "../../components/form";
import { useCallback, useEffect } from "react";

interface CommissionsModalProps {
    data: ModalData<CommissionGetListModel>;
    onCancel(): void;
    onDone(): void;
}
export const CommissionsModal: React.FC<CommissionsModalProps> = props => {

    const service = useCommissionService();
    const { t } = useTranslation();
    const [form] = useForm();

    const onReset = useCallback(() => {
        form.resetFields();
    }, [form])

    const onCancel = useCallback(() => {
        onReset();
        props.onCancel()
    }, [onReset, props])

    const onDone = useCallback(() => {
        onReset();
        props.onDone()
    }, [onReset, props])

    useEffect(() => {
        if (!props.data.data) return;
        form.setFieldsValue(props.data.data);
        // eslint-disable-next-line
    }, [props.data])

    const formItems = useCreateFormItems<CommissionCreateReqModel>([
        {
            colProps: {
                span: 12
            },
            formItem: {
                name: 'amount',
                label: 'Amount'
            },
            element: <StyledInput inputMode="decimal" />,
            elementType: ComponentTypes.Input
        },
        {
            colProps: {
                span: 12
            },
            formItem: {
                name: 'rate',
                label: 'Rate'
            },
            element: <StyledInput inputMode="decimal" />,
            elementType: ComponentTypes.Input
        },
    ]);

    return (
        <Modal
            open={props.data.open}
            title={t(props.data.data ? 'Update' : 'New')}
            okButtonProps={{
                form: 'commissionForm',
                htmlType: 'submit'
            }}
            onCancel={() => onCancel()}
        >
            <Form form={form} id="commissionForm" onFinish={async (v: any) => {
                let res;
                if (props.data.data) {
                    res = await service.update({
                        ...v,
                        comission_id: props.data.data.comission_id
                    })
                } else {
                    res = await service.create(v);
                }

                if (!res || !res.result) return;
                onDone()
            }}>
                <Row gutter={16}>
                    {formItems}
                </Row>
            </Form>
        </Modal>
    )
}