import { Form, Modal, Row } from "antd";
import { useUserService } from "../../services/user/user.service";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/lib/form/Form";
import { useCallback, useEffect } from "react";
import { useCreateFormItems } from "../../helpers/form";
import { UserCreateReqModel } from "../../services/user/user";
import { IDatePicker, StyledInput, StyledNumberInput, StyledSelect } from "../../components/form";
import { ComponentTypes, GenderEnum } from "../../models/system";
import { EnumToSelectOptions } from "../../helpers/utils";
import { useStore } from "../../store";

interface NewUserModalProps {
    open: boolean;
    onDone(): void;
    onCancel(): void;
}
export const NewUserModal: React.FC<NewUserModalProps> = props => {

    const service = useUserService();
    const { t } = useTranslation();
    const store = useStore();
    const [form] = useForm();

    useEffect(() => {
        if (props.open) {
            store.getLanguages();
        }
        // eslint-disable-next-line
    }, [props.open])

    const onReset = useCallback(() => {
        form.resetFields();
    }, [form])

    const onCancel = useCallback(() => {
        onReset();
        props.onCancel()
    }, [onReset, props])

    const onDone = useCallback(() => {
        onReset();
        props.onDone()
    }, [onReset, props])

    const onSubmit = useCallback(async (val: any) => {
        const res = await service.add({
            ...val
        });
        if (!res.result) return;
        onDone()
    }, [service, onDone])

    const formItems = useCreateFormItems<UserCreateReqModel>([
        {
            formItem: {
                name: 'name',
                label: 'Name',
                required: true
            },
            colProps: {
                span: 12
            },
            element: <StyledInput />,
            elementType: ComponentTypes.Input
        },
        {
            formItem: {
                name: 'surname',
                label: 'Surname',
                required: true
            },
            colProps: {
                span: 12
            },
            element: <StyledInput />,
            elementType: ComponentTypes.Input
        },
        {
            formItem: {
                name: 'username',
                label: 'Username',
                required: true
            },
            colProps: {
                span: 12
            },
            element: <StyledInput />,
            elementType: ComponentTypes.Input
        },
        {
            formItem: {
                name: 'countryCode',
                label: 'CountryCode',
                required: true
            },
            colProps: {
                span: 12
            },
            element: <StyledNumberInput maxLength={3} />,
            elementType: ComponentTypes.Input
        },
        {
            formItem: {
                name: 'phone',
                label: 'PhoneNumber',
                required: true
            },
            colProps: {
                span: 12
            },
            element: <StyledNumberInput maxLength={10} />,
            elementType: ComponentTypes.Input
        },
        {
            formItem: {
                name: 'email',
                label: 'Email',
                required: true
            },
            colProps: {
                span: 12
            },
            element: <StyledInput />,
            elementType: ComponentTypes.InputEmail
        },
        {
            formItem: {
                name: 'birthday',
                label: 'Birthday',
                required: true
            },
            colProps: {
                span: 12
            },
            element: <IDatePicker />,
            elementType: ComponentTypes.Input
        },
        {
            formItem: {
                name: 'gender',
                label: 'Gender',
                required: true
            },
            colProps: {
                span: 12
            },
            element: <StyledSelect options={EnumToSelectOptions(GenderEnum, { languageKeyPrefix: 'Genders' })} />,
            elementType: ComponentTypes.Input
        },
        {
            formItem: {
                name: 'display_language',
                required: true,
                label: 'Language'
            },
            colProps: {
                span: 12
            },
            element: <StyledSelect options={store.data.languages.map(e => ({ label:`${e.language.toUpperCase()} - ${e.code.toUpperCase()}`, value: e.code }))} />,
            elementType: ComponentTypes.Input
        },

    ])

    return (
        <Modal
            open={props.open}
            title={t('NewUser')}
            onCancel={() => onCancel()}
            okButtonProps={{
                form: 'newUserForm',
                htmlType: 'submit'
            }}
        >
            <Form id="newUserForm" form={form} onFinish={(v) => onSubmit(v)}>
                <Row gutter={24}>
                    {formItems}
                </Row>
            </Form>
        </Modal>
    )
}