import { useCallback, useEffect, useState } from "react";
import { PageCard } from "../../components/pageCard"
import { useBookService } from "../../services/book/book.service"
import { BookSearchGoogleReqModel, BookSearchGoogleResModel } from "../../services/book/book";
import { PaginationResDto, PaginationResModel } from "../../models/system/http";
import { Button, Col, Form, Image, Row, Tag, Tooltip } from "antd";
import { useCreateFormItems } from "../../helpers/form";
import { useStore } from "../../store";
import { StyledInput, StyledSelect } from "../../components/form";
import { ComponentTypes } from "../../models/system";
import { useForm } from "antd/lib/form/Form";
import { ITable } from "../../components/table/table";
import { IColumnProps } from "../../components/table";
import { t } from "i18next";
import { PlusOutlined } from "@ant-design/icons";
import { useModal } from "../../hooks/modal";
import { useNotification } from "../../hooks/notification";

export const NewBook: React.FC = props => {
    const service = useBookService();
    const store = useStore();
    const modal = useModal();
    const notification = useNotification()
    const [form] = useForm();
    const [state, setState] = useState<PaginationResModel<BookSearchGoogleResModel>>(PaginationResDto());
    const [filter, setFilter] = useState<BookSearchGoogleReqModel>({
        limit: 35, page: 1,
        query: ''
    });
    const [lang, setLang] = useState("");

    useEffect(() => {
        store.getLanguages();
        // eslint-disable-next-line
    }, [])

    const onSearch = useCallback(async () => {
        if (!filter.query.length) return;
        if (!lang.length) return;
        const res = await service.searcFromGoogle(filter, lang);
        if (!res.result) return;
        setState(res);
    }, [filter, lang, setState, service])

    useEffect(() => {
        onSearch();
        // eslint-disable-next-line
    }, [filter])

    const columns: IColumnProps<BookSearchGoogleResModel>[] = [
        {
            dataIndex: 'image',
            title: 'Image',
            render: (v) => <Image width={40} height={60} src={v} />
        },
        {
            dataIndex: 'book_name',
            title: 'BookName'
        },
        {
            dataIndex: 'author',
            title: 'Author'
        },
        {
            dataIndex: 'description',
            title: 'Description',
            render: (v) => {
                const desc = String(v);
                if (desc.length < 50) return desc;
                return <Tooltip placement="left" title={v}>{String(v).substring(0, 50) + "..."}</Tooltip>
            }
        },
        {
            dataIndex: 'categories',
            title: 'Categories',
            render: (v, r) => r.categories.map(e => <Tag title={e} color="blue" >{e}</Tag>)
        },
        {
            dataIndex: 'author',
            actionColumn: {
                items: (row) => [
                    {
                        key: 'add',
                        label: t('Add'),
                        icon: <PlusOutlined />,
                        onClick: () => {
                            modal.confirm({
                                content: t('ApproveAddBook', { value: row.book_name }),
                                onOk: async () => {
                                    const res = await service.add(row);
                                    if (!res.result) return;
                                    notification.success({
                                        message: t('SuccessAddedBook')
                                    })
                                }
                            })
                        }
                    }
                ]
            }
        }
    ]

    return (
        <>
            <PageCard>
                <Form form={form} onFinish={(v) => {
                    setFilter(p => ({ ...p, query: v.query }))
                }} >
                    <Row gutter={24} align={"bottom"}>
                        {useCreateFormItems([
                            {
                                colProps: {
                                    span: 5
                                },
                                element: <StyledSelect onSelect={(v) => setLang(String(v))} options={store.data.languages.map(e => ({ label:`${e.language.toUpperCase()} - ${e.code.toUpperCase()}`, value: e.code }))} />,
                                elementType: ComponentTypes.Input,
                                formItem: {
                                    label: 'Language',
                                    name: 'lang',
                                    required: true
                                }
                            },
                            {
                                colProps: {
                                    span: 5
                                },
                                element: <StyledInput />,
                                elementType: ComponentTypes.Input,
                                formItem: {
                                    label: 'Query',
                                    name: 'query',
                                    required: true
                                }
                            },
                        ])}
                        <Col span={4}> <Form.Item labelCol={{ span: 24 }}><Button htmlType="submit" >{t('List')}</Button></Form.Item></Col>
                    </Row>
                </Form>
            </PageCard>

            <ITable
                data={state}
                columns={columns}
                onPageChange={(page, size) => setFilter(p => ({ ...p, page: page, limit: size }))}
            />
        </>
    )
}