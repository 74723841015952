import { useCallback } from "react";
import { useHttp } from "../../hooks/http"
import { InteresetsUpdateReqModel, InterestAddPopularReqModel, InterestGetByIdModel, InterestPopularListResModel, InterestUpdatePriorityReqModel, InterestsCreateReqModel, InterestsGetAllResModel, InterestsModel } from "./interest";

export const useInterestService = () => {
    const http = useHttp();

    const getAll = useCallback(async () => {
        return await http.get<{ data: InterestsGetAllResModel[] }>('/interests');
    }, [http])

    const create = useCallback(async (req: InterestsCreateReqModel) => {
        return await http.post<InterestsCreateReqModel>('/interest/add', req);
    }, [http]);

    const getByLanguage = useCallback(async (language: string) => {
        return await http.get<InterestsModel>(`/interests/${language}`);
    }, [http]);

    const update = useCallback(async (req: InteresetsUpdateReqModel) => {
        return await http.put<InteresetsUpdateReqModel>(`/interests/${req.interest_id}`, req)
    }, [http]);

    const remove = useCallback(async (id: number) => {
        return await http.delete(`/interest/${id}`);
    }, [http]);

    const getById = useCallback(async (id: number) => {
        return await http.get<{ data: InterestGetByIdModel }>(`/interest/${id}`);
    }, [http])

    const getPopularList = useCallback(async () => {
        return await http.get<{ data: InterestPopularListResModel[] }>('/interests/popular/get');
    }, [http])

    const getPopularListByLang = useCallback(async (lang: string) => {
        return await http.get<{ data: InterestPopularListResModel[] }>(`/interests/popular/get/${lang}`);
    }, [http])

    const addPopular = useCallback(async (req: InterestAddPopularReqModel) => {
        return await http.post('/interests/popular', req);
    }, [http])

    const removePopular = useCallback(async (id: number) => {
        return await http.delete(`/interests/popular/${id}/delete`);
    }, [http])

    const updatePopularPriority = useCallback(async (req: InterestUpdatePriorityReqModel) => {
        return await http.post('/interests/popular/update', req);
    }, [http])


    return {
        getAll,
        getById,
        create,
        getByLanguage,
        update,
        remove,
        getPopularList,
        getPopularListByLang,
        addPopular,
        removePopular,
        updatePopularPriority,
    }
}