import { useCallback } from "react";
import { useHttp } from "../../hooks/http"
import { AgreementAddUpdateModel, AgreementCreateReqModel, AgreementGet } from "./agreement";

export const useAgreementService = () => {
  const http = useHttp();

  const getAll = useCallback(async () => {
    return await http.get<{ data: AgreementGet[] }>('/user-agreement');
  }, [http])



  const create = useCallback(async (req: AgreementCreateReqModel) => {
    return await http.post<AgreementCreateReqModel>('/agreement', req);
  }, [http])

  const getByLang = useCallback(async (lang: string) => {
    return await http.get<{ data: AgreementGet[] }>(`/user-agreement/get/${lang}`);
  }, [http])

  const add = useCallback(async (req: AgreementAddUpdateModel) => {
    return await http.post('user-agreement/add', req);
  }, [http])

  const update = useCallback(async (req: AgreementAddUpdateModel) => {
    return await http.post('user-agreement/update', req);
  }, [http])

  const remove = useCallback(async (agreement_id: number) => {
    return await http.post('user-agreement/delete', { ua_id: agreement_id })
  }, [http])



  return {
    getAll,
    getByLang,
    update, remove,
    create,
    add,
  }
}