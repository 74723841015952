import { useCallback } from "react";
import { useHttp } from "../../hooks/http"
import { BannerCreateReqModel, BannerGetByLangResModel, BannerUpdateReqModel } from "./banner";

export const useBannerService = () => {
    const http = useHttp();

    const getAll = useCallback(async () => {
        return await http.get('/banners/language/en');
    }, [http])

    const create = useCallback(async (req: BannerCreateReqModel) => {
        return await http.post('/banner/add', req);
    }, [http])

    const update = useCallback(async (req: BannerUpdateReqModel, id: number) => {
        return await http.put(`/banner/${id}`, req);
    }, [http])

    const remove = useCallback(async (id: number) => {
        return await http.delete(`/banner/${id}`);
    }, [http])

    const getListByLanguage = useCallback(async (lang: string) => {
        return await http.get<{ data: BannerGetByLangResModel[] }>(`/banners/language/${lang}`)
    }, [http])

    const deActive = useCallback(async (id: number) => {
        return await http.post('/banners/deactive', {
            banner_id: id
        })
    }, [http])

    const active = useCallback(async (id: number) => {
        return await http.post('/banners/active', {
            banner_id: id
        })
    }, [http])

    return {
        getAll,
        create,
        update,
        getListByLanguage,
        remove,
        active,
        deActive
    }
}