import { Button, Card, Col, Form, Modal, Row, Slider } from "antd"
import axios from "axios";
import { useCallback, useMemo, useState } from "react";
import { useStore } from "../store";
import { useCreateFormItems } from "../helpers/form";
import { ComponentTypes } from "../models/system";
import { useForm } from "antd/lib/form/Form";
import { StyledSelect } from "./form";
import { GoogleTextToSpeechConfigParamsResources } from "./googleTextToSpeech/resources/resources";
import { IColumnProps } from "./table";
import { CheckCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { ITable } from "./table/table";
import { useTranslation } from "react-i18next";
import { useLoading } from "../hooks/loading";
import TextArea from "antd/es/input/TextArea";

interface SpeechInfo {
    content: string;
    effect: string;
    pitch: number;
    speakingRate: string;
    language: string;
    voiceName: string;
    gender: string;
}

interface GoogleTextToSpeechModalPros {
    onSelect(file: any): void;
    onClose(): void;
    open: boolean;
}

export const GoogleTextToSpeechModal: React.FC<GoogleTextToSpeechModalPros> = (props) => {
    const endPoint = `https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=${process.env.REACT_APP_GOOGLETEXTTOSPEECHKEY}`
    const store = useStore();
    const [form] = useForm();
    const [resultList, setResultList] = useState<SpeechInfo[]>([]);
    const [langCode, setLangCode] = useState<null | string>("tr-TR");
    const { t } = useTranslation();
    const { addLoading, removeLoading } = useLoading()

    const addResult = useCallback((info: SpeechInfo) => {
        setResultList(p => [...p, info]);
    }, [setResultList])

    const createSpeech = useCallback(async (value: any) => {
        addLoading('google-text-to-speech')
        const payload = {
            "audioConfig": {
                "audioEncoding": "MP3",
                "effectsProfileId": [
                    value.effectsProfileId
                ],
                "pitch": value.pitch,
                "speakingRate": value.speakingRate,

            },
            "input": {
                "text": value.text
            },
            "voice": {
                "languageCode": value.country,
                "name": value.voiceName,
                "ssmlGender": value.voiceGender
            }
        }

        const res = await axios.post(endPoint, payload).then(e => e.data).catch(e => false);
        removeLoading('google-text-to-speech')
        addResult({
            content: res.audioContent,
            effect: value.effectsProfileId,
            gender: value.voiceGender,
            language: value.country,
            pitch: value.pitch,
            speakingRate: value.speakingRate,
            voiceName: value.voiceName
        });
    }, [endPoint, addResult, addLoading, removeLoading])

    const voiceName = useMemo(() => {
        if (!langCode) return;
        const selected = GoogleTextToSpeechConfigParamsResources.languageSettings.find(e => e.langCode === langCode);
        if (!selected) return [];
        return selected.name.map(e => ({ label: e, value: e }));
    }, [langCode])

    const voiceGender = useMemo(() => {
        if (!langCode) return;
        const selected = GoogleTextToSpeechConfigParamsResources.languageSettings.find(e => e.langCode === langCode);
        if (!selected) return [];
        return selected.ssmlGender.map(e => ({ label: e, value: e }));
    }, [langCode])

    const onReset = useCallback(() => {
        setResultList([])
        form.resetFields()
    }, [setResultList, form])

    const onSelect = useCallback((value: string) => {

        var binaryString = window.atob(value);
        var bytes = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        var blob = new Blob([bytes]);

        var file = new File([blob], "speech.mp3", { type: "audio/mp3" });
        props.onSelect(file);
        onReset()
    }, [props,onReset])

    const formItems = useCreateFormItems([
        {
            colProps: {
                span: 4,
            },
            element: <StyledSelect
                showSearch
                optionFilterProp="label"
                onSelect={(v) => setLangCode(String(v))}

                options={GoogleTextToSpeechConfigParamsResources.languageSettings.map(e => ({ label: e.country, value: e.langCode }))} />,
            elementType: ComponentTypes.Input,
            formItem: {
                name: 'country',
                label: 'Language',
                required: true,
                initialValue: 'tr-TR'
            }
        },
        {
            colProps: {
                span: 4,
            },
            element: <StyledSelect options={voiceName} />,
            elementType: ComponentTypes.Input,
            formItem: {
                name: 'voiceName',
                label: 'VoiceName',
                required: true,
                initialValue: 'tr-TR-Wavenet-D'
            },
        },
        {
            colProps: { span: 3 },
            element: <StyledSelect options={voiceGender} />,
            elementType: ComponentTypes.Input,
            formItem: {
                name: 'voiceGender',
                label: 'Gender',
                required: true,
                initialValue: 'MALE'
            }
        },
        {
            colProps: {
                span: 6
            },
            element: <StyledSelect options={GoogleTextToSpeechConfigParamsResources.effectsProfileIds.map(e => ({ label: e, value: e }))} />,
            elementType: ComponentTypes.Input,
            formItem: {
                name: 'effectsProfileId',
                label: 'EffectsProfile',
                initialValue: 'medium-bluetooth-speaker-class-device',
                required: true
            }
        },

        {
            colProps: {
                span: 6,
                md: { span: 12 }
            },
            element: <Slider
                min={-20}
                max={20}
                defaultValue={0}
                step={1}
            />,
            elementType: ComponentTypes.Input,
            formItem: {
                name: 'pitch',
                label: 'Pitch',
                required: true,
                initialValue: 0
            }
        },
        {
            colProps: {
                span: 6,
                md: { span: 12 }
            },
            element: <Slider
                min={0.25}
                max={4}
                step={0.01}
                defaultValue={1}
            />,
            elementType: ComponentTypes.Input,
            formItem: {
                name: 'speakingRate',
                label: 'SpeakingRate',
                required: true,
                initialValue: 1
            }

        },
        {
            colProps: {
                span: 24
            },
            element: <TextArea style={{ height: 300 }} />,
            elementType: ComponentTypes.Input,
            formItem: {
                label: 'Writing',
                name: 'text',
                required: true
            }
        },

    ]);

    const columns: IColumnProps<SpeechInfo>[] = [
        {
            dataIndex: 'language',
            title: 'Language'
        },
        {
            dataIndex: 'voiceName',
            title: 'VoiceName'
        },
        {
            dataIndex: 'gender',
            title: 'Gender'
        },
        {
            dataIndex: 'effect',
            title: 'EffectProfile'
        },
        {
            dataIndex: 'pitch',
            title: 'Pitch',
        },
        {
            dataIndex: 'speakingRate',
            title: 'SpeakingRate'
        },
        {
            dataIndex: 'content',
            title: 'Voice',
            render: (v) => <Button onClick={() => store.setSound(`data:audio/mp3;base64,${v}`)}><PlayCircleOutlined /></Button>
        },
        {
            dataIndex: 'content',
            title: 'Select',
            render: (v) => <Button onClick={() => onSelect(v)} type="primary"><CheckCircleOutlined /></Button>
        }
    ]

    return (
        <>
            <Modal
                title={t('GoogleTextSpeech')}
                open={props.open}
                onCancel={() => props.onClose()}
                okButtonProps={{ hidden: true }}
                width={"70vw"}
            >
                <Form form={form} onFinish={(v) => createSpeech(v)} >
                    <Card>
                        <Row gutter={[16, 16]}>
                            {formItems}
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Form.Item labelCol={{ span: 24 }}>
                                    <Button htmlType="submit" type="primary">Seslendir</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <ITable
                                    columns={columns}
                                    dataSource={resultList}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Form>
            </Modal>
        </>
    )
}