import { useCallback } from "react";
import { useHttp } from "../../hooks/http"
import { WithdrawalApproveReqModel, WithdrawalDeclineReqModel, WithdrawalGetAllListResModel, WithdrawalWaitingListResModel } from "./withdrawal";

export const useWithdrawalService = () => {
    const http = useHttp();

    const getWaitingList = useCallback(async () => {
        return await http.get<{ data: WithdrawalWaitingListResModel[] }>('/withdrawal/waiting');
    }, [http])

    const getAllList = useCallback(async () => {
        return await http.get<{ data: WithdrawalGetAllListResModel[] }>('/withdrawal/all');
    }, [http])

    const approve = useCallback(async (req: WithdrawalApproveReqModel) => {
        return await http.post('/withdrawal/approve', req);
    }, [http])

    const decline = useCallback(async (req: WithdrawalDeclineReqModel) => {
        return await http.post('/withdrawal/decline', req);
    }, [http])

    const withdrawListGetForUser = useCallback(async (userId: number) => {
        return await http.get<{ data: WithdrawalWaitingListResModel[] }>(`/withdrawal/user/${userId}`);
    }, [http])

    return {
        getWaitingList,
        getAllList,
        approve,
        decline,
        withdrawListGetForUser
    }
}