import { FlagOutlined, KeyOutlined, LogoutOutlined, MenuOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Badge, Breadcrumb, Drawer, Form, Grid, Layout, Menu, Modal, Row, Select, theme } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import React, { createElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation, } from 'react-i18next';
import { Outlet, useLocation, useMatches, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { useConfig } from '../../hooks/config';
import { useTheme } from '../../hooks/theme';
import { ComponentTypes, UseMatchesDto } from '../../models/system';
import { dashboardRoutes, mainRouteList } from '../../routes/routes';
import { IRouteProp } from '../../routes/type';
import { AuthGuard } from './authGuard';
import { EnumToSelectOptions } from '../../helpers/utils';
import { ThemeModeEnum } from '../../context/theme';
import { Moon2, Sun, SunMoon } from 'tabler-icons-react';
import { BreadcrumbItemType, BreadcrumbSeparatorType } from 'antd/es/breadcrumb/Breadcrumb';
import { Player } from '../player';
import { useStore } from '../../store';
import { AdminType, AdminUpdatePasswordReqModel } from '../../services/admin/admin';
import { useForm } from 'antd/lib/form/Form';
import { useAdminService } from '../../services/admin/admin.service';
import { useCreateFormItems } from '../../helpers/form';
import { StyledInput } from '../form';



export const Main: React.FC = (props) => {
    const { toggleThemeMode, mode, themeMode } = useTheme();
    const { clearAuth, authData } = useAuth();
    const navigate = useNavigate();
    const location = useLocation()
    const config = useConfig();
    const { t, i18n } = useTranslation();
    const breakpoints = Grid.useBreakpoint();

    const { token } = theme.useToken();
    const breadCrumbRoutes = useMatches().filter(m => m.id !== 'layout') as UseMatchesDto[];

    const [activeKey, setActiveKeys] = useState<string[]>([]);
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [mouseEnterSidebar, setMouseEnterSidebar] = useState<boolean>(false);
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false)
    const [rightDrawerVisible, setRightDrawerVisible] = useState<boolean>(false)
    const store = useStore();

    const updateActiveKeys = useCallback(async () => {
        setActiveKeys(p => breadCrumbRoutes.map(e => e.id));
    }, [breadCrumbRoutes, setActiveKeys])

    const breadCrumbItems = useMemo((): Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] => {
        return breadCrumbRoutes.filter(i => i.handle).map(item => ({
            title: <>
                {item.handle.icon ? createElement(item.handle.icon) : null}
                <span style={{ padding: '0px 5px' }}>{t(`Navigation.${item.handle.title}`)}</span>
            </>,

        }))
    }, [breadCrumbRoutes, t])

    useEffect(() => {
        updateActiveKeys();

        // eslint-disable-next-line
    }, [setActiveKeys, location.pathname])

    useEffect(() => {
        store.getReportedBooks();
        store.getReportedUsers();
        // eslint-disable-next-line
    }, [])

    const menuKeys = useMemo(() => {
        const list = breadCrumbRoutes.map(e => e.id).concat(activeKey);
        if (collapsed && !mouseEnterSidebar) {
            return list.filter(item => mainRouteList.find(r => r.id === item && r.path))
        }
        return list;
    }, [activeKey, collapsed, breadCrumbRoutes, mouseEnterSidebar])

    const createLabel = useCallback((route: IRouteProp) => {
        if (!route.handle || !route.handle.title) return null;
        switch (route.id) {
            case 'report-book':
                return <Badge offset={[20, 5]} count={store.data.reportedBookList.length} >
                    {t('Navigation.' + route.handle.title)}
                </Badge>
            case 'report-user':
                return <Badge offset={[20, 5]} count={store.data.reportedUserList.length} >
                    {t('Navigation.' + route.handle.title)}
                </Badge>
            case 'reportslist':
                return <Badge offset={[20, 5]} count={store.data.reportedUserList.length + store.data.reportedBookList.length} >
                    {t('Navigation.' + route.handle.title)}
                </Badge>
            default:
                return t('Navigation.' + route.handle.title)
        }
    }, [store, t])

    const createMenuItems = useCallback((routes: IRouteProp[] = dashboardRoutes()): any[] => {
        // eslint-disable-next-line
        return routes.filter(route => route.handle && !route.handle.noMenu).filter(r => {
            if (!r.adminTypes || !authData) return r;
            if (authData.admin_type === AdminType.super) return r;

            if (r.adminTypes.includes(authData.admin_type)) return r;
        }).map(item => {
            const route = { ...item };
            delete route.children;
            if (item.children) {
                return {
                    key: item.id,
                    label: createLabel(item),
                    icon: item.handle && item.handle.icon && createElement(item.handle.icon),
                    children: createMenuItems([...item.children.filter(cRoute => cRoute.handle && !cRoute.handle.noMenu)])
                }
            } else {
                return {
                    key: item.id,
                    icon: item.handle && item.handle.icon && createElement(item.handle.icon),
                    onClick: () => {
                        item.path && navigate(item.path)
                    },
                    label: createLabel(item),
                    type: item.children ? 'group' : undefined,
                }
            }
        })
    }, [navigate, createLabel, authData]);

    const sideMenuItems: ItemType[] = useMemo(() => {
        return createMenuItems();
    }, [createMenuItems])

    const NavigationMenu = () => <Menu
        theme={themeMode}
        mode="inline"
        selectedKeys={menuKeys}
        onClick={() => breakpoints.xs && setDrawerVisible(p => !p)}
        openKeys={menuKeys}
        style={{ height: '100%', borderRight: 0, background: breakpoints.xs ? token.colorBgContainer : token.colorPrimaryBg }}
        items={sideMenuItems}
        onMouseEnter={() => setMouseEnterSidebar(true)}
        onMouseLeave={() => setMouseEnterSidebar(false)}
        onOpenChange={(keys) => setActiveKeys(keys)}
    />

    const headerMenuItems: ItemType[] = useMemo(() => {
        const items: ItemType[] = [
            {
                icon: <SettingOutlined />,
                key: 'setting',
                label: '',
                onClick: () => setRightDrawerVisible(true)
            },
            {
                key: 'user',
                icon: <UserOutlined />,
                children: [
                    {
                        key: 'updatePwd',
                        label: t('UpdatePassword'),
                        icon: <KeyOutlined />,
                        onClick: () => setPasswordModal(true)
                    },
                    {
                        key: 'logout',
                        label: t('System.Logout'),
                        icon: <LogoutOutlined />,
                        onClick: () => clearAuth()
                    },

                ]
            }
        ];
        if (breakpoints.xs) {
            items.push({
                key: 'drawer-btn',
                icon: <MenuOutlined />,
                onClick: () => setDrawerVisible(p => !p)
            })
        }

        return items;
    }, [breakpoints, setDrawerVisible, clearAuth, t])

    const [pwdForm] = useForm();
    const adminService = useAdminService();
    const [passwordModal, setPasswordModal] = useState(false);

    const onFinishPwdForm = useCallback(async (val: AdminUpdatePasswordReqModel) => {
        const res = await adminService.updatePassword(val);
        if (!res.result) return;
        pwdForm.resetFields()
        setPasswordModal(false);
        clearAuth();
    }, [adminService, setPasswordModal,clearAuth, pwdForm])

    const pwdFormItems = useCreateFormItems<AdminUpdatePasswordReqModel>([
        // {
        //     formItem: {
        //         label: 'Username',
        //         name: 'username'
        //     },
        //     colProps: {
        //         span: 24
        //     },
        //     element: <StyledInput />,
        //     elementType: ComponentTypes.Input
        // },

        {
            formItem: {
                label: 'old_password',
                name: 'old_password'
            },
            colProps: {
                span: 24
            },
            element: <StyledInput.Password />,
            elementType: ComponentTypes.InputPassword
        },

        {
            formItem: {
                label: 'new_password',
                name: 'new_password'
            },
            colProps: {
                span: 24
            },
            element: <StyledInput.Password />,
            elementType: ComponentTypes.InputPassword
        },
    ])

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header className="header" style={{ display: 'flex', justifyContent: 'space-between', background: themeMode === 'light' ? token.colorPrimary : token.colorPrimaryBg }}>
                <div
                    style={{
                        width: '270px',
                        marginLeft: -37,
                    }}
                >
                    <img src="/logo.svg" style={{
                        filter: ' contrast(7) invert()',
                        width: '100%', maxWidth: '220px', objectFit: 'contain'
                    }} alt={process.env.REACT_APP_NAME} />
                </div>
                <Menu
                    theme={themeMode}
                    mode="horizontal"
                    triggerSubMenuAction='click'
                    selectedKeys={[
                        "language",
                        `lang-${i18n.language}`
                    ]}
                    style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'end',
                        background: themeMode === 'light' ? token.colorPrimary : token.colorPrimaryBg,
                        color: themeMode === 'light' ? 'white' : 'white',
                    }}
                    items={headerMenuItems}
                />
            </Header>
            <Layout>
                <Drawer
                    placement='left'
                    open={drawerVisible}
                    onClose={() => setDrawerVisible(false)}
                    title={t('Menu')}
                >
                    {NavigationMenu()}
                </Drawer>
                <Drawer
                    placement='right'
                    open={rightDrawerVisible}
                    onClose={() => setRightDrawerVisible(false)}
                    title={t('System.Settings')}
                >
                    <Form>
                        <Form.Item labelCol={{ span: 24 }} label={t('System.Language')}>
                            <Select
                                value={i18n.language}
                                onSelect={(v) => i18n.changeLanguage(v)}
                                suffixIcon={<FlagOutlined />}
                                options={config.languages.map(langKey => ({
                                    key: `lang-${langKey}`,
                                    label: langKey.toUpperCase(),
                                    value: langKey
                                }))} />
                        </Form.Item>
                        <Form.Item labelCol={{ span: 24 }} label={t('System.ThemeSetting')}>
                            <Select
                                value={mode}
                                onChange={(value) => toggleThemeMode(value as ThemeModeEnum)}
                                options={EnumToSelectOptions(ThemeModeEnum, {
                                    customLabel: (value, key) => {
                                        switch (key) {
                                            case ThemeModeEnum.Dark:
                                                return <><Moon2 /> {value}</>;
                                            case ThemeModeEnum.Light:
                                                return <><Sun /> {value}</>;
                                            case ThemeModeEnum.Dynamic:
                                                return <><SunMoon /> {value}</>;
                                        }
                                    },
                                    languageKeyPrefix: 'System'
                                })}
                            />
                        </Form.Item >
                    </Form>
                </Drawer>
                <Sider
                    width={270}
                    hidden={breakpoints.xs}
                    collapsible
                    theme={themeMode}
                    collapsed={mouseEnterSidebar ? false : collapsed}
                    onCollapse={(value) => setCollapsed(value)}
                >
                    {NavigationMenu()}
                </Sider>
                <Layout style={{ padding: '0 24px 24px' }}>
                    <Breadcrumb style={{ margin: '16px 0' }} items={breadCrumbItems} />
                    <Content
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                            background: token.colorFillContent
                        }}
                    >
                        <AuthGuard>
                            <Outlet />
                        </AuthGuard>
                    </Content>
                </Layout>
            </Layout>
            <Player />
            <Modal
                open={passwordModal}
                title={t('UpdatePassword')}
                okButtonProps={{
                    htmlType: 'submit',
                    form: 'pwdForm'
                }}
                onCancel={()=>{
                    pwdForm.resetFields();
                    setPasswordModal(false)
                }}
            >
                <Form id="pwdForm" form={pwdForm} onFinish={v => onFinishPwdForm(v)}>
                    <Row gutter={24}>
                        {pwdFormItems}
                    </Row>
                </Form>
            </Modal>
        </Layout>
    )
}