import { Button, Card, Dropdown, Form, Grid, Space, Table, TableProps, Tag } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IColumnProps } from '.';
import dayjs from 'dayjs';
import { PaginationResModel } from '../../models/system/http';
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, MoreOutlined, PlusOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { useModal } from '../../hooks/modal';
import { YesOrNoEnum } from '../../models/system';
import { TableCell } from './tableComponents';
import { useForm } from 'antd/es/form/Form';
import StarsRating from 'react-star-rate';
import { useStore } from '../../store';
import { StyledInput } from '../form';
import { secondFormat } from '../../helpers/utils';

interface ITableProps extends Omit<TableProps<any>, 'columns'> {
    data?: PaginationResModel<any>;
    onPageChange?(page: number, size: number): void;
    columns: IColumnProps<any>[];
    onSaveRow?(data: any): void;
    newRow?: boolean;
    withOutCard?: boolean;
}


const CardWrapper = (_props: { children: React.ReactNode, withOutCard?: boolean, onNewRow?(): void }): any => {
    if (_props.withOutCard) return _props.children;

    return (
        <Card
            extra={_props.onNewRow && <Button onClick={() => _props.onNewRow && _props.onNewRow()}><PlusOutlined /></Button>}
        >
            {_props.children}
        </Card>
    )
}

export const ITable: React.FC<ITableProps> = props => {

    const { t } = useTranslation()
    const modal = useModal();
    const [form] = useForm();
    const store = useStore();
    const [, setSearchedColumn] = useState('');

    const [state, setState] = useState<any[]>([]);

    // const data = useMemo(() => {
    //     return props.data ? props.data.search_results : props.dataSource ? props.dataSource : [];
    // }, [props.data, props.dataSource])

    const [editingData, setEditingData] = useState<any>(null);

    const onEdit = useCallback((row: any) => {

        form.setFieldsValue(row);
        setEditingData(JSON.stringify(row));
    }, [form, setEditingData])

    const onSave = useCallback(async () => {
        await form.validateFields();
        const error = form.getFieldsError();
        if (error.find(e => e.errors.length)) return;

        if (props.onSaveRow) {
            const values = {
                ...JSON.parse(editingData),
                ...form.getFieldsValue()
            }
            props.onSaveRow(values);
        }
        setEditingData(null);
        form.resetFields();
    }, [form, setEditingData, props, editingData])

    useEffect(() => {
        const d = props.data ? props.data.search_results : props.dataSource ? [...props.dataSource] : [];
        setState(d);
    }, [props.data, props.dataSource])

    useEffect(() => {
        store.getAllTiers();
        return () => {
            form.resetFields();
            setState([]);
        }
        // eslint-disable-next-line
    }, [])

    const isEditable = useCallback((row: any) => {
        return JSON.stringify(row) === editingData
    }, [editingData])

    const onRemoveRow = useCallback((row: any) => {
        setState(p => p.filter((v, i) => v !== row));
    }, [setState])

    const handleSearch = useCallback((selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchedColumn(dataIndex);
    }, [setSearchedColumn])

    const handleReset = useCallback((clearFilters: any) => {
        clearFilters();
        setSearchedColumn('');

    }, [setSearchedColumn])

    const columns = useMemo(() => {
        const cols = props.columns.map(column => {
            column.key = column.dataIndex
            if (column.title) {
                column.title = t(column.title).toString()
            }

            if (column.isRate) {
                column.width = '230px';
                column.render = (v) => <StarsRating
                    value={v}
                    disabled
                    style={{
                        style: {
                            width: '300px',
                        },
                        full: {
                            star: {
                                margin: 0,
                                width: '24px',
                                height: '24px',
                                fontSize: '24px',
                            },
                        },
                        half: {
                            star: {
                                width: '24px',
                                margin: 0,
                                height: '24px',
                                fontSize: '24px',
                            }
                        },
                        zero: {
                            star: {
                                width: '24px',
                                margin: 0,
                                height: '24px',
                                fontSize: '24px',
                            }
                        },
                        hover: {

                        }

                    }}
                />
            }

            if (!column.width) {
                column.width = `${100 / props.columns.length}%`
            }

            if (column.isDate) {
                // column.sorter = (a, b) => dayjs(a[column.dataIndex]).unix() >= dayjs(b[column.dataIndex]).unix() ? 1 : 0
                column.render = (v) => v === null ? "" : dayjs(v).format(column.dateFormat || 'DD/MM/YYYY').toString();
            }

            if (column.isTier) {
                column.render = (v) => {
                    // const tier = store.data.tierList.find(e => e.tier === v);
                    if (v === 0) return t('Free');
                    return v;
                }
            }

            if (column.isTag) {
                column.render = (v) => <Tag color='blue' >{v}</Tag>
            }

            if (column.actionColumn) {
                const { hasDelete, hasUpdate, items } = column.actionColumn;
                column.align = "right";
                column.render = (value, record, index) => {
                    const _items: ItemType[] = [];

                    if (hasDelete) {
                        _items.push({
                            label: t('Delete'),
                            danger: true,
                            icon: <DeleteOutlined />,
                            onClick: () => {
                                modal.confirm({
                                    content: t('ConfirmDelete', { val: hasDelete.messageProp ? `${record[hasDelete.messageProp]}` : '' }),
                                    title: t('Warning'),
                                    onOk: () => hasDelete.callback(record[hasDelete.propName])
                                })
                            },
                            key: 'delete'
                        })
                    }

                    if (hasUpdate) {
                        _items.push({
                            label: t('Update'),
                            icon: <EditOutlined />,
                            onClick: () => hasUpdate.propName && hasUpdate.callback(record[hasUpdate.propName], record),
                            key: 'update'
                        })
                    }

                    if (items) {
                        items(record).forEach((e: any) => {
                            e.label = t(e.label);
                            _items.push(e)
                        });
                    }

                    return (
                        <Dropdown menu={{
                            items: _items
                        }}>
                            <Button>
                                <MoreOutlined />
                            </Button>
                        </Dropdown>
                    )
                }
            }

            if (column.isYesOrNo) {
                column.align = "center";
                column.render = (v, r) => <Tag color={v === YesOrNoEnum.Yes ? 'green' : 'red'} children={v === YesOrNoEnum.Yes ? <CheckOutlined /> : <CloseOutlined />} />
            }

            if (column.editable) {
                return {
                    ...column,
                    onCell: (r: any, index: number) => ({
                        ...column,
                        row: r,
                        index,
                        editable: column.editable,
                        dataIndex: column.dataIndex,
                        editing: editingData === JSON.stringify(r)
                    })
                }
            }

            if (column.isDuration) {
                column.render = (v) => v !== null ? secondFormat(v) : ""
            }

            if (column.sortNumber) {
                column.sorter = (a, b) => {
                    const val1 = a[column.dataIndex];
                    const val2 = b[column.dataIndex];
                    if (column.sortNumberFormater) {
                        return column.sortNumberFormater(val1) - column.sortNumberFormater(val2)
                    }
                    return val1 - val2;
                }
            }

            if (column.sortDate) {
                column.sorter = (a, b, sortOrder) => {
                    return dayjs(a[column.dataIndex]).diff(dayjs(a[column.dataIndex]).unix());

                }
            }

            if (column.textFilter) {
                column = {
                    ...column,
                    filterIcon: (filtered: boolean) => (
                        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
                    ),
                    onFilter: (value: any, record: any) =>
                        column.textFilterData ? column.textFilterData(record).toLowerCase().includes(value.toLowerCase()) : record[column.dataIndex]
                            ? record[column.dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                            : '',
                    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                        <div style={{ padding: 8 }}>
                            <StyledInput
                                placeholder={`${t('Search')} ${column.title}`}
                                value={selectedKeys[0]}
                                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                                onPressEnter={() => handleSearch(selectedKeys, confirm, column.dataIndex)}
                                style={{ width: 188, marginBottom: 8, display: 'block' }}
                            />
                            <Space>
                                <Button
                                    htmlType='button'
                                    onClick={() => handleSearch(selectedKeys, confirm, column.dataIndex)}
                                    style={{ width: 90 }}
                                >
                                    {t('Search')}
                                </Button>
                                <Button onClick={() => {
                                    handleReset(clearFilters)
                                    clearFilters && clearFilters()
                                    confirm({ closeDropdown: true })
                                }} style={{ width: 90 }}>
                                    {t('Reset')}
                                </Button>
                            </Space>
                        </div>
                    ),
                    // render: (v, r, i) => column.render ? column.render(v, r, i) : searchedColumn === column.dataIndex ? <Typography.Text mark>{v}</Typography.Text> : v
                }
            }

            return column;
        });

        const editableColExist = cols.find(e => e.editable);

        if (editableColExist) {
            cols.push({
                title: t('Edit').toString(),
                align: 'center',
                dataIndex: '',
                width: `${80 / props.columns.length}%`,
                render: (v, r, i) => <div style={{ display: 'flex', gap: '5px' }}>
                    {isEditable(r) ?
                        <Button
                            onClick={() => onSave()}
                            type='primary'
                        ><SaveOutlined /></Button> : <Button
                            onClick={() => {
                                onEdit(r)
                            }}
                            disabled={editingData}
                        ><EditOutlined /></Button>}
                    {r._newRow && <Button danger onClick={() => onRemoveRow(r)}><DeleteOutlined /></Button>}
                </div>
            })
        }

        return cols;
    }, [onRemoveRow, props.columns, editingData, t, modal, isEditable, onSave, onEdit, handleSearch, handleReset])

    const onNewRow = useCallback(() => {
        const newData = { _newRow: true };

        setState(p => ([...p, newData]));
        onEdit(newData);
    }, [setState, onEdit])

    // const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const breakpoints = Grid.useBreakpoint()

    return (
        <>
            <Form form={form} component={false}>
                <CardWrapper
                    withOutCard={props.withOutCard}
                    onNewRow={props.newRow ? () => { onNewRow() } : undefined}
                    children={<Table
                        {...props}
                        columns={columns as any}
                        components={state.length && !props.expandable && columns.find(e => e.editable) ? {
                            body: {
                                cell: TableCell
                            }
                        } : undefined}
                        dataSource={state}
                        style={{ height: '100%', ...props.style }}
                        scroll={{ x: breakpoints.md ? '70vh' : undefined }}
                        pagination={props.data ? {
                            current: props.data.currentPage,
                            pageSize: state.length,
                            total: state.length * props.data.totalPages,
                            onChange(page, pageSize) {
                                props.onPageChange && props.onPageChange(page, pageSize)
                            },
                            defaultPageSize: 1
                        } : undefined}
                    />}
                />
            </Form >
        </>
    )
}