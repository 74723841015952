import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { createContext, useCallback, useMemo } from "react";
import { useAuth } from "../hooks/auth";
import { useConfig } from "../hooks/config";
import { useLoading } from "../hooks/loading";
import { HttpResponseModel } from "../models/system/http";
import { useNotification } from "../hooks/notification";
import { useTranslation } from "react-i18next";

export interface HttpContextType {
    instance: AxiosInstance;
    methods: {
        post<Req, Res = {}>(url: string, data: Req, options?: AxiosRequestConfig): Promise<HttpResponseModel<Res>>
        get<Res = {}>(url: string, options?: AxiosRequestConfig): Promise<HttpResponseModel<Res>>
        put<Req, Res = {}>(url: string, data: Req, options?: AxiosRequestConfig): Promise<HttpResponseModel<Res>>
        delete<Res = {}>(url: string, req?: any, options?: AxiosRequestConfig): Promise<HttpResponseModel<Res>>
    }
}

export const HttpContext = createContext<HttpContextType | null>(null);

export const HttpProvider: React.FC = (props) => {
    const { apiBaseUrl, httpTimeout } = useConfig()
    const { clearAuth, authData } = useAuth();
    const { addLoading, removeLoading } = useLoading();
    const notification = useNotification();
    const { t } = useTranslation();

    const errorHandler = useCallback(async (value: AxiosResponse) => {

        if (value.data.result === -1) {
            notification.info({
                message: value.data.message
            })
        }

        const messageHideUrls = [
            "/book-reports",
            "/chat-reports"
        ];

        switch (value.status) {
            case 401:
                clearAuth();
                break;
            case 500:
                notification.error({
                    message: t('UnExecptedError')
                });
                break;
            case 200:
                if (Number(value.data.result) === 1 && value.data.message && !messageHideUrls.find(e=> value.config.url?.includes(e))) {
                    notification.success({
                        message: value.data.message
                    })
                }
                break;
            default:
                break;
        }

        return value;
    }, [clearAuth, notification, t]);

    const onRequest = useCallback(async (value: InternalAxiosRequestConfig) => {
        if (authData) {
            value.headers['Authorization'] = `Bearer ${authData.token}`
        }
        
        addLoading(value.url as string);
        return value;
    }, [addLoading, authData]);

    const onRequestRejected = useCallback(async (value) => {
        removeLoading(value.config.url as string);

        return await Promise.resolve({ data: { result: false } })
    }, [removeLoading]);

    const onResponse = useCallback(async (value: AxiosResponse) => {
        removeLoading(value.config.url as string);
        errorHandler(value)
        return {
            ...value,
            data: {
                ...value.data,
                result: value.data.result === -1 || value.data.exception ? false : true
            }
        };
    }, [errorHandler, removeLoading]);

    const onResponseRejected = useCallback(async (error: any) => {
        removeLoading(error.config.url);
        errorHandler(error.response)
        return await Promise.resolve({ data: { ...error.response.data, result: error.response.data.result === -1 || error.response.data.exception.lenght ? false : true } })
    }, [removeLoading, errorHandler]);

    const instance = useMemo(() => {
        const instance = axios.create({
            baseURL: apiBaseUrl,
            timeout: httpTimeout,
            headers: {
                common: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json'
                }
            }
        });

        instance.interceptors.request.use(onRequest, onRequestRejected)
        instance.interceptors.response.use(onResponse, onResponseRejected)

        return instance
    }, [apiBaseUrl, httpTimeout, onRequest, onResponse, onRequestRejected, onResponseRejected]);

    const methods = useMemo(() => {
        return {
            post: async <Req = any, Res = {}>(url: string, data: Req, options?: AxiosRequestConfig): Promise<HttpResponseModel<Res>> => {
                return await instance.post(url, data, options).then(res => res.data).catch(e => false);
            },
            put: async <Req = any, Res = {}>(url: string, data: Req, options?: AxiosRequestConfig): Promise<HttpResponseModel<Res>> => {
                return await instance.put(url, data, options).then(res => res.data).catch(() => false);
            },
            get: async <Res = {}>(url: string, options?: AxiosRequestConfig): Promise<HttpResponseModel<Res>> => {
                return await instance.get(url, options).then(res => res.data).catch(() => false);
            },
            delete: async <Res = {}>(url: string, req?: any, options?: AxiosRequestConfig): Promise<HttpResponseModel<Res>> => {
                return await instance.delete(url, {
                    ...options,
                    data: req
                }).then(res => res.data).catch(() => false);
            }
        }
    }, [instance]);

    return <HttpContext.Provider value={{
        instance: instance,
        methods: methods
    }}>{props.children}</HttpContext.Provider>
}