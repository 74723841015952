import { useCallback } from "react";
import { useHttp } from "../../hooks/http"
import { CreditSales, SalesGetListResModel } from "./sales";

export const useSalesService = () => {

    const http = useHttp();

    const getAllSales = useCallback(async () => {
        return await http.get<{ transactions: SalesGetListResModel[] }>('/sales');
    }, [http])

    const creditSales = useCallback(async () => {
        return await http.get<{ data: any[] }>('/credit/sales');
    }, [http])

    const transactions = useCallback(async () => {
        return await http.get<{ data: CreditSales[] }>('/credit/transactions')
    }, [http])

    return {
        getAllSales,
        creditSales,
        transactions
    }
}