import { FlagOutlined, KeyOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Layout, Row, Select, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { useAuthService } from '../../services/auth/auth.service';
import { useTranslation } from 'react-i18next';
import { useConfig } from '../../hooks/config';

interface LoginProps {

}

export const Login: React.FC<LoginProps> = (props) => {
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const service = useAuthService();
    const config = useConfig();
    const { t, i18n } = useTranslation();

    const onSubmit = useCallback(async (req: any) => {
        const res = await service.login(req);
        if (!res.result) return;
        setAuth({
            admin_id: res.admin_id,
            admin_type: res.admin_type,
            token: res.token
        });
        navigate('/')
    }, [service, setAuth, navigate])

    return (
        <div>
            <Layout style={{ height: '100vh' }}>
                <Content>
                    <Row align="middle" justify={"center"} style={{ height: '100%' }}>
                        <Col xs={22} md={10}>
                            <Card
                                title={t('System.Login')}
                                bodyStyle={{
                                    background:'#27599a'
                                }}
                                
                                extra={
                                    <Select
                                        value={i18n.language}
                                        onSelect={(v) => i18n.changeLanguage(v)}
                                        suffixIcon={<FlagOutlined />}
                                        options={config.languages.map(langKey => ({
                                            key: `lang-${langKey}`,
                                            label: langKey.toUpperCase(),
                                            value: langKey
                                        }))} />
                                }
                            >
                                <Row gutter={14}>
                                    <Col xs={24} md={10}
                                        style={{
                                            minHeight: '100px',
                                        }}
                                    >
                                        <span style={{
                                            backgroundImage: 'url(/logo.svg)',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: '90%',
                                            backgroundPosition: 'center center',
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex'

                                        }}></span>
                                    </Col>

                                    <Col xs={24} md={14}>
                                        <Typography.Text style={{ textAlign: 'center', display: 'block' }}>{t('System.LoginText')}</Typography.Text>
                                        <Form style={{ marginTop: 20 }} onFinish={(v) => onSubmit(v)}>
                                            <Form.Item name={"username"}>
                                                <Input prefix={<UserOutlined />} />
                                            </Form.Item>
                                            <Form.Item name={"password"}>
                                                <Input.Password prefix={<KeyOutlined />} />
                                            </Form.Item>
                                            <Form.Item>
                                                <Button block type='primary' htmlType='submit'>{t('System.Login')}</Button>
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </div>
    )
}
