import { useCallback } from "react";
import { useHttp } from "../../hooks/http"
import { AuthLoginReqModel, AuthLoginResModel } from "./auth";

export const useAuthService = () => {

    const http = useHttp();


    const login = useCallback(async (req: AuthLoginReqModel) => {
        return await http.post<AuthLoginReqModel, AuthLoginResModel>('/admin/login', req);
    }, [http])

    const refresh = useCallback(async () => {
        return await http.get('/admin/refresh');
    }, [http])

    return {
        login,
        refresh
    }
}