import React, { useCallback, useEffect, useState } from 'react';
import { ComponentTypes, ModalData, YesOrNoEnum } from '../../models/system';
import { Divider, Form, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useInterestService } from '../../services/interest/interest.service';
import { useForm } from 'antd/es/form/Form';
import { InterestLanguageModel, InterestsCreateReqModel } from '../../services/interest/interest';
import { useCreateFormItems } from '../../helpers/form';
import { IColumnProps } from '../../components/table';
import { ITable } from '../../components/table/table';
import { StyledInput, StyledSelect } from '../../components/form';
import { useStore } from '../../store';
import { useTranslationService } from '../../services/translation/translation.service';
import { TranslationEnums } from '../../services/translation/translation';

interface InteresetModalProps {
    data: ModalData<{ key: string; id: number; }>;
    onDone(): void;
    onCancel(): void;
}

export const InterestModal: React.FC<InteresetModalProps> = props => {
    const { t } = useTranslation();
    const service = useInterestService();
    const { data, getLanguages } = useStore()
    const [langList, setLangList] = useState<InterestLanguageModel[]>([]);
    const [form] = useForm();
    const translationService = useTranslationService();

    const getData = useCallback(async () => {
        if (!props.data.data) return;
        const res = await service.getById(props.data.data.id);
        if (!res.result) return;
        setLangList(res.data.translations)
        form.setFieldsValue(res.data);
    }, [service, form, props.data])

    useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, [props.data])

    const onReset = useCallback(() => {
        setLangList([]);
        form.resetFields();
    }, [form, setLangList])

    const onDone = useCallback(() => {
        onReset();
        props.onDone();
    }, [props, onReset])

    const onCancel = useCallback(() => {
        onReset();
        props.onCancel();
    }, [props, onReset])

    const onSubmit = useCallback(async (value: any) => {
        let res;
        if (props.data.data) {
            res = await service.update({
                ...value,
                interest_id: props.data.data.id
            });
        } else {
            res = await service.create(value);
        }

        if (!res.result) return;
        onDone();
    }, [onDone, service, props.data])

    const formItems = useCreateFormItems<InterestsCreateReqModel>([
        {
            formItem: {
                name: 'interest_key',
                label: 'Key',
                normalize(value, prevValue, allValues) {
                    return value.replace(/[A-Z]/g, function (m: any) { return m.toLowerCase(); }).replace(/\s/g, "_");
                },
            },
            colProps: { span: 24 },
            element: <Input />,
            elementType: ComponentTypes.Input
        }
    ]);

    const langCols: IColumnProps<InterestLanguageModel>[] = [
        {
            dataIndex: 'language',
            title: 'Language',
            editable: {
                canEdit: (r) => {
                    return r.translation_id === undefined
                },
                element: <StyledSelect options={data.languages.filter(e=> e.isAppLanguage === YesOrNoEnum.Yes).filter(e=> !langList.find(langList=> langList.language === e.code) ).map(e => ({ label:`${e.language.toUpperCase()} - ${e.code.toUpperCase()}`, value: e.code }))} onDropdownVisibleChange={() => getLanguages()} />
            }
        },
        {
            dataIndex: 'value',
            title: 'Translate',
            editable: {
                element: <StyledInput />,
            }
        },
        {
            dataIndex:'translation_id',
            actionColumn:{
                hasDelete:{
                    propName:'translation_id',
                    callback:async(data)=> {
                        const res = translationService.remove(data);
                        if(!res) return;
                        getData();
                    },
                }
            }
        }
    ]

    return (
        <>
            <Modal
                open={props.data.open}
                title={t(props.data.data ? 'Update' : 'New')}
                onCancel={() => onCancel()}
                okButtonProps={{
                    form: 'interestForm',
                    htmlType: 'submit'
                }}
                width={"50%"}
            >
                <Form form={form} id='interestForm' onFinish={onSubmit}>
                    {formItems}
                </Form>

                {
                    props.data.data && <>
                        <Divider />
                        <ITable
                            rowKey={"interest_key"}
                            columns={langCols}
                            dataSource={langList}
                            onSaveRow={async (v: InterestLanguageModel) => {
                                if (v.translation_id) {
                                    let update = await translationService.update({
                                        translation_id: v.translation_id,
                                        value: v.value
                                    });

                                    if (!update.result) return;
                                } else {
                                    // todo
                                    if (!props.data.data) return;
                                    const res = await translationService.add({ type: TranslationEnums.interest, language: v.language, key: props.data.data.key, value: v.value })
                                    if (!res.result) return;
                                }

                                getData()
                            }}
                            newRow
                        />
                    </>
                }
            </Modal>
        </>
    )
}