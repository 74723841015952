import { useCallback, useEffect, useState } from "react";
import { PageCard } from "../../components/pageCard"
import { useInterestService } from "../../services/interest/interest.service"
import { InterestsGetAllResModel } from "../../services/interest/interest";
import { ITable } from "../../components/table/table";
import { IColumnProps } from "../../components/table";
import { InterestModal } from "./interestModal";
import { ModalData, ModalDataModel } from "../../models/system";

export const Interest: React.FC = props => {
    const service = useInterestService();

    const [state, setState] = useState<InterestsGetAllResModel[]>([])

    const [modal, setModal] = useState<ModalData<{ key: string; id: number; }>>({ data: null, open: false })
    const getList = useCallback(async () => {
        const res = await service.getAll();

        if (!res.result) return;

        setState(res.data);
    }, [service, setState])

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])

    const columns: IColumnProps<InterestsGetAllResModel>[] = [
        {
            dataIndex: 'interest_key',
            title: 'Key',
            textFilter: true
        },

        {
            dataIndex: 'book_count',
            title: 'BookSize',
            sortNumber: true
        },
        {
            dataIndex: 'user_book_count',
            title: 'VocalizationSize',
            sortNumber: true
        },
        // {
        //     dataIndex: 'updated_at',
        //     isDate: true,
        //     title: 'UpdatedDate'
        // },
        {
            dataIndex: 'created_at',
            isDate: true,
            sortDate:true,
            title: 'CreatedDate'
        },
        {
            dataIndex: 'interest_id',
            actionColumn: {
                hasUpdate: {
                    propName: 'interest_id',
                    callback: (data, r) => setModal({ data: { id: r.interest_id, key: r.interest_key }, open: true })
                },
                hasDelete: {
                    callback: async (data) => {
                        const res = await service.remove(data);
                        if (!res.result) return;
                        getList()
                    },
                    propName: 'interest_id',
                    messageProp: 'interest_key'
                },

            }
        }
    ]

    return (
        <>
            <PageCard
                onClickNew={() => setModal({ data: null, open: true })}
            >


                <ITable
                    rowKey={"interest_id"}
                    dataSource={state}
                    columns={columns}
                />
            </PageCard>

            <InterestModal
                data={modal}
                onCancel={() => setModal(ModalDataModel())}
                onDone={() => {
                    setModal(ModalDataModel())
                    getList()
                }}
            />
        </>
    )
}