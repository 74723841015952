import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Typography } from "antd";
import { createContext, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

interface LoadingContextType {
    loadingKeys: string[];
    addLoading(key: string): void;
    removeLoading(key: string): void;
}

export const LoadingContext = createContext<LoadingContextType>({
    addLoading: () => { },
    loadingKeys: [],
    removeLoading: () => { }
});

export const LoadingProvider: React.FC = props => {

    const [state, setState] = useState<string[]>([]);

    const { t } = useTranslation();

    const addLoading = useCallback((key: string) => {
        setState(prev => prev.concat(key));
    }, [setState])

    const removeLoading = useCallback((key: string) => {
        setState(prev => prev.filter(currentKey => currentKey !== key));
    }, [setState])

    return <LoadingContext.Provider
        value={{
            addLoading,
            loadingKeys: state,
            removeLoading
        }}
    >
        <div
            style={{
                position: 'absolute',
                zIndex: 10000,
                width: '100vw',
                height: '100vh',
                display: state.length > 0 ? 'flex' : 'none',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'rgba(0, 0, 0, 0.5)'
            }}
        >
            <Spin style={{margin:5}} size="large" indicator={<LoadingOutlined style={{ fontSize: 32, zIndex: 10000, color: 'white' }} spin />} tip={t('System.Loading')} spinning={state.length > 0}></Spin>
            <Typography.Text>{t('System.Loading')}</Typography.Text>
        </div>
        {props.children}
    </LoadingContext.Provider>
}