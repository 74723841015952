import { Form, Input, InputProps, Select } from "antd";
import { ChangeEvent, useCallback } from "react";
import { DatePicker, DatePickerProps } from 'antd';
import dayjs from 'dayjs';
import React, { createElement } from 'react';
import styled from "styled-components";

export const StyledFormItem = styled(Form.Item)`
`

export const StyledInput = styled(Input)`
`;

export const StyledSelect = styled(Select)`
`;

export const StyledNumberInput: React.FC<InputProps> = props => {
    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
       e.target.value = e.target.value.replace(/[^0-9.,]/g, '');

       e.target.value = e.target.value.replace(/,/g, '.');

       const v = Number(e.target.value);
       if (props.min && v < Number(props.min)) {
           e.target.value = String(props.min);
       }
       props.onChange && props.onChange(e);
    }, [props])
    return (
        <StyledInput
            inputMode="numeric"
            {...props}
            onChange={(e) => onChange(e)}
        />
    )
}

export const DecimalInput: React.FC<InputProps> = props => {

    const onChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
        let start = e.target.selectionStart
        let val = e.target.value.toString();
        val = val.replace(/([^0-9.]+)/, "");
        val = val.replace(/^(0|\.)/, "");
        const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
        if (!match) return;
        let value: any = match[1] + match[2];
        value = Number(value)
        e.target.value = value;
        props.onChange && props.onChange(value);
        if (val.length > 0) {
            e.target.value = Number(value).toFixed(2);
            e.target.setSelectionRange(start, start);
            props.onChange && props.onChange(Number(Number(value).toFixed(2)) as any);
        }
    }, [props])

    return (
        <StyledInput
            {...props}
            inputMode="decimal"
            value={String(Number(props.value).toFixed(2))}
            onChange={e => onChange(e)}
        />
    )

}

type IPickerProps = DatePickerProps & {
}
export const IDatePicker: React.FC<IPickerProps> = props => {

    return (
        <>
            {createElement<IPickerProps>(DatePicker, {
                ...props,
                format: "YYYY-MM-DD",
                defaultValue: dayjs(props.defaultValue).locale('tr') as any,
                value: props.value ? dayjs(props.value,['YYYY-MM-DD']).locale('tr') as any : "",
                style: {
                    ...props.style,
                    width: '100%'
                },
                onChange: (v, s) => {
                    props.onChange && props.onChange(v?.format('YYYY-MM-DD') as any, s)
                }
            })}
        </>
    )
}