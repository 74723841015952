import { DefaultOptionType } from "antd/es/select";
import { useTranslation } from "react-i18next";

export const EnumToSelectOptions = (enumObj: any, options: {
    languageKeyPrefix?: string
    customLabel?(label: string, key: string): React.ReactNode | string;
}): DefaultOptionType[] => {
    const { t } = useTranslation();
    const list: any[] = [];

    const getLabel = (key: string) => {
        const label = t(options.languageKeyPrefix ? `${options.languageKeyPrefix}.${key}` : key);
        if (options.customLabel) {
            return options.customLabel(label, key);
        }
        return label
    }

    Object.keys(enumObj).filter(e => !Number(e)).forEach(e => {

        list.push({
            value: enumObj[e],
            label: getLabel(e),
        });
    });

    return list;
}


export const DurationToTime = (duration: number) => {
    var date = new Date(0);
    date.setSeconds(45); // specify value for SECONDS here
    return date.toISOString().substring(11, 19);
}

export const makeid = (length = 6) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export const secondFormat = (second: number) => {
    return new Date(second * 1000).toISOString().substring(11, 19)
}