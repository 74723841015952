import { useCallback } from "react";
import { useHttp } from "../../hooks/http"
import { SalesGetListResModel } from "../sales/sales";

export const useTransactionsService = () => {

    const http = useHttp();

    const getTransactionsByUserId = useCallback(async (id: number) => {
        return await http.get<{ transactions: SalesGetListResModel[] }>(`/transactions/${id} `);
    }, [http])

    const getEarningsByUserId = useCallback(async (id: number) => {
        return await http.get<{ transactions: SalesGetListResModel[] }>(`/earnings/${id}`);
    }, [http])
    return {
        getTransactionsByUserId,
        getEarningsByUserId
    }
}