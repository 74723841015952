import { useCallback, useEffect, useState } from "react";
import { PageCard } from "../../components/pageCard"
import { useInterestService } from "../../services/interest/interest.service"
import { InterestAddPopularReqModel, InterestPopularListResModel } from "../../services/interest/interest";
import { IColumnProps } from "../../components/table";
import { useStore } from "../../store";
import { Avatar, Button, Form, Modal, Row } from "antd";
import { StyledNumberInput, StyledSelect } from "../../components/form";
import { useTranslation } from "react-i18next";
import { ITable } from "../../components/table/table";
import { DownCircleOutlined, UpCircleOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { useCreateFormItems } from "../../helpers/form";
import { ComponentTypes } from "../../models/system";
import { ImageUpload } from "../../components/imageUpload";

export const PopularInterests = () => {
    const service = useInterestService();
    const [state, setState] = useState<InterestPopularListResModel[]>([]);
    const [lang] = useState("tr");
    const store = useStore();
    const [modalState, setModalState] = useState(false);
    const { t } = useTranslation();
    const [form] = useForm();

    const onSearch = useCallback(async () => {
        // if (lang === '') return;
        const res = await service.getPopularList();
        if (!res.result) return;
        setState(res.data)
    }, [service, setState])

    useEffect(() => {
        store.getLanguages();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        onSearch();
        // eslint-disable-next-line
    }, [lang])

    useEffect(() => {
        store.getAllInterest()
        // eslint-disable-next-line
    }, [modalState])

    const columns: IColumnProps<InterestPopularListResModel>[] = [
        {
            dataIndex: 'icon',
            render: (v) => <Avatar src={v} />,
            title: 'Icon'
        },
        {
            dataIndex: 'interest_key',
            title: 'Category'
        },
        {
            dataIndex: 'priority',
            title: 'Priority',
            isTag: true
        },
        {
            dataIndex: 'pi_id',
            title: 'PriorityUpdate',
            render: (v, r) => (
                <div style={{ gap: 10, display: 'flex' }}>
                    <Button onClick={async () => {
                        const res = await service.updatePopularPriority({
                            pi_id: r.pi_id,
                            priority: r.priority + 1
                        });
                        if (!res.result) return;
                        onSearch()
                    }} type="dashed"><UpCircleOutlined /></Button>
                    <Button onClick={async () => {
                        const res = await service.updatePopularPriority({
                            pi_id: r.pi_id,
                            priority: r.priority - 1
                        });
                        if (!res.result) return;
                        onSearch()
                    }} type="dashed"><DownCircleOutlined /></Button>
                </div>
            )
        },
        {
            dataIndex: 'pi_id',
            actionColumn: {
                hasDelete: {
                    callback: async (data) => {
                        const res = await service.removePopular(data);
                        if (!res.result) return;
                        onSearch()
                    },
                    propName: 'pi_id'
                }
            }
        }
    ]

    const formItems = useCreateFormItems<InterestAddPopularReqModel>([
        {
            colProps: {
                span: 24
            },
            formItem: {
                name: 'interest_id',
                label: 'Category'
            },
            element: <StyledSelect showSearch optionFilterProp="label" options={store.data.categories.map(e => ({ label: e.interest_key, value: e.interest_id }))} />,
            elementType: ComponentTypes.Input
        },
        {
            colProps: {
                span: 24
            },
            formItem: {
                name: 'priority',
                label: 'Priority'
            },
            element: <StyledNumberInput />,
            elementType: ComponentTypes.Input
        },
        {
            colProps: {
                span: 24
            },
            formItem: {
                name: 'icon',
                label: 'Icon'
            },
            element: <ImageUpload />,
            elementType: ComponentTypes.Input
        }
    ])

    return (
        <PageCard
            onClickNew={() => setModalState(true)}
            // extra={<Form.Item style={{ margin: 0 }} label={t('SelectLanguage')}>
            //     <StyledSelect
            //         style={{ width: '200px' }}
            //         options={store.data.languages.map(e => ({ label:`${e.language.toUpperCase()} - ${e.code.toUpperCase()}`, value: e.code }))}
            //         value={lang}
            //         onSelect={(v) => setLang(String(v))}
            //     />
            // </Form.Item>}
        >
            <ITable
                columns={columns}
                dataSource={state}
            />

            <Modal
                open={modalState}
                okButtonProps={{
                    form: 'popInterestForm',
                    htmlType: 'submit'
                }}
                onCancel={() => {
                    form.resetFields();
                    setModalState(false)
                }}
                title={t('New')}
            >
                <Form form={form} id="popInterestForm" onFinish={async (v: any) => {
                    const res = await service.addPopular(v);
                    if (!res.result) return;
                    onSearch();
                    setModalState(false)
                    form.resetFields()
                }}>
                    <Row gutter={24}>
                        {formItems}
                    </Row>
                </Form>
            </Modal>
        </PageCard>
    )
}