import { useCallback } from "react";
import { useHttp } from "../../hooks/http"
import { UserCreateReqModel, UserEarnModel, UserGetByIdResModel, UserGetListResModel, UserUpdateReqModel } from "./user";

export const useUserService = () => {
    const http = useHttp();

    const getList = useCallback(async () => {
        return await http.get<{ data: UserGetListResModel[] }>('/users');
    }, [http])

    const getById = useCallback(async (id: number) => {
        return await http.get<{ data: UserGetByIdResModel }>(`/user/${id}`);
    }, [http])

    const update = useCallback(async (req: UserUpdateReqModel) => {
        return await http.put<UserUpdateReqModel>('/users', req);
    }, [http])

    const ban = useCallback(async (id: number) => {
        return await http.get(`/user/${id}/ban`);
    }, [http])

    const unban = useCallback(async (id: number) => {
        return await http.get(`/user/${id}/unban`);
    }, [http])

    const setPremium = useCallback(async (id: number) => {
        return await http.get(`/user/${id}/premium`);
    }, [http])

    const unPremium = useCallback(async (id: number) => {
        return await http.get(`/user/${id}/unpremium`);
    }, [http])

    const add = useCallback(async (req: UserCreateReqModel) => {
        return await http.post('user/register', req);
    }, [http])

    const getFollowList = useCallback(async (id: number) => {
        return await http.get<{ data: UserGetByIdResModel[] }>(`/user/${id}/followlist`);
    }, [http])

    const getFollowingList = useCallback(async (id: number) => {
        return await http.get<{ data: UserGetByIdResModel[] }>(`/user/${id}/followinglist`);
    }, [http])

    const earnings = useCallback(async (id: number) => {
        return http.get<{ data: UserEarnModel }>(`user/earnings/${id}`);
    }, [http])

    const search = useCallback(async (query: string) => {
        return await http.post<any, { data: any[] }>('/active-user/search', { query })
    }, [http])

    return {
        earnings,
        search,
        getList,
        update,
        ban,
        getFollowList,
        getFollowingList,
        add,
        setPremium,
        unPremium,
        unban,
        getById
    }
}