import { useForm } from "antd/lib/form/Form";
import { ComponentTypes, ModalData, YesOrNoEnum } from "../../../models/system";
import { useAgreementService } from "../../../services/agreement/agreement.service";
import { useCallback, useEffect } from "react";
import { AgreementAddUpdateModel } from "../../../services/agreement/agreement";
import { Form, Modal, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useCreateFormItems } from "../../../helpers/form";
import { StyledInput, StyledSelect } from "../../../components/form";
import { useStore } from "../../../store";
import { RichEditor } from "../../../components/editor";

interface AgreementContentAddModalProps {
    data: ModalData<AgreementAddUpdateModel>;
    onDone(): void;
    onCancel(): void;
}

export const AgreementContentAddModal: React.FC<AgreementContentAddModalProps> = props => {

    const service = useAgreementService();
    const [form] = useForm();
    const { data, getLanguages } = useStore();
    const { t } = useTranslation();

    const onReset = useCallback(() => {
        form.resetFields();
    }, [form])

    useEffect(() => {
        if (props.data.data) {
            form.setFieldsValue(props.data.data);
        }
        // eslint-disable-next-line
    }, [props.data.data])

    useEffect(() => {
        getLanguages()
        // eslint-disable-next-line
    }, [])

    const onCancel = useCallback(() => {
        onReset();
        props.onCancel();
    }, [onReset, props])

    const onDone = useCallback(() => {
        onReset();
        props.onDone();
    }, [onReset, props])

    const onSubmit = useCallback(async (val: AgreementAddUpdateModel) => {
        let res = false;


        if (!props.data.data) {
            const add = await service.add({
                ...val,
            });
            res = add.result;
        } else {
            const update = await service.update({
                ...val,
                ua_id: props.data.data.ua_id,
            })

            res = update.result
        }

        if (!res) return;
        onDone();
    }, [service, onDone, props.data])

    return (
        <Modal
            open={props.data.open}
            title={t('Agreement')}
            onCancel={() => onCancel()}
            okButtonProps={{
                form: 'agreementContentModalForm',
                htmlType: 'submit'
            }}
        >
            <Form id="agreementContentModalForm" form={form} onFinish={(v) => onSubmit(v)}>
                <Row gutter={16}>
                    {useCreateFormItems<AgreementAddUpdateModel>([
                        {
                            formItem: {
                                name: 'lang',
                                label: 'Language'
                            },
                            colProps: {
                                span: 24
                            },
                            element: <StyledSelect options={data.languages.filter(e=> e.isAppLanguage === YesOrNoEnum.Yes).map(e => ({ value: e.code, label:`${e.language.toUpperCase()} - ${e.code.toUpperCase()}` }))} />,
                            elementType: ComponentTypes.Input
                        },
                        {
                            colProps: {
                                span: 24
                            },
                            formItem: {
                                name: 'title',
                                label: 'Title'
                            },
                            element: <StyledInput />,
                            elementType: ComponentTypes.Input
                        },
                        {
                            colProps: {
                                span: 24
                            },
                            formItem: {
                                name: 'content',
                                label: 'Content'
                            },
                            element: <RichEditor />,
                            elementType: ComponentTypes.Input
                        }
                    ])}
                </Row>
            </Form>
        </Modal>
    )

}