import React, { useCallback, useEffect, useState } from 'react';
import { ComponentTypes, ModalData, ModalDataModel } from '../../models/system';
import { Button, Dropdown, Form, Modal, Row, Space, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useUserService } from '../../services/user/user.service';
import { UserGetByIdResModel, UserUpdateReqModel } from '../../services/user/user';
import { useForm } from 'antd/lib/form/Form';
import { useWithdrawalService } from '../../services/withdrawal/withdrawal.service';
import { useCreateFormItems } from '../../helpers/form';
import { StyledInput } from '../../components/form';
import { ITable } from '../../components/table/table';
import { useModal } from '../../hooks/modal';
import { IColumnProps } from '../../components/table';
import { WithdrawalWaitingListResModel } from '../../services/withdrawal/withdrawal';
import { DownOutlined } from '@ant-design/icons';
import { WithdrawDeclineModal } from '../withdrawManager/withdrawDeclineModal';

interface UserModalProps {
    data: ModalData<number>;
    onDone(): void;
    onCancel(): void;
}

export const UserModal: React.FC<UserModalProps> = props => {
    const { t } = useTranslation();
    const [state, setState] = useState<UserGetByIdResModel | null>(null);
    const [withdrawInfo, setWithdrawInfo] = useState<WithdrawalWaitingListResModel[]>([]);
    const service = useUserService();
    const withdrawService = useWithdrawalService();
    const [activeTab, setActiveTab] = useState("general")
    const [form] = useForm();
    const modal = useModal();
    const [modalData, setModalData] = useState<ModalData<number>>(ModalDataModel());


    const getDetail = useCallback(async () => {
        if (!props.data.data) return;
        const res = await service.getById(props.data.data);
        if (!res.result) return;
        setState(res.data)
        form.setFieldsValue(res.data)
    }, [service, setState, form, props.data])

    const getWithdrawHistory = useCallback(async () => {
        if (!props.data.data) return;
        const res = await withdrawService.withdrawListGetForUser(props.data.data);
        if (!res.result) return;
        setWithdrawInfo(res.data)
    }, [props.data, withdrawService, setWithdrawInfo])

    const onReset = useCallback(async () => {
        form.resetFields();
        setState(null);
        setActiveTab("general");
        setWithdrawInfo([])
    }, [form, setState, setActiveTab, setWithdrawInfo])


    const onDone = useCallback(async () => {
        onReset();
        props.onDone()
    }, [onReset, props])

    const onCancel = useCallback(async () => {
        onReset();
        props.onCancel()
    }, [onReset, props])

    const onSubmit = useCallback(async (data: UserUpdateReqModel) => {
        if (!props.data.data) return;
        const req: any = {
            id: props.data.data,
            earned_wallet: String(data.earned_wallet),
            wallet: String(data.wallet),
            name: data.name,
            surname: data.surname
        };

        const res = await service.update(req as any);

        if (!res.result) return;
        onDone()

    }, [service, onDone, props.data])

    useEffect(() => {
        if (props.data.open) {
            getDetail();
        } else {
            setState(null)
        }
        // eslint-disable-next-line
    }, [props.data])

    useEffect(() => {
        if (props.data.open && activeTab === 'WithdrawlHistory') {
            getWithdrawHistory()
        }
        // eslint-disable-next-line
    }, [props.data, activeTab])

    const formItems = useCreateFormItems<UserUpdateReqModel>([
        {
            colProps: {
                span: 12
            },
            formItem: {
                label: 'Name',
                name: 'name',
            },
            element: <StyledInput />,
            elementType: ComponentTypes.Input
        },
        {
            colProps: {
                span: 12
            },
            formItem: {
                label: 'Name',
                name: 'surname'
            },
            element: <StyledInput />,
            elementType: ComponentTypes.Input
        },
        {
            colProps: {
                span: 12
            },
            formItem: {
                label: 'PhoneNumber',
                name: 'phone'
            },
            element: <StyledInput disabled />,
            elementType: ComponentTypes.Input
        },
        {
            colProps: {
                span: 12
            },
            formItem: {
                label: 'Email',
                name: 'email'
            },
            element: <StyledInput disabled />,
            elementType: ComponentTypes.Input
        },
        {
            colProps: {
                span: 12
            },
            formItem: {
                label: 'Country',
                name: 'countryCode'
            },
            element: <StyledInput disabled />,
            elementType: ComponentTypes.Input
        },

        {
            colProps: {
                span: 12
            },
            formItem: {
                label: 'EarnedWallet',
                name: 'earned_wallet'
            },
            element: <StyledInput disabled />,
            elementType: ComponentTypes.Input
        },

        {
            colProps: {
                span: 12
            },
            formItem: {
                label: 'Credit',
                name: 'wallet'
            },
            element: <StyledInput />,
            elementType: ComponentTypes.Input
        },


    ]);

    const columns: IColumnProps<WithdrawalWaitingListResModel>[] = [
        {
            dataIndex: 'name',
            title: 'Name'
        },
        {
            dataIndex: 'surname',
            title: 'Surname'
        },
        {
            dataIndex: 'amount',
            title: 'Amount'
        },
        {
            dataIndex: 'earned_wallet',
            title: 'EarnedWallet'
        },
        {
            dataIndex: 'created_at',
            title: 'CreatedDate',
            isDate: true
        },
        {
            dataIndex: 'isApproved',
            title: 'Approved',
            isYesOrNo: true
        },
        {
            dataIndex: 'isDeclined',
            title: 'Declined',
            isYesOrNo: true
        },
        {
            title: 'Action',
            dataIndex: 'id',
            render: (v, r) => <Dropdown menu={{
                items: [
                    {
                        label: t('Approve'),
                        key: 'approve',
                        onClick: () => modal.confirm({
                            content: t('WithdrawApproveConfirm', { value: r.amount }),
                            onOk: async () => {
                                const res = await withdrawService.approve({ request_id: r.request_id })
                                if (!res.result) return;
                                getWithdrawHistory();
                            }
                        })
                    },
                    {
                        label: t('Decline'),
                        key: 'decline',
                        onClick: () => modal.confirm({
                            content: t('WithdrawDeclineConfirm', { value: r.amount }),
                            onOk: async () => {
                                setModalData({ data: r.request_id, open: true })
                            }
                        })
                    }
                ]
            }}>
                <Button>
                    <Space>
                        {t('Action')}
                        <DownOutlined />
                    </Space>
                </Button>
            </Dropdown>
        }
    ]


    return (
        <>
            <Modal
                open={props.data.open}
                title={t('UserDetail')}
                onCancel={() => onCancel()}
                width={"70vw"}
                okButtonProps={{
                    htmlType: activeTab !== 'WithdrawlHistory' ? 'submit' : 'button',
                    form: 'userupdateform',
                }}
            >
                <Tabs
                    activeKey={activeTab}
                    onChange={(key) => setActiveTab(key)}
                    items={[
                        {
                            label: t('GeneralInformation'),
                            key: 'general',
                            children: <>
                                {
                                    state && <>
                                        <Form id="userupdateform" form={form} onFinish={(v) => onSubmit(v)}>
                                            <Row gutter={14}>
                                                {formItems}
                                            </Row>
                                        </Form>
                                    </>
                                }
                            </>
                        },
                        {
                            label: t('WithdrawlHistory'),
                            key: 'WithdrawlHistory',
                            children: <>
                                {
                                    withdrawInfo && <>
                                        <ITable
                                            dataSource={withdrawInfo}
                                            columns={columns}
                                        />
                                    </>
                                }
                            </>
                        }
                    ]}
                />
            </Modal>
            <WithdrawDeclineModal
                data={modalData}
                onCancel={() => setModalData(ModalDataModel())}
                onDone={() => {
                    setModalData(ModalDataModel())
                    getWithdrawHistory()
                }}
            />
        </>
    )
}