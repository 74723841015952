import { useCallback, useEffect, useMemo, useState } from "react";
import { useBookService } from "../../services/book/book.service"
import { BookLangResModel, BookListReqModel, BookModel } from "../../services/book/book";
import { PaginationResDto, PaginationResModel } from "../../models/system/http";
import { ModalData, ModalDataModel } from "../../models/system";
import { Form, Image, Tag } from "antd";
import { PageCard } from "../../components/pageCard";
import { ITable } from "../../components/table/table";
import { IColumnProps } from "../../components/table";
import { useStore } from "../../store";
import { StyledSelect } from "../../components/form";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { BookInterestModal } from "./bookInterestModal";
import { BookDetailEditModal } from "./bookDetailEditModal";
import { SummaryStatsModal } from "../summary/summaryStatsModal";
import { BookImageUpdateModal } from "./bookImageModal";


export const Books: React.FC = props => {
    const navigation = useNavigate();
    const service = useBookService();
    const [state, setState] = useState<PaginationResModel<BookLangResModel>>(PaginationResDto())
    const store = useStore();
    const [lang, setLang] = useState("tr");
    const [statsModal, setStatsModal] = useState<ModalData<number>>(ModalDataModel());
    const [coverModal, setCoverModal] = useState(ModalDataModel());
    const [searchFilter, setSearchFilter] = useState<BookListReqModel>({
        limit: 100,
        page: 1
    });

    const [interestModal, setInterestModal] = useState<ModalData<number>>(ModalDataModel())
    const [modalData, setModalData] = useState<ModalData<number>>(ModalDataModel());

    const onSearch = useCallback(async () => {
        if (lang === '') return;
        const res = await service.getList(searchFilter, lang);
        if (!res.result) return;
        setState(res.data)
    }, [service, setState, searchFilter, lang])

    useEffect(() => {
        onSearch();
        // eslint-disable-next-line
    }, [searchFilter, lang])

    useEffect(() => {
        store.getLanguages();
        store.getAllInterest();
        // eslint-disable-next-line
    }, [])

    const catList = useMemo(() => {
        const list = [{ text: t('UnCategorized'), value: -1 }]
        store.data.categories.forEach(e => list.push({ text: e.interest_key, value: e.interest_id }))
        return list;
    }, [store])

    const columns: IColumnProps<BookModel>[] = [
        {
            dataIndex: 'image',
            title: 'Image',
            align: 'center',
            render: (v, r) => {
                const val = r.updated_image ? r.updated_image : r.image.length ? r.image : null

                return val ? <Image width={40} height={60} src={val} /> : null
            }
        },
        {
            dataIndex: 'book_name',
            title: 'BookName',
            textFilter: true,
            width: '35%',
            textFilterData(row) {
                return row.book_name;
            },
        },

        {
            dataIndex: 'categories',
            title: 'Categories',
            filters: catList,
            filterSearch: true,
            filterMode: 'menu',
            render: (v, r) => r.categories.map(e => {
                const cat = store.data.categories.find(c => c.interest_id === e.interest_id)
                if (!cat) return null;
                return <Tag color='blue' >{cat.interest_key}</Tag>
            }),
            onFilter(value, record) {
                if (value === -1 && !record.categories.length) return true;
                const exist = record.categories.find(e => e.interest_id === Number(value))
                return exist ? true : false;
            },
        },
        {
            dataIndex: 'user_books_count',
            title: 'SummaryCount',
            sortNumber: true,
        },
        {
            dataIndex: 'book_sales',
            title: 'BookSales',
            sortNumber: true,

        },
        {
            dataIndex: 'listenings',
            title: 'ListeningCount',
            sortNumber: true,

        },
        // {
        //     title: 'Description',
        //     dataIndex: 'description',
        //     render: (v) => {
        //         const desc = String(v);
        //         if (desc.length < 50) return desc;
        //         return <Tooltip placement="left" title={v}>{String(v).substring(0, 50) + "..."}</Tooltip>
        //     }
        // },
        {
            dataIndex: 'created_at',
            title: 'CreatedDate',
            isDate: true
        },
        {
            dataIndex: 'updated_at',
            title: 'UpdatedDate',
            isDate: true
        },
        {
            dataIndex: 'book_id',

            actionColumn: {
                hasUpdate: {
                    callback: (data) => setModalData({ data, open: true }),
                    propName: 'book_id'
                },
                items: (row) => [
                    {
                        key: 'editinterest',
                        label: 'EditInterest',
                        onClick: () => setInterestModal({ data: row.book_id, open: true })
                    },
                    {
                        key: 'updateCover',
                        label: 'BookUpdateCover',
                        onClick: () => setCoverModal({ data: row.book_id, open: true })
                    },
                    {
                        key: 'detail',
                        label: t('SummaryDetail'),
                        onClick: () => {
                            setStatsModal({ data: row.book_id, open: true })
                        }
                    }
                ],
            }
        }
    ]

    return (
        <>
            <PageCard
                onClickNew={() => navigation("/books/new")}
                extra={<Form.Item style={{ margin: 0 }} label={t('SelectLanguage')}>
                    <StyledSelect
                        style={{ width: '200px' }}
                        options={store.data.languages.map(e => ({ label: `${e.language.toUpperCase()} - ${e.code.toUpperCase()}`, value: e.code }))}
                        value={lang}
                        onSelect={(v) => setLang(String(v))}
                    />
                </Form.Item>}
            >
                {/* <Form id="search" onFinish={(v) => setSearchFilter(p => ({ ...p, query: v.query }))}>
                    <Row gutter={14}>
                        {searchFormItems}
                    </Row>
                </Form>
                <Button htmlType="submit" form="search">{t('List')}</Button> */}

                <ITable
                    dataSource={state.search_results}
                    rowKey={"id"}
                    columns={columns}
                    onPageChange={(page, s) => setSearchFilter(p => ({ ...p, page }))}
                />
            </PageCard>
            <BookImageUpdateModal
                data={coverModal}
                onCancel={() => {
                    setCoverModal(ModalDataModel())
                }}
                onDone={() => {
                    setCoverModal(ModalDataModel())

                    onSearch()
                }}
            />
            <SummaryStatsModal
                data={statsModal}
                onCancel={() => setStatsModal(ModalDataModel())}
                onDone={() => {
                    setStatsModal(ModalDataModel())
                    onSearch()
                }}
            />
            <BookInterestModal
                data={interestModal}
                onCancel={() => setInterestModal(ModalDataModel())}
                onDone={() => {
                    setInterestModal(ModalDataModel())
                    onSearch()
                }}
                onReload={() => onSearch()}
            />

            <BookDetailEditModal
                data={modalData}
                onCancel={() => {
                    setModalData(ModalDataModel())
                }}
                onDone={() => {
                    setModalData(ModalDataModel())
                }}
            />

        </>
    )
}