import { useCallback, useEffect, useState } from "react";
import { ModalData } from "../../models/system";
import { useBookService } from "../../services/book/book.service";
import { IColumnProps } from "../../components/table";
import { BookInterestsList } from "../../services/book/book";
import { StyledSelect } from "../../components/form";
import { useInterestService } from "../../services/interest/interest.service";
import { InterestsGetAllResModel } from "../../services/interest/interest";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { ITable } from "../../components/table/table";

interface BookInterestModalProps {
    data: ModalData<number>;
    onDone(): void;
    onCancel(): void;
    onReload(): void;
}

export const BookInterestModal: React.FC<BookInterestModalProps> = props => {
    const service = useBookService();
    const interestService = useInterestService();
    const { t } = useTranslation();

    // todo: store a eklenecek yeni interest ekleninde store siliniceke
    const [interestList, setInterestList] = useState<InterestsGetAllResModel[]>([]);
    const [state, setState] = useState<BookInterestsList[]>([])

    const getInterestList = useCallback(async () => {
        const res = await interestService.getAll();
        if (!res.result) return;
        setInterestList(res.data);
    }, [interestService, setInterestList])

    const getDetail = useCallback(async () => {
        if (!props.data.data) return;
        const res = await service.getInterests(props.data.data);
        if (!res.result) return;
        setState(res.data);
    }, [service, props.data, setState])

    useEffect(() => {
        if (!props.data.open) return;
        getDetail()
        getInterestList()
        // eslint-disable-next-line
    }, [props.data])

    const onReset = useCallback(() => {
        setState([]);
    }, [setState])

    const onCancel = useCallback(() => {
        onReset();
        props.onCancel();
    }, [onReset, props])

    const cols: IColumnProps<BookInterestsList>[] = [
        {
            dataIndex: 'interest_key',
            title: 'Category',
            editable: {
                canEdit(row) {
                    return !row.interest_id
                },
                element: <StyledSelect showSearch optionFilterProp="label" options={interestList.map(e => ({ label: e.interest_key, value: String(e.interest_id) }))} />
            }
        },
        {
            dataIndex: 'interest_id',
            width: 40,
            actionColumn: {
                hasDelete: {
                    propName: 'interest_id',
                    messageProp: 'interest_key',
                    callback: async (data) => {
                        if (!props.data.data) return;
                        const res = await service.removeInterest({ book_id: props.data.data, interest_id: data });
                        if (!res.result) return;
                        getDetail()
                        props.onReload()
                    }
                }
            }
        }
    ];


    return (
        <Modal
            open={props.data.open}
            title={t('EditBookInterest')}
            onCancel={() => onCancel()}
            onOk={() => onCancel()}
            width={"40vw"}

        >
            <ITable
                columns={cols}
                dataSource={state}
                newRow
                onSaveRow={async (data: BookInterestsList) => {
                    if (!props.data.data) return;

                    if ((data as any)._newRow) {
                        const res = await service.addInterest({ book_id: props.data.data, interest_id: Number(data.interest_key) })
                        if (!res.result) return;
                        getDetail()
                        props.onReload();
                    }
                }}
            />
        </Modal>
    )

}