import { useCallback } from "react";
import { useHttp } from "../../hooks/http";
import {
  UserBookCreateReqModel,
  UserBookDeclinedListResModel,
  UserBookGetAllResModel,
  UserBookGetByResModel,
  UserBookUpdateModel,
  UserBookUpdateReqModel,
  UserBookWaitingResModel,
  Userbookdetail,
} from "./userBook";

export const useUserBookService = () => {
  const http = useHttp();

  const getAll = useCallback(async () => {
    return await http.get<{ data: UserBookGetAllResModel[] }>(
      "/user-books/all"
    );
  }, [http]);

  const getById = useCallback(
    async (id: number) => {
      return await http.get<{ data: UserBookGetByResModel }>(
        `/user-book/${id}`
      );
    },
    [http]
  );

  const update = useCallback(
    async (req: UserBookUpdateReqModel) => {
      return await http.put<
        UserBookUpdateReqModel,
        { data: UserBookGetByResModel }
      >(`/user-book/${req.id}`, req);
    },
    [http]
  );

  const create = useCallback(
    async (req: UserBookCreateReqModel) => {
      return await http.post<UserBookCreateReqModel>("/user-book/store", req);
    },
    [http]
  );

  const remove = useCallback(
    async (id: number, ub_id: number) => {
      return await http.delete(`/user-book/${id}/${ub_id}`);
    },
    [http]
  );

  const getAllByUserId = useCallback(
    async (id: number) => {
      return await http.get<{ data: UserBookGetAllResModel[] }>(
        `/user-book/${id}/books`
      );
    },
    [http]
  );

  const getByBookAndLanguage = useCallback(
    async (bookId: number, language: string) => {
      return await http.get<{ date: UserBookGetAllResModel }>(
        `/user-book/${bookId}/books/${language}`
      );
    },
    [http]
  );

  const approveWaitingBooks = useCallback(async () => {
    return await http.get<{ data: UserBookWaitingResModel[] }>(
      "/user-books/waiting"
    );
  }, [http]);

  const approve = useCallback(
    async (ub_id: number) => {
      return await http.get(`/user-book/approve/${ub_id}`);
    },
    [http]
  );

  const decline = useCallback(
    async (ub_id: number, description: string) => {
      return await http.post(`/user-book/decline/${ub_id}`, {
        rejected_description: description,
      });
    },
    [http]
  );

  const declineAdmin = useCallback(
    async (ub_id: number, description: string) => {
      return await http.post(`/user-book/admin/unpublish`, {
        reason: description,
        ub_id: ub_id,
      });
    },
    [http]
  );

  const removeSummary = useCallback(
    async (id: number) => {
      return await http.delete(`/user_book/delete/${id}`);
    },
    [http]
  );

  const declinedList = useCallback(async () => {
    return await http.get<{ data: UserBookDeclinedListResModel[] }>(
      "/user-book/declined/get"
    );
  }, [http]);

  const updateUserBook = useCallback(
    async (req: UserBookUpdateModel) => {
      return await http.post("/user-book/update", req);
    },
    [http]
  );

  const unPublish = useCallback(
    async (id: number) => {
      return await http.post("/user-book/unpublish", { ub_id: id });
    },
    [http]
  );

  const getDetail = useCallback(
    async (id: number) => {
      return await http.get<{ data: Userbookdetail[] }>(`user-books/get/${id}`);
    },
    [http]
  );

  return {
    getAll,
    getDetail,
    unPublish,
    removeSummary,
    declineAdmin,
    declinedList,
    updateUserBook,
    approve,
    decline,
    getById,
    update,
    create,
    remove,
    getAllByUserId,
    getByBookAndLanguage,
    approveWaitingBooks,
  };
};
