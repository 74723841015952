import { useCallback, useEffect, useState } from "react";
import { PageCard } from "../../components/pageCard"
import { useWithdrawalService } from "../../services/withdrawal/withdrawal.service";
import { WithdrawalWaitingListResModel } from "../../services/withdrawal/withdrawal";
import { IColumnProps } from "../../components/table";
import { ITable } from "../../components/table/table";

export const WithdrawAllList: React.FC = props => {
    const service = useWithdrawalService();

    const [state, setState] = useState<WithdrawalWaitingListResModel[]>([]);

    const getList = useCallback(async () => {
        const res = await service.getAllList();
        if (!res.result) return;
        setState(res.data);
    }, [service, setState])

    useEffect(() => {
        getList();
        // eslint-disable-next-line
    }, [])

    const columns: IColumnProps<WithdrawalWaitingListResModel>[] = [
        {
            dataIndex: 'name',
            title: 'Name'
        },
        {
            dataIndex: 'surname',
            title: 'Surname'
        },
        {
            dataIndex: 'amount',
            title: 'Amount'
        },
        {
            dataIndex: 'account_name',
            title: 'account_name'
        },
        {
            dataIndex: 'address',
            title: 'address'
        },
        {
            dataIndex: 'bank_address',
            title: 'bank_address'
        },
        {
            dataIndex: 'bank_name',
            title: 'bank_name'
        },
        {
            dataIndex: 'country',
            title: 'country'
        },
        {
            dataIndex: 'iban',
            title: 'iban'
        },
        {
            dataIndex: 'service_fee',
            title: 'service_fee'
        },
        {
            dataIndex: 'swift',
            title: 'swift'
        },
        {
            dataIndex: 'total_amount',
            title: 'total_amount'
        },
        {
            dataIndex: 'created_at',
            title: 'CreatedDate',
            isDate: true
        }
    ];

    return (
        <>
            <PageCard>
                <ITable
                    rowKey={"request_id"}
                    columns={columns}
                    dataSource={state}
                />
            </PageCard>

        </>
    )
}